import React, { useEffect } from "react";
import { Box, Stack, Typography, Button } from "@mui/material";
import BackNavigationHeader from '../../common/components/header/BackNavigationHeader';
import ExhibitionCard from './ExhibitionCard';
import { useDispatch, useSelector } from 'react-redux';
import { useCustomNavigate } from '../../common/hooks/useCustomNavigate';
import { useSnakeBarAlert } from '../../common/hooks/useSnakeBarAlert';
import { setIsBookmarked } from '../../repository/exhibition/exhibitionRepository';
import { postBookmarks, deleteBookmarks } from '../../api/SwissApi';
import { fetchExhibition } from '../../repository/exhibition/exhibitionRepository';
import { useSearchParams } from 'react-router-dom';
import { updateEditorAtIndex } from '../../repository/posts/postsSlice';
import { useNavigate } from 'react-router-dom';
const SearchExhibitionPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const contentIndex = searchParams.get('content_index');
  
    const { onMoveExhibitionPage, onMoveExhibitionDetailPage } = useCustomNavigate();
    const exhibitions = useSelector(state => state.exhibition.exhibitions);
    const userId = useSelector((state) => state.user.userId);
    const { handleLoginAlert, addBookmarkAlert, removeBookmarkAlert } = useSnakeBarAlert();
  
    useEffect(() => {
        const fetchData = async () => {
            try {
                if (exhibitions.length === 0) {
                    await fetchExhibition(dispatch);
                }
            } catch (error) {
                console.error('데이터 로딩 중 에러 발생:', error);
            }
        }
        fetchData();
    }, [exhibitions.length, dispatch]);

    const handleExhibitionClick = (exhibition) => {
        dispatch(updateEditorAtIndex({
            index: contentIndex || 0, 
            exhibition: exhibition
          }));
        navigate(`/posts/contents/edit?content_index=${contentIndex}`);
    }
    return (
        <Box>
            <BackNavigationHeader title="" />
            <Box sx={{ 
                minHeight: 'calc(100vh - 200px)',
                display: 'flex',
                flexDirection: 'column'
            }}>
                <Box sx={{ px: 3, flex: 1, py:2 }}>
                    <Typography 
                        variant="h6" 
                        sx={{ 
                            mb: 1,
                            color: '#666666',
                            fontWeight: 500
                        }}
                    >
                        관람한 전시를 선택해 주세요
                    </Typography>
                    <Stack spacing={2.5} sx={{mt:2}}>
                        {exhibitions.map((exhibition) => (
                            <Box
                                key={exhibition.id}
                                onClick={() => handleExhibitionClick(exhibition)}
                                sx={{
                                    p: 2,
                                    bgcolor: '#FFFFFF',
                                    borderBottom: '2px solid #DDDDDD',
                                    transition: 'all 0.2s ease-in-out',
                                    '&:hover': {
                                        transform: 'translateY(-2px)',
                                        boxShadow: '0 4px 12px rgba(0,0,0,0.08)',
                                    }
                                }}
                            >
                                <Typography variant="subtitle1" fontWeight={600} sx={{ mb: 1 }}>
                                    {exhibition.title}
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    {exhibition.location?.name}
                                </Typography>
                            </Box>
                        ))}
                    </Stack>
                </Box>
            </Box>

            <Box sx={{ px: 3, mt: 'auto' }}>
                <Box sx={{ textAlign: 'left', pb:1 }}>
                    <Typography variant="subtitle2" color="text.secondary">
                        찾는 전시가 없다면?
                    </Typography>
                </Box>
                <Button 
                    variant="contained" 
                    fullWidth 
                    onClick={() => window.open('https://forms.gle/z1YBhtAZmjDLFj8N6', '_blank')}
                    sx={{ 
                        py: 1.5,
                        bgcolor: '#F8F8F8',
                        color: '#333333',
                        boxShadow: 'none',
                        '&:hover': {
                            bgcolor: '#EEEEEE',
                            boxShadow: 'none'
                        }
                    }}
                >
                    미술전시 등록 요청
                </Button>
            </Box>
        </Box>
    )
}

export default SearchExhibitionPage;
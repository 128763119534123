import React, { useEffect, useRef, useState } from 'react';
import { Box, Button, IconButton, AppBar, Toolbar, Input, CircularProgress } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';
import PostListEditForm from './PostListEditForm';
import PostEditForm from './PostEditForm';
import { useDispatch, useSelector } from 'react-redux';
import {updateEditorImageAtIndex, removeEditorImage, clearEditors, addEditor, setEditorType, setLoading} from '../../repository/posts/postsSlice';
import {uploadImage} from '../../api/SwissApi';
import { fetchPostFeed } from '../../repository/posts/postsRepository';
import CustomSnackbar from '../../common/components/exception/CustomSnackbar';
import { useSnakeBarAlert } from '../../common/hooks/useSnakeBarAlert';
const MAX_IMAGES = 10;
const MAX_CONTENT_LENGTH = 1000;  
const TYPE_INIT = 'init';
const TYPE_ADD = 'add';

const ContentEditForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const editors = useSelector((state) => state.posts.editors);
  const editorType = useSelector((state) => state.posts.editorType);
  const loading = useSelector((state) => state.posts.loading);
  const userId = useSelector((state) => state.user.userId);
  const editorImage = useSelector((state) => state.posts.editors[0].image_url);
  const [showPhotoEdit, setShowPhotoEdit] = useState(false);
  const editorsCount = useSelector(state => state.posts.editors.length);
  const fileInputRef = useRef();

  const { excetpionContentAlert, exceptionImageAlert, exceptionArtAlert, exceptionTitleAlert, exceptionArtistAlert, exceptionContentMaxLengthAlert } = useSnakeBarAlert();
  //snackbarState

  useEffect(()=>{
    dispatch(setEditorType("feed"));
  },[]);

  useEffect(() => {
    if (editorImage == "") {
      setShowPhotoEdit(false);
    } else {
      setShowPhotoEdit(true);
      resetFileInput();
    }
  }, [editorsCount, editorImage]);

  const handleSubmit = async () => {
    const filterEditor = editors.filter((editor, index, array) => {
      return (editor.content !== "" || editor.image_url !== "") || (index === array.length - 1 && (editor.content !== "" || editor.image_url !== ""));
    }).map(editor => ({
      ...editor,
      exhibition_id: editor.exhibition?.id || null
    }));

    if (filterEditor.length === 0) {
      excetpionContentAlert();
      return;
    }

    const firstEditor = filterEditor[0];

    if (!firstEditor.image_url) {
      exceptionImageAlert();
      return;
    }

    // if (!firstEditor.content?.trim()) {
    //   excetpionContentAlert();
    //   return;
    // }

    if (firstEditor.content.length > MAX_CONTENT_LENGTH) {
      exceptionContentMaxLengthAlert();
      return;
    }

    if (!firstEditor.art) {
      exceptionArtAlert();
      return;
    }

    if (!firstEditor.art.title?.trim()) {
      exceptionTitleAlert();
      return;
    }

    if (!firstEditor.art.artist?.trim()) {
      exceptionArtistAlert();
      return;
    }

    const regitEditorParam = {
      type: editorType,
      posts: filterEditor
    }
    console.log(regitEditorParam)
    fetchPostFeed(dispatch, regitEditorParam).then(() => {
      navigate('/'); 
    });
  };

  const handleClose = () => {
    navigate(-1);
  };

  // handlePhotoEdit 함수에 type 파라미터 추가
  const handlePhotoEdit = (type) => {
    openFileInput(type);
  };

  // openFileInput 함수에 type 파라미터 추가 및 fileInputRef에 type 저장
  const openFileInput = (type) => {
    fileInputRef.current.input_type = type;  // type을 현재 선택한 input의 ref에 저장
    fileInputRef.current.click();
  };

  const handleChangeImage = async (event) => {
    const files = Array.from(event.target.files);
    const type = fileInputRef.current.input_type;

    if (files.length > 1) {
        alert('이미지는 한 개만 선택 가능합니다.');
        return;
    }

    // if (files.length > MAX_IMAGES) {
    //   alert(`You can only upload up to ${MAX_IMAGES} images.`);
    //   return;
    // }
    dispatch(setLoading(true));  // 로딩 시작

    try {
        if (type === TYPE_INIT) {
            await handleInitType(files);
        } else if (type === TYPE_ADD) {
            await handleAddType(files);
        }
    } catch (error) {
        console.error('Error processing files:', error);
    } finally {
      dispatch(setLoading(false));  // 작업 완료 후 로딩 종료
    }

    dispatch(addEditor({image_url: ""}));
  };
  
  const handleInitType = async (files) => {
    for (const [index, file] of files.entries()) {
        const imageUrl = await uploadImage(file);
        dispatch(updateEditorImageAtIndex({ index, image_url: imageUrl }));
    }
  };

  const handleAddType = async (files) => {
      for (const [index, file] of files.entries()) {
          const imageUrl = await uploadImage(file);
          if (index === 0) {
              dispatch(updateEditorImageAtIndex({ index: editorsCount - 1, image_url: imageUrl }));
          } else {
              dispatch(addEditor({ image_url: imageUrl }));
          }
      }
  };

  const resetFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const handleRemoveImage = (index) => {
    dispatch(removeEditorImage(index));
  };


  return (
    <Box sx={{
      width: '100%',
      maxWidth: '800px',
      mx: 'auto',
      border:0,
      p:2,
      pl:'20px',
      boxSizing: 'border-box',
      '@media (min-width: 800px)': {
        margin: '0 auto',
      }
    }}>
        <AppBar position="static" sx={{
            backgroundColor: '#ffffff', 
            elevation: 0, // Remove shadow
            p:0,
            margin:0,
            boxShadow: 'none', // Additional reinforcement to remove shadows
            border: 0 // Ensure no borders
        }}>
        <Toolbar sx={{ justifyContent: 'space-between',p:0, margin:0}}>
          <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close" sx={{ color: 'black' }}>
            <CloseIcon />
          </IconButton>
          <Button color="inherit" onClick={handleSubmit} sx={{ color: '#000000', fontSize:'16px',fontWeight:600 }}>
            완료
          </Button>
        </Toolbar>
      </AppBar>
      {
      // loading ? (
      //   <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80vh' }}>
      //     <CircularProgress />
      //   </Box>
      // ) : 
        showPhotoEdit ? (
          <PostListEditForm handleRemoveImage={handleRemoveImage} handlePhotoEdit={(type) => { handlePhotoEdit(type) }} loading={loading}/>
        ) : (
          <PostEditForm handlePhotoEdit={(type) => { handlePhotoEdit(type) }} />
        )
    }      
      <Input
          type="file"
          inputProps={{accept: 'image/*', multiple: false }}
          multiple={false}
          inputRef={fileInputRef}
          onChange={handleChangeImage}
          style={{ display: 'none' }}
        />
        <CustomSnackbar autoHideDuration={3000} />
    </Box>
  );
};

export default ContentEditForm;
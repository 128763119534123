import React, { useEffect, useState } from 'react';
import { Box, Avatar, Typography, IconButton, Divider, Menu, MenuItem, Dialog, DialogTitle, DialogActions, Button } from '@mui/material';
import { formatTime } from '../../util/utils';
import CommentContent from './CommentContent';
import ArtworkReplyList from './ArtworkReplyList';
import { useDispatch } from 'react-redux';
import { fetchPostArtworkCommentLike, fetchDeleteArtworkCommentLike, fetchDeleteArtworkComment } from '../../repository/comments/artworkCommentsRepository';
import { icons } from '../../constants/icons';
import { useNavigate } from 'react-router-dom';

const ArtworkCommentCard = ({ index, commentsEndRef, exhibitionId, artworkId, comment, mentionUsers, userId, handleReplyTo, handleLoginAlert }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [isLiked, setIsLiked] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const [openDialog, setOpenDialog] = useState(false);

    useEffect(() => {
        setIsLiked(comment.is_liked);
    }, [comment.is_liked]);

    const handleLike = (commentInfo) => {
        if (!userId) {
            handleLoginAlert();
            return;
        }
        setIsLiked(!isLiked);
        isLiked ? dispatch(fetchDeleteArtworkCommentLike(exhibitionId, artworkId, commentInfo.id)) : dispatch(fetchPostArtworkCommentLike(exhibitionId, artworkId, commentInfo.id));
    };

    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleDelete = () => {
        setOpenDialog(true);
        handleMenuClose();
    };

    const handleConfirmDelete = async () => {
        // TODO: 삭제 로직 구현
        await dispatch(fetchDeleteArtworkComment(exhibitionId, artworkId, comment.id));
        setOpenDialog(false);
    };

    const handleCancelDelete = () => {
        setOpenDialog(false);
    };

    const handleUserClick = () => {
        navigate(`/profiles/${comment.user.id}`);
    };

    return (
        <Box key={`comment-card-${index}`} ref={commentsEndRef} sx={{ 
            wordBreak: 'break-word', 
            whiteSpace: 'normal',
            overflow: 'hidden'
        }}>
            <Box sx={{ width: '100%', pb: '0px' }}>
              <Box sx={{
                display: 'flex',
                justifyContent: 'space-between', // This ensures that the items are spread across the container
                p: '16px 20px',
              }}>
                <Box sx={{ display: 'flex' }}>
                  <Avatar 
                    src={comment.user.profile_image_url} 
                    sx={{ 
                      width: 28, 
                      height: 28, 
                      marginRight: '8px',
                      cursor: 'pointer'
                    }} 
                    onClick={handleUserClick}
                  />
                  <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Typography 
                        sx={{ 
                          fontSize: '14px', 
                          fontWeight: 600, 
                          color: 'rgba(0,0,0,0.9)', 
                          alignSelf: 'flex-start', 
                          pr: '6px',
                          cursor: 'pointer'
                        }}
                        onClick={handleUserClick}
                      >
                        {comment.user.username}
                      </Typography>
                      {comment.is_deleted ? null:
                      <Typography sx={{ fontSize: '12px', fontWeight: 400, color: 'rgba(0,0,0,0.65)', alignSelf: 'center' }}>
                        {formatTime(comment.created_at)}
                      </Typography>
                      }
                    </Box>
                    <Box sx={{ pt: '4px' }}>
                      {comment.is_deleted ? <Typography sx={{ fontSize: '14px', fontWeight: 400, color: 'rgba(0,0,0,0.55)' }}>작성자가 삭제한 댓글입니다.</Typography> :
                       <Typography sx={{ fontSize: '14px', fontWeight: 400 }}>
                        <CommentContent content={comment.content} mentionUsers={mentionUsers}/>
                       </Typography>
                       }
                    </Box>
                    <Box sx={{ pt: '8px', m: 0 }}>
                      {comment.is_deleted ? <Typography sx={{ fontSize: '12px', fontWeight: 400, color: 'rgba(0,0,0,0.65)' }}> {formatTime(comment.created_at)}</Typography> : <>
                      <IconButton sx={{ p: 0, mr: '14px' }} onClick={() => handleLike(comment)}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <img src={isLiked ? icons.commentLike.active : icons.commentLike.inactive} alt="Like" style={{ width: 20, height: 20 }} />
                            <Typography sx={{fontSize: '14px', fontWeight: 500, color: 'rgba(0, 0, 0, 0.65)', marginLeft: 1}}>
                            {comment.likes_count || ''} {/* 좋아요 수 */}
                            </Typography>
                        </Box>
                      </IconButton>
                      <IconButton
                        sx={{ p: 0 }}
                        onClick={() => handleReplyTo(comment, "comment")}
                      >
                        <img
                          src={'/assets/icons/comments/comment.png'}
                          alt="comment"
                          style={{ width: 20, height: 20 }}
                        />
                      </IconButton>
                      </>
                      }
                    </Box>
                  </Box>
                </Box>
                <Box>
                  {comment.user.id === userId && !comment.is_deleted && (
                    <IconButton 
                      sx={{ p: 0, m: 0 }}
                      onClick={handleMenuClick}
                  >
                    <img src={'/assets/icons/comments/more.png'} alt="more" style={{ width: 20, height: 20 }} />
                  </IconButton>
                  )}
                  <Menu
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleMenuClose}
                  >
                    <MenuItem onClick={handleDelete}>
                      <Typography sx={{ fontSize: '12px', fontWeight: 500, color: 'red' }}>
                        삭제하기
                      </Typography>
                    </MenuItem>
                  </Menu>
                </Box>
              </Box>
              <Divider variant="middle" />
              {comment.replies && <ArtworkReplyList commentId={comment.id} replies={comment.replies} exhibitionId={exhibitionId} artworkId={artworkId} userId={userId} mentionUsers={mentionUsers} handleReplyTo={handleReplyTo} handleLoginAlert={handleLoginAlert}/>}
            </Box>
            <Dialog
                open={openDialog}
                onClose={handleCancelDelete}
            >
                <DialogTitle sx={{ fontSize: '14px', py: 2 }}>
                    정말 삭제하시겠습니까?
                </DialogTitle>
                <DialogActions sx={{ pb: 2, px: 2 }}>
                    <Button onClick={handleCancelDelete} sx={{ color: 'rgba(0, 0, 0, 0.65)' }}>
                        취소
                    </Button>
                    <Button onClick={handleConfirmDelete} sx={{ color: 'red' }}>
                        삭제
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default ArtworkCommentCard;
import { Box, Drawer, List, ListItem, ListItemIcon, ListItemText, AppBar, Toolbar, Typography } from '@mui/material';
import DashboardIcon from '@mui/icons-material/Dashboard';
import Icon from '@mui/icons-material/Museum';
import PaletteIcon from '@mui/icons-material/Palette';
import CollectionsIcon from '@mui/icons-material/Collections';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';

import { useNavigate, Outlet } from 'react-router-dom';
import MuseumIcon from '@mui/icons-material/Museum';

const AdminPage = () => {
  const navigate = useNavigate();
  const drawerWidth = 240;

  const menuItems = [
    { text: '대시보드', icon: <DashboardIcon />, path: '/admin/manage' },
    { text: '전시회 관리', icon: <MuseumIcon />, path: '/admin/manage/exhibitions' },
    { text: '작품 관리', icon: <PaletteIcon />, path: '/admin/manage/artworks' },
    { text: '전시 작품 관리', icon: <CollectionsIcon />, path: '/admin/manage/exhibitions/artworks' },
    { text: '콘텐츠 관리', icon: <ContentPasteIcon />, path: '/admin/manage/contents' },
  ];

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <Toolbar>
          <Typography variant="h6">Art Friends 관리자</Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
      >
        <Toolbar />
        <List>
          {menuItems.map((item) => (
            <ListItem button key={item.text} onClick={() => navigate(item.path)}>
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.text} />
            </ListItem>
          ))}
        </List>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Toolbar />
        <Outlet/>
      </Box>
    </Box>
  );
};

export default AdminPage;
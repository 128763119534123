import { 
    getExhibition, getExhibitionDetail, 
    getExhibitionArtWork, getExhibitionArtWorkRecommend,
    postExhibitionArtWorkLikes, deleteExhibitionArtWorkLikes
} from '../../api/SwissApi';

import { setExhibition, setExhibitionDetail, 
    updateExhibitionBookmark, updateArtWorkBookmark, 
    setExhibitionArtWork, setExhibitionArtWorkRecommend,
    updateArtWorkLike
} from './exhibitionSlice';

export const fetchExhibition = async (dispatch) => {
    const {results} = await getExhibition("all");
    const allExhibitions = results.filter(exhibition => !exhibition.tags.includes("test"));
    dispatch(setExhibition(allExhibitions));
}

export const fetchExhibitionDetail = (exhibitionId) => async (dispatch) => {
    const exhibitionDetail = await getExhibitionDetail(exhibitionId);
    dispatch(setExhibitionDetail(exhibitionDetail));
}

export const setIsBookmarked = (dispatch, exhibitionId, isBookmarked) => {
    dispatch(updateExhibitionBookmark({ id: exhibitionId, isBookmarked }));
}

export const setIsBookmarkedArtWork = (artworkId, isBookmarked) => async (dispatch) => {
    dispatch(updateArtWorkBookmark({ id: artworkId, isBookmarked }));
}

export const setIsLikedArtWork = (artworkId, isLiked) => async (dispatch) => {
    dispatch(updateArtWorkLike({ id: artworkId, isLiked }));
}

export const fetchExhibitionArtWork = async (dispatch, exhibitionId, artworkId) => {
    const exhibitionArtWork = await getExhibitionArtWork(exhibitionId, artworkId);  
    dispatch(setExhibitionArtWork(exhibitionArtWork));
}

export const fetchExhibitionArtWorkRecommend = async (dispatch, exhibitionId, artworkId) => {
    const {results} = await getExhibitionArtWorkRecommend(exhibitionId, artworkId);
    dispatch(setExhibitionArtWorkRecommend(results));
}

export const fetchPostArtworkLike = async (exhibitionId, artworkId) => {
    const results = await postExhibitionArtWorkLikes(exhibitionId, artworkId);
    return results;
}

export const fetchDeleteArtworkLike = async (exhibitionId, artworkId)  => {
    const results = await deleteExhibitionArtWorkLikes(exhibitionId, artworkId);
    return results;
}
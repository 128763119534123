import React, { useEffect, useRef, useState } from 'react';
import { Box, Typography, AppBar, Toolbar, IconButton, Paper, Rating } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { useSelector } from 'react-redux';
/* global google */

const BottomSheet = styled(Paper)(({ theme }) => ({
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
  backgroundColor: 'white',
  borderTopLeftRadius: 16,
  borderTopRightRadius: 16,
  overflow: 'auto',
  zIndex: 1000,
  boxShadow: '0px -2px 10px rgba(0, 0, 0, 0.1)',
  transition: 'height 0.15s ease-out',
}));

const DragHandle = styled(Box)({
  width: '40px',
  height: '4px',
  backgroundColor: '#e0e0e0',
  borderRadius: '2px',
  cursor: 'grab',
  '&:active': {
    cursor: 'grabbing'
  }
});

function BookmarkMap() {
    const locationBookmarks = useSelector((state) => state.bookmarks.location);
    const [places, setPlaces] = useState([]);
    
    useEffect(() => {
        const transformedPlaces = locationBookmarks.map(bookmark => ({
            name: bookmark?.result?.name,
            address: bookmark?.result?.address,
            lat: parseFloat(bookmark?.result?.latitude),
            lng: parseFloat(bookmark?.result?.longitude)
        }));
        setPlaces(transformedPlaces);
    }, []);
  return <NumberedMapComponent places={places} />
}

function NumberedMapComponent({places}) {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const selectedIndex = parseInt(searchParams.get('index'));

  const mapRef = useRef(null);
  const sheetRef = useRef(null);
  const [startY, setStartY] = useState(null);
  const [sheetHeight, setSheetHeight] = useState('35%');
  const [map, setMap] = useState(null);
  const [markers, setMarkers] = useState([]);
  const [infoWindows, setInfoWindows] = useState([]);

  useEffect(() => {
    const selectedIndex = parseInt(searchParams.get('index'));
    if (!isNaN(selectedIndex) && selectedIndex >= 0) {
        handlePlaceClick(selectedIndex);
    }
  }, [infoWindows, searchParams]);

  const handleTouchStart = (e) => {
    setStartY(e.touches[0].clientY);
  };

  const handleTouchMove = (e) => {
    if (!startY) return;
    
    const currentTouchY = e.touches[0].clientY;
    
    const sheet = sheetRef.current;
    if (!sheet) return;
    
    const windowHeight = window.innerHeight;
    const newHeight = windowHeight - currentTouchY;
    const heightPercentage = Math.max(
      20,
      Math.min((newHeight / windowHeight) * 100, 80)
    );
    
    setSheetHeight(`${heightPercentage}%`);
  };

  const handleTouchEnd = () => {
    setStartY(null);
  };

  const handleMouseDown = (e) => {
    e.preventDefault();
    const sheet = sheetRef.current;
    if (!sheet) return;
    
    const startY = e.clientY;
    const startHeight = sheet.getBoundingClientRect().height;
    const windowHeight = window.innerHeight;
    
    const handleMouseMove = (e) => {
      const deltaY = startY - e.clientY;
      const newHeight = startHeight + deltaY;
      const heightPercentage = Math.max(
        20,
        Math.min((newHeight / windowHeight) * 100, 80)
      );
      
      // 부드러운 움직임을 위해 transform 사용
      requestAnimationFrame(() => {
        setSheetHeight(`${heightPercentage}%`);
      });
    };

    const handleMouseUp = () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    };

    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('mouseup', handleMouseUp);
  };

  useEffect(() => {
    if (!places || places.length === 0 || !map) return;

    // 기존 마커들과 InfoWindow들을 제거
    markers.forEach(marker => marker.setMap(null));
    infoWindows.forEach(iw => iw.close());

    const markersArray = [];
    const infoWindowsArray = [];

    places.forEach((place, index) => {
      const marker = new google.maps.Marker({
        position: { lat: place.lat, lng: place.lng },
        map: map,
        label: {
          text: (index + 1).toString(),
          color: 'white',
          fontSize: '14px'
        },
        icon: {
          path: google.maps.SymbolPath.CIRCLE,
          scale: 14,
          fillColor: '#4285F4',
          fillOpacity: 1,
          strokeColor: '#4285F4',
          strokeWeight: 1,
        },
        title: place.name
      });

      const infoWindow = new google.maps.InfoWindow({
        content: `
          <div>
            <h3>${place.name}</h3>
            <div><strong>주소:</strong> ${place.address}</div>
          </div>
        `
      });

      marker.addListener('click', () => {
        infoWindowsArray.forEach(iw => iw.close());
        infoWindow.open(map, marker);
      });

      markersArray.push(marker);
      infoWindowsArray.push(infoWindow);
    });

    setMarkers(markersArray);
    setInfoWindows(infoWindowsArray);
  }, [places, map]); // map과 places가 변경될 때마다 실행

  useEffect(() => {
    // Google Maps 초기화 함수
    window.initMap = function() {
      if (!mapRef.current) return;
      
      const defaultCenter = places[0] ? 
        { lat: places[0].lat, lng: places[0].lng } : 
        { lat: 37.5665, lng: 126.9780 }; // 기본 위치 (서울)

      const mapInstance = new window.google.maps.Map(mapRef.current, {
        zoom: 17,
        center: defaultCenter,
        mapId: '99bdb9b2d6da52a7'
      });

      setMap(mapInstance);
    };

    // Google Maps 스크립트 로드
    const loadGoogleMapsScript = () => {
      const scriptId = 'google-maps-script';
      if (!document.getElementById(scriptId)) {
        const script = document.createElement('script');
        script.id = scriptId;
        script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&callback=initMap`;
        script.async = true;
        script.defer = true;
        document.head.appendChild(script);
      } else if (window.google) {
        window.initMap();
      }
    };

    loadGoogleMapsScript();

    // 클린업 함수
    return () => {
      const script = document.getElementById('google-maps-script');
      if (script) {
        document.head.removeChild(script);
      }
      delete window.initMap;
    };
  }, [places]); // places가 변경될 때마다 실행

  const handleBack = () => {
    navigate(-1);
  };

  const handlePlaceClick = (index) => {
    if (map && markers[index] && infoWindows[index]) {
      // 모든 InfoWindow 닫기
      infoWindows.forEach(iw => iw.close());
      
      // 선택된 마커의 InfoWindow 열기
      infoWindows[index].open(map, markers[index]);
      
      // 지도 중심을 선택된 마커로 이동
      map.panTo(markers[index].getPosition());
      map.setZoom(16);  // 선택적: 줌 레벨 조정
    }
  };

  return (
    <Box sx={{ flexGrow: 1, height: '100vh', position: 'relative' }}>
      <AppBar position="static" color="default">
        <Toolbar sx={{ justifyContent: 'center', position: 'relative' }}>
          <IconButton
            onClick={handleBack}
            edge="start"
            color="inherit"
            aria-label="back"
            sx={{ position: 'absolute', left: 16 }}
          >
            <ArrowBackIcon />
          </IconButton>
          <Box sx={{ textAlign: 'center', width: '100%' }}>
            <Typography variant="h6" sx={{ fontSize: '16px', fontWeight: 600 }}>
              {places?.[0]?.name || '위치 정보'}
            </Typography>
          </Box>
        </Toolbar>
      </AppBar>
      <div ref={mapRef} style={{ height: 'calc(100% - 64px)', width: '100%' }} />
      
      <BottomSheet
        ref={sheetRef}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
        sx={{ 
          height: sheetHeight,
          touchAction: 'none',
          userSelect: 'none',
          cursor: 'grab',
          '&:active': {
            cursor: 'grabbing'
          }
        }}
      >
        <Box 
          sx={{ 
            position: 'sticky',
            top: 0,
            width: '100%', 
            height: '30px',
            display: 'flex', 
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'grab',
            userSelect: 'none',
            backgroundColor: 'white',
            zIndex: 1
          }}
          onMouseDown={handleMouseDown}
        >
          <DragHandle />
        </Box>
        
        {places.map((place, index) => (
          <Box 
            key={index} 
            sx={{ 
              p: 2, 
              borderBottom: '1px solid #eee',
              cursor: 'pointer',
              backgroundColor: selectedIndex === index ? 'rgba(66, 133, 244, 0.08)' : 'transparent',
              '&:hover': {
                backgroundColor: selectedIndex === index 
                  ? 'rgba(66, 133, 244, 0.12)' 
                  : 'rgba(0, 0, 0, 0.04)'
              }
            }}
            onClick={() => {
              handlePlaceClick(index);
              navigate(`/bookmarks/map?index=${index}`);
            }}
          >
            <Box sx={{ display: 'flex', gap: 2, alignItems: 'flex-start' }}>
              <Typography variant="h6" sx={{ minWidth: '24px' }}>{index + 1}</Typography>
              <Box>
                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>{place.name}</Typography>
                <Typography color="text.secondary">{place.address}</Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mt: 1 }}>
                  <Rating value={4.0} readOnly size="small" />
                  <Typography variant="body2" color="text.secondary">(13)</Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        ))}
      </BottomSheet>
    </Box>
  );
}

export default BookmarkMap;
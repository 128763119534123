import { useState, useEffect } from 'react';
import { 
  Box, 
  Button, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Typography,
  IconButton,
  Avatar,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Autocomplete,
  Chip
} from '@mui/material';
import { Add, Edit, Delete } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { fetchExhibitionList, fetchPlaces, 
  fetchPlacesAutoComplete, fetchExhibitionCreate, 
  fetchExhibitionArtWorksMapping, fetchDeleteExhibition, 
  fetchUpdateExhibition 
} from '../../repository/admin/adminRepository';
import { uploadImage } from '../../api/SwissApi';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { debounce } from 'lodash';

const ExhibitionManagementPage = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const exhibitions = useSelector(state => state.admin.exhibitions);
  const places = useSelector(state => state.admin.places);
  const [exhibitionData, setExhibitionData] = useState({
    title: '',
    display_start_at: dayjs(),
    display_end_at: dayjs(),
    location_id: '',
    image_url: '',
    tags: []
  });
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [selectedExhibitionId, setSelectedExhibitionId] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [editExhibitionId, setEditExhibitionId] = useState(null);

  // 임시 위치 데이터 (실제로는 API에서 가져와야 함)


  useEffect(() => {
    // TODO: API 연동
    dispatch(fetchExhibitionList());
    dispatch(fetchPlaces());
    // setExhibitions([]);
  }, []);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setEditMode(false);
    setEditExhibitionId(null);
    setExhibitionData({
      title: '',
      display_start_at: dayjs(),
      display_end_at: dayjs(),
      location_id: '',
      image_url: '',
      tags: []
    });
  }

  const handleEditClick = (exhibition) => {
    setEditMode(true);
    setEditExhibitionId(exhibition.id);
    setExhibitionData({
      title: exhibition.title,
      display_start_at: dayjs(exhibition.display_start_at),
      display_end_at: dayjs(exhibition.display_end_at),
      location_id: exhibition.location.id,
      image_url: exhibition.image_url,
      tags: exhibition.tags
    });
    setOpen(true);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = {
      ...exhibitionData,
      display_start_at: exhibitionData.display_start_at?.format('YYYY-MM-DD'),
      display_end_at: exhibitionData.display_end_at?.format('YYYY-MM-DD')
    };
    console.log(formData);
    if (editMode) {
      // Update existing exhibition
      dispatch(fetchUpdateExhibition(editExhibitionId, formData));
    } else {
      // Create new exhibition
      dispatch(fetchExhibitionCreate(formData));
    }
    handleClose();
  };

  const handleChangeImage = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    try {
      const imageUrl = await uploadImage(file, 'exhibition');
      setExhibitionData({...exhibitionData, image_url: imageUrl});
    } catch (error) {
      console.error('이미지 업로드 실패:', error);
    }
  };

  const handleDeleteImage = () => {
    setExhibitionData({...exhibitionData, image_url: ""});
  };

  const handleChangeLocation = (newValue) => {
    setExhibitionData({
      ...exhibitionData, 
      location_id: newValue ? newValue.id : ''
    });
  };

  const handleToggleActive = async (exhibitionId, isActive) => {
    console.log(exhibitionId, isActive);
    const params = {
      is_active: !isActive
    }
    try {
      await dispatch(fetchExhibitionArtWorksMapping(exhibitionId, params));
      dispatch(fetchExhibitionList()); // 목록 새로고침
    } catch (error) {
      console.error('전시 상태 업데이트 실패:', error);
    }
  };

  const handleDeleteClick = (exhibitionId) => {
    setSelectedExhibitionId(exhibitionId);
    setConfirmOpen(true);
  };

  const handleConfirmDelete = () => {
    if (selectedExhibitionId) {
      dispatch(fetchDeleteExhibition(selectedExhibitionId));
      setConfirmOpen(false);
      setSelectedExhibitionId(null);
    }
  };

  const handleInputChange = debounce((newInputValue) => {
    if (newInputValue) {
      dispatch(fetchPlacesAutoComplete(newInputValue));
    } else {
      dispatch(fetchPlaces());
    }
  }, 1000); // 300ms 지연

  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2, alignItems: 'center' }}>
        <h2>전시회 관리</h2>
        <Button 
          variant="contained" 
          startIcon={<Add />}
          onClick={handleOpen}
        >
          전시회 등록
        </Button>
      </Box>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>이미지</TableCell>
              <TableCell>제목</TableCell>
              <TableCell>기간</TableCell>
              <TableCell>장소</TableCell>
              <TableCell>태그</TableCell>
              <TableCell>전시여부</TableCell>
              <TableCell>관리</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {exhibitions.map((exhibition) => (
              <TableRow key={exhibition?.id}>
                <TableCell>{exhibition?.id}</TableCell>
                <TableCell>
                  <Avatar
                    src={exhibition?.image_url}
                    alt={exhibition?.title}
                    variant="rounded"
                    sx={{ width: 60, height: 60 }}
                  />
                </TableCell>
                <TableCell>{exhibition?.title}</TableCell>
                <TableCell>{exhibition?.display_start_at} ~ {exhibition?.display_end_at}</TableCell>
                <TableCell>{exhibition?.location?.name}</TableCell>
                <TableCell>
                  <Box sx={{ display: 'flex', gap: 0.5, flexWrap: 'wrap' }}>
                    {exhibition?.tags?.map((tag, index) => (
                      <Chip
                        key={index}
                        label={tag}
                        size="small"
                        variant="outlined"
                      />
                    ))}
                  </Box>
                </TableCell>
                <TableCell>
                  <Chip
                    label={exhibition?.is_active ? "전시중" : "전시종료"}
                    color={exhibition?.is_active ? "success" : "default"}
                    size="small"
                    onClick={() => handleToggleActive(exhibition.id, exhibition.is_active)}
                    sx={{ cursor: 'pointer' }}
                  />
                </TableCell>
                <TableCell>
                  <Button startIcon={<Edit />} onClick={() => handleEditClick(exhibition)}>수정</Button>
                  <Button 
                    startIcon={<Delete />} 
                    color="error" 
                    onClick={() => handleDeleteClick(exhibition.id)}
                  >
                    삭제
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>전시회 등록</DialogTitle>
        <form onSubmit={handleSubmit}>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              label="전시회 제목"
              fullWidth
              value={exhibitionData.title}
              onChange={(e) => setExhibitionData({...exhibitionData, title: e.target.value})}
            />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="전시 시작일"
                value={exhibitionData.display_start_at}
                onChange={(newValue) => setExhibitionData({
                  ...exhibitionData, 
                  display_start_at: newValue
                })}
                sx={{ mt: 2, width: '100%' }}
              />
              <DatePicker
                label="전시 종료일"
                value={exhibitionData.display_end_at}
                onChange={(newValue) => setExhibitionData({
                  ...exhibitionData, 
                  display_end_at: newValue
                })}
                sx={{ mt: 2, width: '100%' }}
                minDate={exhibitionData.display_start_at}
              />
            </LocalizationProvider>
            <Autocomplete
              fullWidth
              options={places}
              getOptionLabel={(option) => option.name}
              value={places.find(loc => loc.id === exhibitionData.location_id) || null}
              onChange={(_, newValue) => {
                handleChangeLocation(newValue);
              }}
              onInputChange={(_, newInputValue) => {
                handleInputChange(newInputValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  margin="dense"
                  label="전시 장소"
                />
              )}
            />
            <Autocomplete
              multiple
              freeSolo
              options={[]}
              value={exhibitionData.tags}
              onChange={(_, newValue) => {
                setExhibitionData({...exhibitionData, tags: newValue});
              }}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    key={`tag-${index}`}
                    label={option}
                    {...getTagProps({ index })}
                    color="primary"
                    variant="outlined"
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  margin="dense"
                  label="태그"
                  placeholder="태그를 입력하세요"
                  helperText="엔터를 눌러 태그를 추가하세요"
                />
              )}
            />
            <Box sx={{ mt: 2 }}>
              <Typography variant="subtitle2" sx={{ mb: 1 }}>전시회 이미지</Typography>
              <Box 
                sx={{ 
                  position: 'relative',
                  width: 200,
                  height: 200,
                  border: '1px dashed rgba(0, 0, 0, 0.2)',
                  borderRadius: 1,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  overflow: 'hidden',
                  '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.04)'
                  }
                }}
              >
                <input
                  type="file"
                  hidden
                  id="exhibition-image-input"
                  accept="image/*"
                  onChange={handleChangeImage}
                />
                {exhibitionData.image_url ? (
                  <>
                    <img 
                      src={exhibitionData.image_url} 
                      alt="전시회 이미지"
                      style={{ 
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover'
                      }}
                    />
                    <Box
                      sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: 'rgba(0, 0, 0, 0.3)',
                        display: 'flex',
                        gap: 1,
                        alignItems: 'center',
                        justifyContent: 'center',
                        opacity: 0,
                        transition: 'opacity 0.2s',
                        '&:hover': {
                          opacity: 1
                        }
                      }}
                    >
                      <IconButton
                        component="label"
                        htmlFor="exhibition-image-input"
                        sx={{ color: 'white' }}
                      >
                        <Edit />
                      </IconButton>
                      <IconButton
                        onClick={handleDeleteImage}
                        sx={{ color: 'white' }}
                      >
                        <Delete />
                      </IconButton>
                    </Box>
                  </>
                ) : (
                  <label htmlFor="exhibition-image-input" style={{ cursor: 'pointer' }}>
                    <Box sx={{ textAlign: 'center' }}>
                      <Add sx={{ fontSize: 40, color: 'text.secondary' }} />
                      <Typography variant="body2" color="text.secondary">
                        이미지를 업로드해주세요
                      </Typography>
                    </Box>
                  </label>
                )}
              </Box>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>취소</Button>
            <Button type="submit">등록</Button>
          </DialogActions>
        </form>
      </Dialog>

      <Dialog
        open={confirmOpen}
        onClose={() => setConfirmOpen(false)}
      >
        <DialogTitle>삭제 확인</DialogTitle>
        <DialogContent>
          <Typography>정말 삭제하시겠습니까?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmOpen(false)}>취소</Button>
          <Button onClick={handleConfirmDelete} color="error">확인</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ExhibitionManagementPage;
import React from 'react';
import ArtworkCommentCard from './ArtworkCommentCard';

const ArtworkCommentList = ({ exhibitionId, artworkId, comments, mentionUsers, commentsEndRef, userId, handleReplyTo, handleLoginAlert}) => {
    // const [activeCommentIndex, setActiveCommentIndex] = useState(null); // 현재 활성화된 comment 버튼의 인덱스
    // const handleCommentClick = (index) => {
    //   console.log('activeCommentIndex', index)
    //   if (activeCommentIndex === index) {
    //     setActiveCommentIndex(null); // 같은 버튼을 다시 클릭하면 비활성화
    //   } else {
    //     setActiveCommentIndex(index); // 클릭된 버튼의 인덱스를 활성화
    //   }
    // };
  
    return (
      <>
        {comments.map((comment, index) => (
          <ArtworkCommentCard index={index} commentsEndRef={commentsEndRef} exhibitionId={exhibitionId} artworkId={artworkId} comment={comment} mentionUsers={mentionUsers} userId={userId} handleReplyTo={handleReplyTo} handleLoginAlert={handleLoginAlert}/>
        ))}
      </>
    );
  };

  export default ArtworkCommentList;
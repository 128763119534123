import React, { useState } from 'react';
import { Card, CardContent, CardActions, Typography, IconButton, Avatar, Box, Divider, Menu, MenuItem, Dialog, DialogActions, DialogTitle, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import { icons } from '../../constants/icons';
import { formatTime } from '../../util/utils';
import { useCustomNavigate } from '../../common/hooks/useCustomNavigate';
import { useSnakeBarAlert } from '../../common/hooks/useSnakeBarAlert';
import { useModal } from '../../common/hooks/useModal';
import ArtTextComponent from '../../common/components/ArtTextComponent';
import PostCardText from '../../common/components/PostCardText';
import { useComment } from '../../features/comment/hooks/useComment';
import PostImageDetail from '../../common/components/PostImageDetail';
import { initKakao } from 'kakao-js-sdk';
import { useDispatch, useSelector } from 'react-redux';
import { postBookmarks, deleteBookmarks } from '../../api/SwissApi';
import { setIsBookmarkedArtWork, setIsLikedArtWork, fetchPostArtworkLike, fetchDeleteArtworkLike } from '../../repository/exhibition/exhibitionRepository';
import ArtWorkCommentDrawer from '../../features/comment/ArtworkCommentDrawer';

const StyledImage = {
  width: '100%',
  height: 'auto',
  maxHeight: '400px',
  objectFit: 'contain',
  paddingTop: '12px',
  // borderRadius: '30px', // Rounded corners with 8px radius
};

// {
//     "id": 1,
//     "title": "젊은 여인의 초상",
//     "artist": "빈센트 반 고흐",
//     "image_url": "https://storage.googleapis.com/art-friends/temp/1734086623240_37.jpg",
//     "content": "반 고흐가 오베르 시절에 남긴 독특한 초상화 중 하나입니다.",
//     "exhibition": {
//         title: "불멸의 화가 반 고흐",
//         period: "2024.03.15 - 2024.06.15",
//         location: "예술의전당 한가람미술관",
//         image_url: "https://storage.googleapis.com/art-friends/test/exhibit2.gif",
//     }
// }

function ArtWorkCard({ artwork, exhibitionId }) {
  const dispatch = useDispatch();

  const { onMoveProfiles } = useCustomNavigate();
  const userId = useSelector((state) => state.user.userId);
  const [commentsCount, setCommentsCount] = useState(0);
  const { selectedImage, handleImageDetailClick, openImageDetail, setOpenImageDetail } = useModal();
  const { openCommentDrawer, drawerOpen, closeCommentDrawer } = useComment();
  const { exceptionShareAlert, handleLoginAlert, addBookmarkAlert, removeBookmarkAlert } = useSnakeBarAlert();


  function shareFallback() {
    initKakao(`${process.env.REACT_APP_KAKAO_APP_KEY}`).then((isloaded) => {
      window.Kakao.Share.sendDefault({
        objectType: 'feed',
        content: {
          title: artwork.title_kr + '(' + artwork.title_en + ')',
          description: artwork.artist_kr + '(' + artwork.artist_en + ')',
          imageUrl: artwork.image_url,
          link: {
            mobileWebUrl: `${window.location.origin}/exhibitions/${exhibitionId}/artworks/${artwork.id}`,
            webUrl: `${window.location.origin}/exhibitions/${exhibitionId}/artworks/${artwork.id}`,
          },
        },
        buttons: [
          {
            title: '웹으로 보기',
            link: {
              mobileWebUrl: `${window.location.origin}/exhibitions/${exhibitionId}/artworks/${artwork.id}`,
              webUrl: `${window.location.origin}/exhibitions/${exhibitionId}/artworks/${artwork.id}`,
            },
          },
        ],
      });
    })
    
  }
  const handleShare = () => {
    if (navigator.share) {
      navigator
          .share({
            title: artwork.title_kr + '(' + artwork.title_en + ')',
            text: artwork.artist_kr + '(' + artwork.artist_en + ')',
            url: `${window.location.origin}/exhibitions/${exhibitionId}/artworks/${artwork.id}`, // 현재 페이지 URL 공유
          })
          .then(() => {
            console.log('공유 성공');
          })
          .catch((error) => {
            console.log('공유 실패', error);
          });
      } else {
        // exceptionShareAlert();
        shareFallback();
      }
  };


  const handleToggleBookmark = async () => {
    if (!userId) {
        handleLoginAlert();
        return;
    }
    
    dispatch(setIsBookmarkedArtWork(artwork.id, !artwork.is_bookmarked));
    try {
      if (!artwork.is_bookmarked) {
        addBookmarkAlert();
        await postBookmarks('exhibition_work', artwork.id);
      } else {
        removeBookmarkAlert();
        await deleteBookmarks('exhibition_work', artwork.id);
      }
      return true;
    } catch (error) {
      dispatch(setIsBookmarkedArtWork(artwork.id, artwork.is_bookmarked)); // Revert on error
      return false;
    }
  };

  const handleLike = async () => {
    if (!userId) {
        handleLoginAlert();
        return;
    }
    
    try {
      dispatch(setIsLikedArtWork(artwork.id, !artwork.is_liked));
      if (artwork.is_liked) {
        await fetchDeleteArtworkLike(exhibitionId, artwork.id);
      } else {
        await fetchPostArtworkLike(exhibitionId, artwork.id);
      }
      return true;
    } catch (error) {
      dispatch(setIsLikedArtWork(artwork.id, artwork.is_liked)); // Revert on error
      return false;
    }
  };

  return (
    <>
      <Card key={`artworkcard-${artwork.id}`} sx={{ display: 'flex', flexDirection: 'column', border: 'none', boxShadow: 'none'}} >
        {/* <PostCardHeader 
          profileImageUrl={artwork.exhibition.image_url} 
          username={artwork.exhibition.title} 
          period={artwork.exhibition.period}
          // createdAt={artwork.created_at} 
          // userId={artwork.user.id} 
          // ownerId={artwork.user.id} 
          // groupId={artwork.id} 
          // onProfileClick={() => onMoveProfiles(artwork.user)}
        /> */}
        <CardContent sx={{ padding: 0 }}>
          <Box sx={{ width: '100%' }}>
              <img src={artwork?.image_url} style={{...StyledImage, cursor: 'pointer'}} onClick={() => handleImageDetailClick(artwork?.image_url)}/> 
              <ArtTextComponent groupId={artwork?.id} title={artwork?.title_kr} artist={artwork?.artist_kr} rating={artwork?.rating} selectedImage={artwork?.image_url}/>
              <PostCardText content={artwork?.description} rich_content={artwork?.rich_content} hashtags={artwork?.hashtags}/>
          </Box>
        </CardContent>
        <PostCardActions 
          isLiked={artwork?.is_liked}
          likesCount={artwork?.likes_count}
          commentsCount={artwork?.comments_count}
          isBookmarked={artwork?.is_bookmarked}
          onLike={handleLike}
          onComment={openCommentDrawer}
          onShare={handleShare}
          onBookmark={handleToggleBookmark}
        />
      <Divider variant="middle" sx={{borderBottomWidth: '2px' }}/>
        
      <ArtWorkCommentDrawer exhibitionId={exhibitionId} artworkId={artwork.id} open={drawerOpen} handleClose={closeCommentDrawer} setCommentsCount={setCommentsCount}/>
      </Card>
      <PostImageDetail selectedImage={selectedImage} modalOpen={openImageDetail} setModalOpen={setOpenImageDetail}/>
    </>
  );
}

export default ArtWorkCard;


const PostCardHeader = ({ 
  profileImageUrl,
  username,
  period,
  userId,
  ownerId,
  groupId,
  onProfileClick,
}) => {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [openDialog, setOpenDialog] = useState(false);
  const { removePostAlert } = useSnakeBarAlert();

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
      setAnchorEl(null);
  };

  const handleDelete = () => {
      setOpenDialog(true);
      handleMenuClose();
  };

  const handleConfirmDelete = async () => {
      // TODO: 삭제 로직 구현
    //   await fetchDeletePost(dispatch, groupId);
      removePostAlert();
      // await fetchDeleteReply(dispatch, reply.group_id, commentId, reply.id);
      setOpenDialog(false);
  };

  const handleCancelDelete = () => {
      setOpenDialog(false);
  };

  return <Box sx={{ p: 2, pb: '0px', display: 'flex', justifyContent: 'space-between'}}>
    <Box sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} 
         onClick={onProfileClick}>
      <Avatar src={profileImageUrl} 
              sx={{ width: 44, height: 44, marginRight: 1.5 }} />
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Typography sx={{ fontSize: '15px', fontWeight: 600 }}>
          {username}
        </Typography>
        <Typography sx={{ fontSize: '12px', fontWeight: 400, color: '#737373' }}>
          {period}
          {/* {formatTime(createdAt)} */}
        </Typography>
      </Box>
    </Box>
    {userId === ownerId &&
    <Box>
      {/* <IconButton 
          sx={{ p: 0, m: 0 }}
          onClick={handleMenuClick}
      >
        <img src={'/assets/icons/comments/more.png'} alt="more" style={{ width: 20, height: 20 }} />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={handleDelete} sx={{p:2}}>
          <Typography sx={{ fontSize: '12px', fontWeight: 500, color: 'red',}}>
            삭제하기
          </Typography>
        </MenuItem>
      </Menu> 
      <Dialog
          open={openDialog}
          onClose={handleCancelDelete}
      >
          <DialogTitle sx={{ fontSize: '14px', py: 2 }}>
              정말 삭제하시겠습니까?
          </DialogTitle>
          <DialogActions sx={{ pb: 2, px: 2 }}>
              <Button onClick={handleCancelDelete} sx={{ color: 'rgba(0, 0, 0, 0.65)' }}>
                  취소
              </Button>
              <Button onClick={handleConfirmDelete} sx={{ color: 'red' }}>
                  삭제
              </Button>
          </DialogActions>
      </Dialog> */}
    </Box>
    }
  </Box>;
};

const PostCardActions = ({ 
  isLiked, 
  likesCount, 
  commentsCount, 
  isBookmarked,
  onLike,
  onComment,
  onShare,
  onBookmark 
}) => (
  <CardActions sx={{ mt: 'auto', justifyContent: 'space-between', pt:0 }} 
               disableSpacing>
    <Box sx={{ display: 'flex' }}> 
      <IconButton onClick={onLike}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <img src={isLiked ? icons.like.active : icons.like.inactive} 
               alt="Like" 
               style={{ width: 20, height: 20 }} />
          <Typography sx={{fontSize: '14px', fontWeight: 500, color: 'rgba(0, 0, 0, 0.65)', marginLeft: 1}}>
            {likesCount || ''}
          </Typography>
        </Box>
      </IconButton>
      <IconButton aria-label="comment" onClick={onComment}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <img src={icons.comments.active} alt="Comment" style={{ width: 20, height: 20 }} />
            <Typography sx={{ fontSize: '14px', fontWeight: 500, color: 'rgba(0, 0, 0, 0.65)',pl:'6px' }}>
            {commentsCount || '' } {/* 댓글 수 */}
           </Typography>
          </Box>
      </IconButton>
    </Box>
    <Box sx={{ display: 'flex' }}>
        <IconButton aria-label="share" sx={{ marginRight: '6px' }} onClick={onShare}>
          <img src={icons.share.active} alt="Share" style={{ width: 20, height: 20 }} />
        </IconButton>
        <IconButton onClick={onBookmark} aria-label="bookmark">
          <img src={isBookmarked ? icons.smallBookmarks.active : icons.smallBookmarks.inactive} alt="Bookmark" style={{ width: 20, height: 20 }} />
        </IconButton>
    </Box>
  </CardActions>
);

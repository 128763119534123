import React from 'react';
import { Box, Typography,Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
const EmptyExhibitionMessage = ({isMyProfile ,content="첫 번째 게시글의 주인공이 되어보세요"}) => {
    const navigate = useNavigate();
  return (
    <Box
      sx={{
        width: '100%',
        minHeight: '300px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        py: 4
      }}
    >
      <Box component="img" 
        src="/assets/images/empty_collection.png"
        alt="빈 컬렉션"
        sx={{ 
          width: '110px',
          height: '110px',
          mb: '12px'
        }}
      />
      <Typography sx={{fontSize: '16px', color: '#575555'}}>
        전시를 관람했다면
      </Typography>
      <Typography sx={{fontSize: '16px', color: '#575555'}}>
        감상평으로 나만의 컬렉션을 만들어보세요!
      </Typography>
      {isMyProfile && (
      <Box sx={{mt: '12px'}}>
        <Button 
          sx={{
            backgroundColor: '#177FFF', 
            color: 'white', 
            borderRadius: '20px', 
            padding:'4px 16px',
            fontSize: '16px',
            fontWeight: 500,
            '&:hover': {
              backgroundColor: '#177FFF',
            }
          }} 
          onClick={() => navigate('/posts/contents/edit')}
        >
          감상평 남기기
        </Button>
      </Box>
      )}
    </Box>
  );
};

export default EmptyExhibitionMessage;
import { Box, Grid, Typography, Card, CardContent, CardMedia, Divider, IconButton } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useParams } from 'react-router-dom';
import BackNavigationHeader from '../../common/components/header/BackNavigationHeader';
import { useCustomNavigate } from '../../common/hooks/useCustomNavigate';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchExhibitionDetail } from '../../repository/exhibition/exhibitionRepository';
import { formatDisplayDate } from '../../util/utils';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { icons } from '../../constants/icons';
import { useSnakeBarAlert } from '../../common/hooks/useSnakeBarAlert';
import CustomSnackbar from '../../common/components/exception/CustomSnackbar';
import { setIsBookmarked } from '../../repository/exhibition/exhibitionRepository';
import { postBookmarks, deleteBookmarks } from '../../api/SwissApi';
import BottomNav from '../../common/components/BottomNav';
import { initKakao } from 'kakao-js-sdk';
import { Helmet } from "react-helmet-async";


const StyledImage = styled('img')({
  width: '100%',
  height: '150px',
  objectFit: 'cover',
  borderRadius: '8px',
});


const ExhibitionDetailPage = () => {
    const dispatch = useDispatch();
    const { onMoveExhibitionPage } = useCustomNavigate();
    const { exhibition_id } = useParams();
    const userId = useSelector((state) => state.user.userId);
    const exhibition = useSelector((state) => state.exhibition.exhibition);
    const { onMoveExhibitionArtWorkPage } = useCustomNavigate();
    const { snackbarState, handleCloseSnackbar, handleLoginAlert, addBookmarkAlert, removeBookmarkAlert  } = useSnakeBarAlert();
    
    useEffect(() => {
        const fetchData = async () => {
            try {
                dispatch(fetchExhibitionDetail(exhibition_id));
            } catch (error) {
              console.error('데이터 로딩 중 에러 발생:', error);
              // 여기서 에러 상태를 관리하거나 사용자에게 알림을 표시할 수 있습니다
            }
          };
      
        fetchData();
        window.scrollTo(0, 0);
    }, [exhibition_id]);


const handleShare = () => {
    if (navigator.share) {
        navigator
            .share({
            title: exhibition?.title,
            text: exhibition?.location?.name + ' (' + formatDisplayDate(exhibition?.display_start_at, exhibition?.display_end_at) + ')',
            url: `${window.location.origin}/exhibitions/${exhibition_id}`, // 현재 페이지 URL 공유
            })
            .then(() => {
            console.log('공유 성공');
            })
            .catch((error) => {
            console.log('공유 실패', error);
            });
        } else {
        // exceptionShareAlert();
        shareFallback();
        }
    };
  function shareFallback() {
        initKakao(`${process.env.REACT_APP_KAKAO_APP_KEY}`).then((isloaded) => {
        window.Kakao.Share.sendDefault({
            objectType: 'feed',
            content: {
            title: exhibition?.title,
            description: exhibition?.location?.name + ' (' + formatDisplayDate(exhibition?.display_start_at, exhibition?.display_end_at) + ')',
            imageUrl: exhibition.image_url,
            link: {
                mobileWebUrl: `${window.location.origin}/exhibitions/${exhibition_id}`,
                webUrl: `${window.location.origin}/exhibitions/${exhibition_id}`,
            },
            },
            buttons: [
            {
                title: '웹으로 보기',
                link: {
                mobileWebUrl: `${window.location.origin}/exhibitions/${exhibition_id}`,
                webUrl: `${window.location.origin}/exhibitions/${exhibition_id}`,
                },
            },
            ],
        });
        })
    }
    
    const handleToggleBookmark = async (id, isBookmarked) => {
        if (!userId) {
            handleLoginAlert();
            return;
        }
        
        setIsBookmarked(dispatch, id, !isBookmarked);
        try {
          if (!isBookmarked) {
            addBookmarkAlert();
            await postBookmarks('exhibition', id);
          } else {
            removeBookmarkAlert();
            await deleteBookmarks('exhibition', id);
          }
          return true;
        } catch (error) {
          setIsBookmarked(dispatch, id, isBookmarked); // Revert on error
          return false;
        }
    };

    const handleMoveBack = () => {
        onMoveExhibitionPage();
    }
    return (
        <>
            <Helmet>
                <title>{exhibition?.title || '전시 상세'}</title>
                <meta name="description" content={`${exhibition?.location?.name || ''} (${formatDisplayDate(exhibition?.display_start_at, exhibition?.display_end_at) || ''})`} />
                
                {/* Open Graph 메타 태그 */}
                <meta property="og:title" content={exhibition?.title || '전시 상세'} />
                <meta property="og:description" content={`${exhibition?.location?.name || ''} (${formatDisplayDate(exhibition?.display_start_at, exhibition?.display_end_at) || ''})`} />
                <meta property="og:image" content={exhibition?.image_url || ''} />
                <meta property="og:url" content={`${window.location.origin}/exhibitions/${exhibition_id}`} />
                
                {/* Twitter 카드 */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content={exhibition?.title || '전시 상세'} />
                <meta name="twitter:description" content={`${exhibition?.location?.name || ''} (${formatDisplayDate(exhibition?.display_start_at, exhibition?.display_end_at) || ''})`} />
                <meta name="twitter:image" content={exhibition?.image_url || ''} />
            </Helmet>
            <Box sx={{ 
                '@media (min-width: 600px)': {
                    maxWidth: '600px',  // 최대 너비
                    padding: '0',  // 너비가 1200px 이상일 때 padding을 넓힘
                    margin: '0 auto',   // 가운데 정렬
                }, 
            }}>
                <BackNavigationHeader onMove={handleMoveBack}/>
                <Box sx={{pt:1, pl:2, pr:2, pb:2}}>
                    <Card sx={{ 
                        display: 'flex', 
                        mb: 3,
                        boxShadow: 'none',
                        border: 'none',
                        position: 'relative'
                    }}>
                        <CardMedia
                            component="img"
                            sx={{ 
                                width: 100,
                                objectFit: 'cover',
                                borderRadius: '8px'
                            }}
                            image={exhibition?.image_url}
                            alt={exhibition?.title}
                        />
                        <CardContent sx={{ flex: '1 1 auto', p: 3 }}>
                            <Typography gutterBottom sx={{ fontSize: '20px', fontWeight: 'bold' }}>
                                {exhibition?.title}
                            </Typography>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 0.5}}>
                                <CalendarTodayIcon sx={{ fontSize: 14, color: '#666666' }} />
                                <Typography sx={{ fontSize: '14px' }}>
                                    {formatDisplayDate(exhibition?.display_start_at, exhibition?.display_end_at)}
                                </Typography>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                <LocationOnIcon sx={{ fontSize: 14, color: '#666666' }} />
                                <Typography sx={{ fontSize: '14px' }}>
                                    {exhibition?.location?.name}
                                </Typography>
                            </Box>

                        </CardContent>
                        <Box sx={{ 
                            position: 'absolute',
                            bottom: 4,
                            right: 4,
                            display: 'flex',
                            alignItems: 'center',
                            gap: 0.5
                        }}>
                            <IconButton aria-label="share" sx={{ marginRight: '6px' }} onClick={handleShare}>
                                <img src={icons.share.active} alt="Share" style={{ width: 17, height: 17 }} />
                            </IconButton>
                            <IconButton 
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleToggleBookmark(exhibition?.id, exhibition?.is_bookmarked);
                                }}
                                sx={{ 
                                    padding: '4px',
                                    '&:hover': {
                                        bgcolor: 'transparent',
                                    },
                                }}
                            >
                                {exhibition?.is_bookmarked ? (
                                    <img src={icons.smallBookmarks.active} alt="Bookmark" style={{ width: 17, height: 17 }} />
                                ) : (
                                    <img src={icons.smallBookmarks.inactive} alt="Bookmark" style={{ width: 17, height: 17 }} />
                                )}
                            </IconButton>
                            <Typography sx={{ fontSize: '14px', color: '#666666', fontWeight: 500}}>
                                {exhibition?.bookmarks_count}
                            </Typography>
                        </Box>
        
                    </Card>
                    
                    <Divider sx={{ mb: 2 }} thickness={2}/>
                    
                    <Grid container item spacing={1}>
                        {(exhibition?.exhibition_works || []).slice().map((artwork) => (
                            <Grid item xs={4} key={`artworks-${artwork.id}`}>
                                <StyledImage
                                    src={artwork.image_url}
                                    alt={artwork.title_kr}
                                    title={`${artwork.title_kr}`}
                                    sx={{cursor: 'pointer'}}
                                    onClick={() => onMoveExhibitionArtWorkPage(exhibition_id, artwork.id)}
                                />
                            </Grid>
                        ))}
                    </Grid>            
                    <CustomSnackbar
                        open={snackbarState.open}
                        autoHideDuration={3000}
                        onClose={handleCloseSnackbar}
                        message={snackbarState.message}
                        actionIcon={snackbarState.actionIcon}
                        actionRoute={snackbarState.actionRoute}
                    />
                </Box>
                <Box sx={{height: 60}}></Box>
                <BottomNav/>
            </Box>
        </>
    );
};

export default ExhibitionDetailPage;
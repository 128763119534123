import { AppBar, Toolbar, IconButton, Box, Typography } from '@mui/material';
import { useCustomNavigate } from '../../../common/hooks/useCustomNavigate';

const BackNavigationHeader = ({title, onMove}) => {
    const { onMoveBack }= useCustomNavigate();

    const handleClose = () => {
        onMoveBack();
    }

    return (
        <AppBar position="sticky" 
            sx={{
                paddingLeft:'8px',
                margin:0,                 
                backgroundColor: '#ffffff', 
                elevation: 0, // Remove shadow
                boxShadow: 'none', // Additional reinforcement to remove shadows
                height: '54px',
                border: 0 }}>
                <Toolbar sx={{ justifyContent: 'space-between' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <IconButton onClick={onMove ? onMove : handleClose} edge="start" color="inherit" aria-label="back">
                            <img src="/assets/icons/back_button.png" alt="back_button" style={{ width: '10px', height: '17px'}} />
                        </IconButton>
                    </Box>
                    {title && (
                        <Typography 
                            variant="subtitle1" 
                            sx={{ 
                                fontFamily: 'NanumGothicCodingBold', 
                                position: 'absolute',
                                left: '50%',
                                transform: 'translateX(-50%)',
                                color: '#000000',
                                fontWeight: 600
                            }}
                        >
                            {title}
                        </Typography>
                    )}
                </Toolbar>
        </AppBar>
    )
}

export default BackNavigationHeader;
import React, { useEffect } from "react";
import { Box, Stack } from "@mui/material";
import BackNavigationHeader from '../../common/components/header/BackNavigationHeader';
import ExhibitionCard from './ExhibitionCard';
import { useDispatch, useSelector } from 'react-redux';
import { useCustomNavigate } from '../../common/hooks/useCustomNavigate';
import { useSnakeBarAlert } from '../../common/hooks/useSnakeBarAlert';
import { setIsBookmarked } from '../../repository/exhibition/exhibitionRepository';
import { postBookmarks, deleteBookmarks } from '../../api/SwissApi';
import { fetchExhibition } from '../../repository/exhibition/exhibitionRepository';
import BottomNav from '../../common/components/BottomNav';
const ExhibitionPage = () => {
    const dispatch = useDispatch();
    const { onMoveExhibitionPage, onMoveExhibitionDetailPage, onMoveMain} = useCustomNavigate();
    const exhibitions = useSelector(state => state.exhibition.exhibitions);
    const userId = useSelector((state) => state.user.userId);
    const { handleLoginAlert, addBookmarkAlert, removeBookmarkAlert } = useSnakeBarAlert();
  
    useEffect(() => {
        const fetchData = async () => {
            try {
                if (exhibitions.length === 0) {
                    await fetchExhibition(dispatch);
                }
            } catch (error) {
                console.error('데이터 로딩 중 에러 발생:', error);
            }
        }
        fetchData();
    }, [exhibitions.length, dispatch]);

    const toggleBookmark = async (id, isBookmarked) => {
        if (!userId) {
            handleLoginAlert();
            return;
        }
        
        setIsBookmarked(dispatch, id, !isBookmarked);
        try {
          if (!isBookmarked) {
            addBookmarkAlert();
            await postBookmarks('exhibition', id);
          } else {
            removeBookmarkAlert();
            await deleteBookmarks('exhibition', id);
          }
          return true;
        } catch (error) {
          setIsBookmarked(dispatch, id, isBookmarked); // Revert on error
          return false;
        }
    };

    return (
        <Box sx={{ 
            '@media (min-width: 600px)': {
                maxWidth: '600px',  // 최대 너비
                padding: '0',  // 너비가 1200px 이상일 때 padding을 넓힘
                margin: '0 auto',   // 가운데 정렬
            }, 
        }}>
            <BackNavigationHeader title="전시톡톡" onMove={onMoveMain}/>
            <Box sx={{pt:1, pl: 2, pr: 2, pb:2}}>
                <Stack spacing={1}>
                {exhibitions.map((exhibition) => (
                    <ExhibitionCard
                        key={exhibition.id}
                        exhibition={exhibition}
                        onMoveExhibitionDetailPage={onMoveExhibitionDetailPage}
                        toggleBookmark={toggleBookmark}
                    />
                ))}
                </Stack>
            </Box>
            <Box sx={{height: 60}}></Box>
            <BottomNav/>
        </Box>
    )
}

export default ExhibitionPage;
import React from 'react';
import { Box, Card, CardActionArea, CardContent, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import MuseumIcon from '@mui/icons-material/Museum';

const ExhibitionSelector = ({type, contentIndex}) =>{
    const navigate = useNavigate();
    const title = "전시 정보"
    const handleExhibitionSelector = () => {
        navigate(`/search/exhibitions?content_index=${contentIndex}`);
      }
    return (
        <>
        {type === 'foursquare' ? 
        <Card sx={{ 
            boxShadow: 'none', 
            border: 0,    
            '&:hover': {
              // boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Example hover effect
              borderRadius: '12px', // Increase border radius on hover
            }
            }}> 
            <CardActionArea onClick={handleExhibitionSelector}>
              <CardContent sx={{ textAlign: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width:'90px', height: '90px', padding:0, backgroundColor:'#F2F4F5', borderRadius:'12px' }}>
                <MuseumIcon sx={{ fontSize: 24, color: 'gray' }} />
                <Box sx={{ height: '4px' }} />
                <Typography sx={{ fontSize: '14px', fontWeight:600, color:'rgba(0, 0, 0, 0.65)'}}>
                  {title}
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>  
          :
          <Card sx={{ 
            boxShadow: 'none', 
            border: 0,    
            '&:hover': {
                // boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Example hover effect
                borderRadius: '12px', // Increase border radius on hover
            }
            }}> 
            <CardActionArea onClick={handleExhibitionSelector}>
                <CardContent sx={{ textAlign: 'center', display: 'flex', alignItems: 'center', height:'48px', padding:0, backgroundColor:'#F2F4F5', borderRadius:'12px' }}>
                <Box sx={{display: 'flex', paddingLeft:'12px'}}>
                    <MuseumIcon sx={{ fontSize: 24, color: 'gray' }} />
                    <Box sx={{width:8}}/>
                    <Typography sx={{ fontSize: '14px', fontWeight:600, color:'rgba(0, 0, 0, 0.70)'}}>
                    {title} (선택)
                    </Typography>
                </Box>
                </CardContent>
            </CardActionArea>
            </Card>}
            </>
    );
  }

  export default ExhibitionSelector;
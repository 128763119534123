import { getBookmarks, deleteBookmarks } from '../../api/SwissApi';
import { setLocation, setPostGroup, setExhibition, setExhibitionWork,
    removeLocation, removePostGroup, removeExhibition, removeExhibitionWork
 } from './bookmarksSlice';

export const fetchBookmarkLists = () => async (dispatch) => {
    try {
        const [postBookmarks, locationBookmarks, exhibitionBookmarks, exhibitionWorkBookmarks] = await Promise.all([
            getBookmarks('post'),
            getBookmarks('location'),
            getBookmarks('exhibition'),
            getBookmarks('exhibition_work')
        ]);
        dispatch(setPostGroup(postBookmarks));
        dispatch(setLocation(locationBookmarks));
        dispatch(setExhibition(exhibitionBookmarks));
        dispatch(setExhibitionWork(exhibitionWorkBookmarks));
    } catch (error) {
        console.error('Failed to fetch bookmark lists:', error);
    }
};

export const fetchDeleteBookmark = (type, targetId) => async (dispatch) => {
    try {
        await deleteBookmarks(type, targetId);
        if (type === 'location') {
            dispatch(removeLocation(targetId));
        } else if (type === 'post') {
            dispatch(removePostGroup(targetId));
        } else if (type === 'exhibition') {
            dispatch(removeExhibition(targetId));
        } else if (type === 'exhibition_work') {
            dispatch(removeExhibitionWork(targetId));
        }
    } catch (error) {
        console.error('Failed to delete bookmark:', error);
    }
}
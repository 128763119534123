import React from 'react';
import { Box, Grid, Card, CardContent, Typography, IconButton } from '@mui/material';
import MuseumIcon from '@mui/icons-material/Museum';
import PaletteIcon from '@mui/icons-material/Palette';
import PeopleIcon from '@mui/icons-material/People';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { LineChart, Line, AreaChart, Area, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

const Dashboard = () => {
  // 실제 애플리케이션에서는 이 데이터를 API에서 가져와야 합니다
  const stats = [
    {
      title: '총 전시회',
      value: '12',
      icon: <MuseumIcon sx={{ fontSize: 40, color: '#1976d2' }} />,
      description: '현재 진행 중인 전시: 3'
    },
    {
      title: '총 작품 수',
      value: '156',
      icon: <PaletteIcon sx={{ fontSize: 40, color: '#2e7d32' }} />,
      description: '이번 달 새로운 작품: 15'
    },
    {
      title: '총 방문자 수',
      value: '1,234',
      icon: <PeopleIcon sx={{ fontSize: 40, color: '#ed6c02' }} />,
      description: '이번 달 방문자: 234'
    },
    {
      title: '총 페이지 뷰',
      value: '5,678',
      icon: <VisibilityIcon sx={{ fontSize: 40, color: '#9c27b0' }} />,
      description: '이번 달 페이지 뷰: 1,234'
    }
  ];

  // 방문자 통계 데이터 (예시)
  const visitorData = [
    { name: '1월', value: 400 },
    { name: '2월', value: 300 },
    { name: '3월', value: 600 },
    { name: '4월', value: 800 },
    { name: '5월', value: 1000 },
    { name: '6월', value: 900 },
  ];

  // 전시회별 방문자 수 데이터 (예시)
  const exhibitionData = [
    { name: '현대미술전', visitors: 1200 },
    { name: '클래식아트', visitors: 800 },
    { name: '디지털아트', visitors: 1500 },
    { name: '사진전', visitors: 1000 },
  ];

  // 일일 페이지뷰 데이터 (예시)
  const pageViewData = [
    { date: '6/1', views: 2400 },
    { date: '6/2', views: 1398 },
    { date: '6/3', views: 3800 },
    { date: '6/4', views: 3908 },
    { date: '6/5', views: 4800 },
    { date: '6/6', views: 3800 },
    { date: '6/7', views: 4300 },
  ];

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Typography variant="h4" sx={{ mb: 4 }}>대시보드</Typography>
      
      {/* 기존 통계 카드 */}
      <Grid container spacing={3} sx={{ mb: 4 }}>
        {stats.map((stat, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <Card sx={{ height: '100%' }}>
              <CardContent>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                  <Typography variant="h6" color="text.secondary">
                    {stat.title}
                  </Typography>
                  <IconButton size="small">
                    {stat.icon}
                  </IconButton>
                </Box>
                <Typography variant="h4" sx={{ mb: 2 }}>
                  {stat.value}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {stat.description}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      {/* 그래프 섹션 */}
      <Grid container spacing={3}>
        {/* 월별 방문자 추이 */}
        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <Typography variant="h6" sx={{ mb: 2 }}>월별 방문자 추이</Typography>
              <ResponsiveContainer width="100%" height={300}>
                <LineChart data={visitorData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Line type="monotone" dataKey="value" stroke="#8884d8" />
                </LineChart>
              </ResponsiveContainer>
            </CardContent>
          </Card>
        </Grid>

        {/* 전시회별 방문자 수 */}
        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <Typography variant="h6" sx={{ mb: 2 }}>전시회별 방문자 수</Typography>
              <ResponsiveContainer width="100%" height={300}>
                <BarChart data={exhibitionData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Bar dataKey="visitors" fill="#82ca9d" />
                </BarChart>
              </ResponsiveContainer>
            </CardContent>
          </Card>
        </Grid>

        {/* 일일 페이지뷰 추이 */}
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Typography variant="h6" sx={{ mb: 2 }}>일일 페이지뷰 추이</Typography>
              <ResponsiveContainer width="100%" height={300}>
                <AreaChart data={pageViewData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="date" />
                  <YAxis />
                  <Tooltip />
                  <Area type="monotone" dataKey="views" stroke="#8884d8" fill="#8884d8" />
                </AreaChart>
              </ResponsiveContainer>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Dashboard;
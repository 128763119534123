import { Box, Typography, Stack, IconButton } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { useCustomNavigate } from '../../common/hooks/useCustomNavigate';
import { useSnakeBarAlert } from '../../common/hooks/useSnakeBarAlert';
import { setIsBookmarked } from '../../repository/exhibition/exhibitionRepository';
import { postBookmarks, deleteBookmarks } from '../../api/SwissApi';
import ExhibitionCard from './ExhibitionCard';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const ExhibitionTalk = () => {
  const dispatch = useDispatch();
  const { onMoveExhibitionPage, onMoveExhibitionDetailPage } = useCustomNavigate();
  const exhibitions = useSelector(state => state.exhibition.exhibitions);
  const userId = useSelector((state) => state.user.userId);
  const { handleLoginAlert, addBookmarkAlert, removeBookmarkAlert } = useSnakeBarAlert();

  const toggleBookmark = async (id, isBookmarked) => {
      if (!userId) {
          handleLoginAlert();
          return;
      }
      
      setIsBookmarked(dispatch, id, !isBookmarked);
      try {
        if (!isBookmarked) {
          addBookmarkAlert();
          await postBookmarks('exhibition', id);
        } else {
          removeBookmarkAlert();
          await deleteBookmarks('exhibition', id);
        }
        return true;
      } catch (error) {
        setIsBookmarked(dispatch, id, isBookmarked); // Revert on error
        return false;
      }
  };

  return (
    <Box sx={{pl: 2, pr: 2, pt:2}}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2}}>
        <Typography 
          sx={{ 
            fontFamily: 'NanumGothicCodingBold', 
            fontSize: '16px',
            color: '#000000',
          }}
        >
          전시톡톡
        </Typography>
        <IconButton 
          sx={{ 
            color: '#666666',
            p: 0
          }}
        >
          <ChevronRightIcon sx={{ fontSize: 20 }} onClick={onMoveExhibitionPage}/>
        </IconButton>
      </Box>

      <Stack spacing={1}>
        {exhibitions
          .filter(exhibition => exhibition.is_active)
          .slice(0, 2)
          .map((exhibition) => (
          <ExhibitionCard
            key={exhibition.id}
            exhibition={exhibition}
            onMoveExhibitionDetailPage={onMoveExhibitionDetailPage}
            toggleBookmark={toggleBookmark}
          />
        ))}
      </Stack>
    </Box>
  );
}

export default ExhibitionTalk;
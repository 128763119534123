import React, { useState, useEffect, useRef } from 'react';
import { Modal, IconButton, Box, Drawer, List, ListItem, ListItemText, ListItemAvatar, Button, Avatar, Typography, Paper, Divider, TextField, Skeleton} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Puller from './Puller';
import { icons } from '../../constants/icons';
import { postDocent } from '../../api/SwissApi';
import { useDispatch, useSelector } from 'react-redux';
import { 
  initializeMessages, 
  addMessage, 
  updateMessage, 
  setTypingMessageId, 
  setLoading,
  removeLoadingMessage,
  selectGroupMessages,
  selectGroupTypingMessageId,
  selectGroupIsLoading,
  initializeIfNeeded
} from '../../repository/docent/docentSlice';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';

const PostImageDetail = ({selectedImage, modalOpen, setModalOpen, groupId, title, artist, isDocent=false}) => {
  const COLLAPSED_HEIGHT = 120;
  const CHANGE_IMAGE_HEIGHT = 200;

  const [drawerHeight, setDrawerHeight] = React.useState(500);
  const [startY, setStartY] = React.useState(null);
  const [initialHeight, setInitialHeight] = React.useState(null); // 추가: 초기 높이 저장
  const [isDragging, setIsDragging] = React.useState(false);  // 추가
  const [isSend, setIsSend] = useState(false);
  const [chatText, setChatText] = useState(''); // 댓글 입력 상태
  const chatInputRef = useRef(null);

  // Add Redux hooks
  const dispatch = useDispatch();
  const messages = useSelector(state => selectGroupMessages(state, groupId));
  const typingMessageId = useSelector(state => selectGroupTypingMessageId(state, groupId));
  const isLoading = useSelector(state => selectGroupIsLoading(state, groupId));

  const [isKeyboardVisible, setIsKeyboardVisible] = useState(false);

  useEffect(() => {
    const handleFocus = () => {
      setIsKeyboardVisible(true);
      setTimeout(() => {
        chatInputRef.current?.scrollIntoView({ behavior: 'smooth' });
      }, 100);
    };

    const handleBlur = () => {
      setIsKeyboardVisible(false);
    };

    chatInputRef.current?.addEventListener('focus', handleFocus);
    chatInputRef.current?.addEventListener('blur', handleBlur);

    return () => {
      chatInputRef.current?.removeEventListener('focus', handleFocus);
      chatInputRef.current?.removeEventListener('blur', handleBlur);
    };
  }, []);

  // Initialize messages when component mounts
  useEffect(() => {
    if (modalOpen) {
      dispatch(initializeIfNeeded({ groupId, artist, title }));
      
      // Add scroll to bottom
      setTimeout(() => {
        const messageContainer = document.querySelector('.message-container');
        if (messageContainer) {
          messageContainer.scrollTop = messageContainer.scrollHeight;
        }
      }, 100); // 약간의 지연을 주어 컨텐츠가 모두 로드된 후 스크롤되도록 함
    }
  }, [modalOpen, groupId, artist, title, dispatch]);

  // Update typeMessage function
  const typeMessage = (message, messageId) => {
    let index = 0;
    dispatch(setTypingMessageId({ groupId, id: messageId }));
  
    const typingInterval = setInterval(() => {
      if (index < message.length) {
        dispatch(updateMessage({ 
          groupId,
          id: messageId, 
          displayMessage: message.slice(0, index + 1) 
        }));

        const messageContainer = document.querySelector('.message-container');
        if (messageContainer) {
          messageContainer.scrollTop = messageContainer.scrollHeight;
        }

        index++;
      } else {
        clearInterval(typingInterval);
        dispatch(setTypingMessageId({ groupId, id: null }));
      }
    }, 50);
  };
  
  // const fetchAudio = async (text) => {
  //   const response = await fetch("https://api.elevenlabs.io/v1/speech", {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //       "Authorization": "Bearer sk_9cd17f8cfb57f335645b08a21719c3467259448e09a9de0b",
  //     },
  //     body: JSON.stringify({
  //       text: text,
  //       voice_settings: {
  //         stability: 0.8,
  //         similarity_boost: 0.9,
  //       },
  //     }),
  //   });
  //   const audioBlob = await response.blob();
  //   return URL.createObjectURL(audioBlob);
  // };
  // const playAudio = (audioUrl) => {
  //   const audio = new Audio(audioUrl);
  //   audio.play();
  // };
  

  const handleDcentAudio = async (text) => {
    console.log(text);
    // // Example usage:
    // const audioUrl = await fetchAudio("Hello, this is a test.");
    // playAudio(audioUrl);
  }
  
  const handleInputChange = (e) => {
    const text = e.target.value;
    setChatText(text);
    setIsSend(text.trim().length > 0);
  };

  const handleDocentButtonClick = (value) => {
    if (isLoading) return; // Prevent action if loading
    handleSendClickWithMessage(value);
    setTimeout(() => {
      const messageContainer = document.querySelector('.message-container');
      if (messageContainer) {
        messageContainer.scrollTop = messageContainer.scrollHeight;
      }
    }, 100);
  };
  
  const handleSendClick = () => {
    if (isLoading) return; // Prevent action if loading
    handleSendClickWithMessage(chatText);
    setTimeout(() => {
      const messageContainer = document.querySelector('.message-container');
      if (messageContainer) {
        messageContainer.scrollTop = messageContainer.scrollHeight;
      }
    }, 100);
  };
  const handleSendClickWithMessage = (currentMessage) => {
    if (!currentMessage.trim() || isLoading) return; // Prevent action if loading
  
    setChatText('');
    setIsSend(false);
    dispatch(setLoading({ groupId, isLoading: true }));
  
    dispatch(addMessage({ 
      groupId, 
      message: { isAI: false, message: currentMessage } 
    }));
    
    dispatch(addMessage({ 
      groupId, 
      message: { isAI: true, message: '', isLoading: true, id: 'loading' } 
    }));

    postDocent({
      artist: artist,
      title: title,
      group_id: groupId,
      question: currentMessage,
      image_url: selectedImage
    }).then((res) => {
      dispatch(setLoading({ groupId, isLoading: false }));
      dispatch(removeLoadingMessage({ groupId }));
      
      const newMessageId = Date.now();
      dispatch(addMessage({
        groupId,
        message: {
          id: newMessageId,
          isAI: true,
          message: res.answer,
          displayMessage: ''
        }
      }));
      typeMessage(res.answer, newMessageId);
    }).catch((err) => {
      console.log(err);
      dispatch(setLoading({ groupId, isLoading: false })); // Ensure loading state is reset
      dispatch(removeLoadingMessage({ groupId }));
      
      const newMessageId = Date.now();
      dispatch(addMessage({
        groupId,
        message: {
          id: newMessageId,
          isAI: true,
          message: err.message,
          displayMessage: ''
        }
      }));
    });
  };

  // const handleKeyDown = (event) => {
  //   if (event.key === 'Enter' && !event.shiftKey) {
  //     event.preventDefault(); // 이 줄을 조건문 안으로 이동
  //     if (isSend) {
  //       event.preventDefault();
  //       event.stopPropagation(); // 이벤트 전파 중지
  //       handleSendClick();
  //     }
  //   }
  // };
    const handleTouchMove = (e) => {
      if (!isDragging || !startY || !initialHeight) return;
      
      const currentY = e.touches[0].clientY;
      const deltaY = startY - currentY; // 움직인 거리
      const newHeight = initialHeight + deltaY; // 초기 높이에서 움직인 거리를 더함
      
      // 높이 제한
      const windowHeight = window.innerHeight;
      const minHeight = COLLAPSED_HEIGHT;
      const maxHeight = windowHeight * 0.9;
      const clampedHeight = Math.min(Math.max(newHeight, minHeight), maxHeight);
      
      setDrawerHeight(`${clampedHeight}px`);
  };

  const handleTouchStart = (e) => {
      e.preventDefault();
      const drawer = e.currentTarget.closest('.MuiDrawer-paper');
      const currentHeight = drawer.offsetHeight;
      
      setStartY(e.touches[0].clientY);
      setInitialHeight(currentHeight);
      setIsDragging(true);
  };

  const handleTouchEnd = () => {
      setStartY(null);
      setInitialHeight(null);
      setIsDragging(false);
  };

  const handleMouseDown = (e) => {
    e.preventDefault();
    const drawer = e.currentTarget.closest('.MuiDrawer-paper');
    const currentHeight = drawer.offsetHeight;
    
    setStartY(e.clientY);
    setInitialHeight(currentHeight);
    setIsDragging(true);
  };

  const handleMouseMove = (e) => {
    if (!isDragging || !startY || !initialHeight) return;
    
    e.preventDefault();
    const deltaY = startY - e.clientY; // 움직인 거리
    const newHeight = initialHeight + deltaY; // 초기 높이에서 움직인 거리를 더함
    
    // 높이 제한
    const windowHeight = window.innerHeight;
    const minHeight = COLLAPSED_HEIGHT;
    const maxHeight = windowHeight * 0.9;
    const clampedHeight = Math.min(Math.max(newHeight, minHeight), maxHeight);
    
    setDrawerHeight(`${clampedHeight}px`);
  };

  const handleMouseUp = () => {
    setStartY(null);
    setInitialHeight(null);
    setIsDragging(false);
  };


  const renderChatMessage = (message, index) => {
    const { isAI, message: messageText, id, displayMessage, isFirstMessage } = message;

    return (
    <Box key={index} sx={{ 
      display: 'flex', 
      alignItems: 'flex-start',
      gap: 2,
      mb: 2,
      justifyContent: isAI ? 'flex-start' : 'flex-end'
    }}>
      {isAI && (
        <Avatar 
          sx={{ 
            bgcolor: '#E8F1FE',
            width: 36,
            height: 36,
            border: '1px solid #E5E5E5'
          }}
          src="/assets/docent_profile.png"
        >
        </Avatar>
      )}
      <Box sx={{ maxWidth: '260px' }}>
      {message.isLoading && isAI && !messageText ? (
          <Typography 
          variant="body1" 
          sx={{
            fontWeight: 600,
            fontSize: '18px',
            color: '#4168F6',
            '&::after': {
              content: '"....."',
              animation: 'loading 1.5s infinite',
            },
            '@keyframes loading': {
              '0%': { opacity: 0.2 },
              '50%': { opacity: 1 },
              '100%': { opacity: 0.2 },
            },
          }}
        >
        </Typography>
        ) : (
          <>
            <Typography 
              variant="body1" 
              sx={{
                fontWeight: 600,
                fontSize: isFirstMessage ? '18px': '16px',
                mb: isFirstMessage ? 1 : 0,
                color: isAI ? '#000' : "#ABB1BA"
              }}
            >
              {isAI 
                ? (id === typingMessageId ? displayMessage : messageText)
                : `"${messageText}"`
              }
            </Typography>
            {/* {isAI && !isFirstMessage && messageText && (
              <Button
                variant="text"
                startIcon={<VolumeUpIcon sx={{ width: 16, height: 16 }} />}
                onClick={() => handleDcentAudio(messageText)}
                sx={{
                  mt: 1,
                  color: '#4168F6',
                  fontSize: '12px',
                  padding: '4px 8px',
                  minWidth: 'auto',
                  textTransform: 'none',
                  '&:hover': {
                    backgroundColor: 'rgba(65, 104, 246, 0.04)'
                  }
                }}
              >
                도슨트 음성으로 듣기
              </Button>
            )} */}
          </>
        )}
        {isFirstMessage && (
          <>
            <Typography 
              sx={{ 
                fontSize: '14px',
                color: '#6B6B6B',
              }}
            >
              아래 항목을 선택하거나 질문을 직접
            </Typography>
            <Typography 
              sx={{ 
                fontSize: '14px',
                color: '#6B6B6B',
                mb: 1
              }}
            >
              입력해 주세요.
            </Typography>
            <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
              <Button
                variant="outlined"
                sx={{
                  borderColor: '#E4E4E4',
                  color: '#000000',
                  borderRadius: '20px',
                  fontWeight: 600,
                  '&:hover': {
                    borderColor: '#000000',
                    backgroundColor: '#000000',
                    color: '#FFFFFF'
                  }
                }}
                onClick={() => handleDocentButtonClick('작품 설명')}
              >
                작품 설명
              </Button>
              <Button
                variant="outlined"
                sx={{
                  borderColor: '#E4E4E4',
                  color: '#000000',
                  borderRadius: '20px',
                  fontWeight: 600,
                  '&:hover': {
                    borderColor: '#000000',
                    backgroundColor: '#000000',
                    color: '#FFFFFF'
                  }
                }}
                onClick={() => handleDocentButtonClick('작가 의도')}
              >
                작가 의도
              </Button>
              <Button
                variant="outlined"
                sx={{
                  borderColor: '#E4E4E4',
                  color: '#000000',
                  borderRadius: '20px',
                  fontWeight: 600,
                  '&:hover': {
                    borderColor: '#000000',
                    backgroundColor: '#000000',
                    color: '#FFFFFF'
                  }
                }}
                onClick={() => handleDocentButtonClick('비슷한 작품 추천')}
              >
                비슷한 작품 추천
              </Button>
            </Box>
          </>
        )}
      </Box>
    </Box>
  )};

  useEffect(() => {
    if (modalOpen) {
      // 모달이 열릴 때 history에 상태 추가
      window.history.pushState(null, '', window.location.pathname);

      // 뒤로가기 이벤트 리스너 추가
      const handlePopState = () => {
        setModalOpen(false);
      };

      window.addEventListener('popstate', handlePopState);

      // 컴포넌트 언마운트 시 이벤트 리스너 제거
      return () => {
        window.removeEventListener('popstate', handlePopState);
      };
    }
  }, [modalOpen, setModalOpen]);

  return <>
  <Modal
      open={modalOpen}
      onClose={() => setModalOpen(false)}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          position: 'relative',
          width: '100vw',  // 전체 너비로 변경
          height: '100vh', // 전체 높이로 변경
          backgroundColor: 'black', // 배경색 검정으로 설정
          outline: 'none',
          display: 'flex',
          alignItems: !isDocent ? 'center' : (drawerHeight === 'auto' || parseInt(drawerHeight) >= CHANGE_IMAGE_HEIGHT) ? 'flex-start' : 'center',
          justifyContent: 'center',
          '&::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            height: '120px',
            background: !isDocent ? 'none' : (drawerHeight === 'auto' || parseInt(drawerHeight) >= CHANGE_IMAGE_HEIGHT) ? 
              'linear-gradient(to bottom, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0) 100%)' : 'none',
            zIndex: 1
          }
        }}
      >
        <IconButton
          onClick={() => setModalOpen(false)}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: 'white',
            backgroundColor: 'rgba(0, 0, 0, 0)',
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.6)'
            },
            zIndex: 2  // z-index 추가
          }}
        >
          <CloseIcon sx={{ width: 24, height: 24 }}/>
        </IconButton>
        <Box
          sx={{
            height: !isDocent ? '100%' : (drawerHeight === 'auto' || parseInt(drawerHeight) >= CHANGE_IMAGE_HEIGHT) ? '100%' : 'calc(100vh - 200px)',
            width: '100%',
            display: 'flex',
            alignItems: !isDocent ? 'center' : (drawerHeight === 'auto' || parseInt(drawerHeight) >= CHANGE_IMAGE_HEIGHT) ? 'flex-start' : 'center',
            justifyContent: 'center'
          }}
        >
          <img
            src={selectedImage}
            style={{
              width: '100%',
              height: !isDocent ? '100%' : (drawerHeight === 'auto' || parseInt(drawerHeight) >= CHANGE_IMAGE_HEIGHT) ? 'auto' : '100%',
              objectFit: 'contain'
            }}
            alt="Enlarged view"
          />
        </Box>
        {isDocent && (
          <Box sx={{ position: 'absolute', bottom: 0, width: '100%' }}>
          <Drawer
          anchor="bottom"
          open={modalOpen}
          variant="persistent"  
          sx={{
            '& .MuiDrawer-paper': {
                position: 'relative',
                borderTopLeftRadius: 32,
                borderTopRightRadius: 32,
                borderColor: '#FFFFFF',
                backgroundColor: '#FFFFFF',
                height: drawerHeight,
                overflow: 'visible',
                transition: isDragging ? 'none' : 'height 0.3s ease-in-out', // 드래그 중에는 transition 비활성화
                maxHeight: '90vh',
            }
          }}
        >
           <Box
             onTouchStart={handleTouchStart}
             onTouchMove={handleTouchMove}
             onTouchEnd={handleTouchEnd}
             onMouseDown={handleMouseDown}
             onMouseUp={handleMouseUp}
             onMouseMove={handleMouseMove}  // 수정된 handleMouseMove 사용
             onMouseLeave={handleMouseUp} 
             sx={{ cursor: isDragging ? 'grabbing' : 'grab', userSelect: 'none', paddingTop: '8px'
             }}
           >
             <Puller />
           <Box sx={{ height: 16 }} />
           <Box sx={{ width: '100%', textAlign: 'center', mt: 2 }}>
            <Typography sx={{ fontSize: '24px', fontWeight: 600, color: 'rgba(0,0,0,0.9)' }}>{title}</Typography>
          </Box>
          <Box sx={{ width: '100%', textAlign: 'center'}}>
            <Typography sx={{ fontSize: '14px', fontWeight: 400, color: 'rgba(0,0,0,0.9)' }}>{artist}</Typography>
          </Box>

          <Divider sx={{ mx: 2, mt: 2}} />
          </Box>
          {drawerHeight === 'auto' || parseInt(drawerHeight) > COLLAPSED_HEIGHT ? (
            <>
            <Box sx={{ 
              flex: 1, 
              overflowY: 'auto',
              px: 2,
              pb: 2
            }} className="message-container">
              <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2, mt: 2 }}>  {/* 추가된 Box wrapper */}
                <Button 
                    variant="contained" 
                    sx={{
                        backgroundColor: '#EEF1FF',
                        color: '#4168F6',
                        fontWeight: 600,
                        fontSize: '14px',
                        borderRadius: '8px',
                        boxShadow: 'none',
                        padding: '4px 12px',
                        minWidth: 'auto',
                        '&:hover': {
                            backgroundColor: '#E5E9FF',
                            boxShadow: 'none',
                        },
                    }} 
                >
                AI 도슨트
              </Button>
              </Box>
              {messages.map((msg, index) => renderChatMessage(msg, index))}
            </Box>
            <Box 
              display="flex" 
              justifyContent="center" 
              sx={{
                pt: '16px',
                pb: '16px',
                pl: 2,
                pr: 2,
                position: 'sticky',
                bottom: 0,
                backgroundColor: 'white',
                zIndex: 1000
              }}
            >
              <Box sx={{ position: 'relative', width: '100%' }}>
                <TextField
                  value={chatText}
                  onChange={handleInputChange}
                  // onKeyDown={handleKeyDown}
                  fullWidth
                  multiline
                  maxRows={2}
                  placeholder="작품에 대해 궁금한 것을 입력하세요."
                  inputRef={chatInputRef}
                  sx={{
                    '.MuiInputBase-root': {
                      height: 'auto',
                      borderRadius: '12px',
                      backgroundColor: 'rgba(245, 247, 248, 1)',
                      padding: '0 14px', // 좌우 패딩 조정 (아이콘 공간 등 고려)
                      minHeight: '44px',
                      maxHeight: '88px',
                      overflow: 'auto', // 스크롤을 가능하게 함
                    },
                    '.MuiOutlinedInput-notchedOutline': {
                      border: 'none' // 외곽선 제거
                    },
                    '.MuiInputBase-input': {
                      padding: '12px 0', // 패딩 조정
                      boxSizing: 'border-box', // 높이 계산을 위한 박스 모델 설정
                      position: 'relative',
                      zIndex: 2,
                      textShadow: 'none', // 텍스트 그림자 제거
                      caretColor: 'black', // 포커스 커서 색상 설정
                      fontSize: '14px', // TextField와 동일한 폰트 크기
                      fontWeight: 400, // TextField와 동일한 폰트 굵기
                      lineHeight: '20px', // TextField와 동일한 줄 높이
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <IconButton color="inherit" onClick={handleSendClick} disabled={!isSend}>
                        <img src={isSend ? icons.send.active : icons.send.inactive} alt="send" style={{ width: '24px', height: '24px' }} />
                      </IconButton>
                    )
                  }}
                />
                {/* <HighlightedText aria-hidden="true">
                  {getHighlightedText(commentText)}
                </HighlightedText> */}
              </Box>
            </Box>
            {/* <Box sx={{ 
              p: 2, 
              borderTop: '1px solid #eee',
              backgroundColor: '#fff'
            }}>
              <Box sx={{ 
                display: 'flex',
                gap: 1
              }}>
                <Box sx={{ 
                  flex: 1,
                  border: '1px solid #ddd',
                  borderRadius: 2,
                  p: 1
                }}>
                  <Typography color="text.secondary">
                    작품에 대해 궁금한 것을 입력하세요
                  </Typography>
                </Box>
                <Button 
                  variant="contained"
                  sx={{ 
                    minWidth: 'auto',
                    px: 3,
                    backgroundColor: '#000',
                    '&:hover': {
                      backgroundColor: '#333'
                    }
                  }}
                >
                  전송
                </Button>
              </Box>
            </Box> */}
            </>
            ) : null}
            </Drawer>
            </Box>
        )}
      </Box>
    </Modal>
    </>
}



export default PostImageDetail;
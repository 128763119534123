import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  exhibitions: [],
  places: [],
  artworks: [],
  exhibitionArtWorks: [],
  availableExhibitionArtWorks: []
};

const adminSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    setExhibition: (state, action) => {
      state.exhibitions = action.payload;
    },
    addExhibition: (state, action) => {
      state.exhibitions.push(action.payload);
    },
    removeExhibition: (state, action) => {
      state.exhibitions = state.exhibitions.filter(exhibition => exhibition.id !== action.payload);
    },
    updateExhibition: (state, action) => {
      state.exhibitions = state.exhibitions.map(exhibition => 
        exhibition.id === action.payload.id ? action.payload : exhibition
      );
    },
    setPlaces: (state, action) => {
      state.places = action.payload;
    },
    setArtWorks: (state, action) => {
      state.artworks = action.payload;
    },
    setExhibitionArtWorks: (state, action) => {
      state.exhibitionArtWorks = action.payload;
    },
    setAvailableExhibitionArtWorks: (state, action) => {
      state.availableExhibitionArtWorks = action.payload;
    },
    addArt: (state, action) => {
      state.artworks.push(action.payload);
    },
    updateArt: (state, action) => {
      state.artworks = state.artworks.map(artwork => 
        artwork.id === action.payload.id ? action.payload : artwork
      );
    },
    removeArt: (state, action) => {
      state.artworks = state.artworks.filter(artwork => artwork.id !== action.payload);
    }
  }
});

export const { 
    setExhibition,
    setPlaces,
    addExhibition,
    setArtWorks,
    setExhibitionArtWorks,
    setAvailableExhibitionArtWorks,
    addArt,
    updateArt,
    removeExhibition,
    updateExhibition,
    removeArt
} = adminSlice.actions;

export default adminSlice.reducer;
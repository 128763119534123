import React, { useState } from 'react';
import { Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import MuseumIcon from '@mui/icons-material/Museum';
import { fetchDeleteBookmark } from '../../repository/bookmarks/bookmarksRepository';
const GridBookmarkCard = ({ post }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    if (post?.result?.is_deleted) {
      setOpen(true);
      return;
    }

    if (post.type === 'post') {
      navigate(`/posts/${post.result.id}`);
    } else if (post.type === 'exhibition_work') {
      navigate(`/exhibitions/${post.result.exhibition_id}/artworks/${post.result.id}`);
    }
  };

  const handleClose = (confirm) => {
    setOpen(false);
    if (confirm) {
      dispatch(fetchDeleteBookmark(post.type, post.result.id));
    }
  };

  return (
    <>
      <Box
        sx={{
          position: 'relative',
          paddingTop: '100%', // 1:1 비율 유지
          width: '100%',
          cursor: 'pointer'
        }}
        onClick={handleClick}
      >
        {post.type == 'post' ? (
          <Box
            component="img"
            src={post?.result?.posts?.[0]?.image_url || ''}
            alt={post?.result?.posts?.[0]?.content || ''}
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              opacity: post?.result?.is_deleted ? 0.6 : 1,
              filter: post?.result?.is_deleted ? 'blur(1px)' : 'none'
            }}
          />
        ) : (
          <Box>
            <Box
              component="img"
              src={post.result.image_url}
              alt={post.result.title_kr}
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                objectFit: 'cover'
              }}
            />
            <Box
              sx={{
                position: 'absolute',
                top: 4,
                left: 4,
                width: 24,
                height: 24,
                borderRadius: '50%',
                backgroundColor: 'rgba(0,0,0,0.5)',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <MuseumIcon 
                sx={{
                  color: 'white',
                  fontSize: 16
                }}
              />
            </Box>
          </Box>
        )}
      </Box>
      <Dialog
        open={open}
        onClose={() => handleClose(false)}
      >
        <DialogTitle>알림</DialogTitle>
        <DialogContent>
          <DialogContentText>
            삭제된 게시물입니다. 북마크에서 제거하시겠습니까?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose(false)} color="primary">
            취소
          </Button>
          <Button onClick={() => handleClose(true)} color="primary" autoFocus>
            확인
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default GridBookmarkCard; 
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  exhibitions: [],
  exhibition: {},
  artwork: {},
  artworkRecommend: []
};

const exhibitionSlice = createSlice({
  name: 'exhibition',
  initialState,
  reducers: {
    setExhibition: (state, action) => {
      state.exhibitions = action.payload;
    },
    setExhibitionDetail: (state, action) => {
      state.exhibition = action.payload;
    },
    updateExhibitionBookmark: (state, action) => {
        const { id, isBookmarked } = action.payload;
        const exhibition = state.exhibitions.find(ex => ex.id === id);
        if (exhibition) {
          exhibition.is_bookmarked = isBookmarked;
          exhibition.bookmarks_count = isBookmarked 
            ? exhibition.bookmarks_count + 1 
            : exhibition.bookmarks_count - 1;
        }
        // exhibition detail의 북마크 상태와 카운트도 업데이트
        if (state.exhibition.id === id) {
          state.exhibition.is_bookmarked = isBookmarked;
          state.exhibition.bookmarks_count = isBookmarked 
            ? state.exhibition.bookmarks_count + 1 
            : state.exhibition.bookmarks_count - 1;
        }
    },
    updateArtWorkBookmark: (state, action) => {
        const { id, isBookmarked } = action.payload;
        state.artwork.is_bookmarked = isBookmarked;
    },
    updateArtWorkLike: (state, action) => {
        const { id, isLiked } = action.payload;
        state.artwork.is_liked = isLiked;
        state.artwork.likes_count = isLiked 
            ? state.artwork.likes_count + 1 
            : state.artwork.likes_count - 1;
    },
    setExhibitionArtWork: (state, action) => {
        state.artwork = action.payload;
    },
    setExhibitionArtWorkRecommend: (state, action) => {
        state.artworkRecommend = action.payload;
    }
  }
});

export const { 
    setExhibition,
    setExhibitionDetail,
    updateExhibitionBookmark,
    updateArtWorkBookmark,
    setExhibitionArtWork,
    setExhibitionArtWorkRecommend,
    updateArtWorkLike
} = exhibitionSlice.actions;

export default exhibitionSlice.reducer;
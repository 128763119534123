import { createSlice } from '@reduxjs/toolkit';

export const postsSlice = createSlice({
  name: 'posts',
  initialState: {
    editorType: '',
    editors: [
      { // 첫 번째 게시글의 초기값 설정
        id:1,
        content: "",
        image_url: "",
        location: null,
        art: null,
        exhibition: null,
        rich_content: [],
        hashtags: [],
        rating: 0,
      }
    ],
    loading: false,
    feeds: [],
    popular:[],
    feeds_next: null,
    popular_next: null,
    detail:{
      id:1,
      content: "",
      rich_content: [],
      hashtags: [],
      image_url: "",
      location: null,
      art: null,
      rating: 0,
      exhibition: null
    }
  },
  reducers: {
    setFeedList: (state, action) => {
      state.feeds = action.payload.results;
      state.feeds_next = action.payload.next;
    },
    moreFeedList: (state, action) => {
      state.feeds = [...state.feeds, ...action.payload.results]; // Append new posts
      state.feeds_next = action.payload.next;
    },
    setPopular: (state, action) => {
      state.popular = action.payload.results;
      state.popular_next = action.payload.next;
    },
    morePopular: (state, action) => {
      state.popular = [...state.popular, ...action.payload.results]; // Append new posts
      state.popular_next = action.payload.next;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setEditorType: (state, action) => {
      state.editorType = action.payload;
    },
    updateEditorImageAtIndex: (state, action) => {
      const { index, image_url } = action.payload;
      if (index < state.editors.length) {
        state.editors[index].image_url = image_url;
      } else {
        // editors 배열의 크기가 10보다 작을 때만 새로운 editor 추가
        if (state.editors.length < 10) {
          state.editors.push({
            id: index + 1,
            content: "",
            rich_content: [],
            hashtags: [],
            image_url: image_url,
            location: null,
            art: null,
            rating: 0,
            exhibition: null
          });
        }
      }
    },
    updateEditorArtAtIndex: (state, action) => {
      const { index, art } = action.payload;
      if (index < state.editors.length) {  // 인덱스가 유효한지 확인
        state.editors[index].art = art;  // art가 null이어도 할당 가능
      }
      // 불변성을 유지하면서 특정 인덱스의 객체를 업데이트
      // state.editors = state.editors.map((editor, i) => 
      //   i === index ? { ...editor, art } : editor
      // );
    },
    removeEditorImage: (state, action) => {
      const index = action.payload;
      // 해당 인덱스를 배열에서 제거
      state.editors[index].image_url = "";
      // if (state.editors.length === 1) {
      //   // 배열의 길이가 1이면 이미지 정보만 초기화
      //   state.editors[index].image_url = "";
      // } else if (index < state.editors.length) {
      //   // 배열 길이가 1보다 크면 해당 인덱스의 editor 객체를 제거
      //   state.editors.splice(index, 1);
      // }
    },
    addEditor: (state, action) => {
      // 게시글 배열이 10개 미만인 경우에만 추가
      const { image_url, content, location, rating, exhibition } = action.payload;

      if (state.editors.length < 10) {
        state.editors.push({
          id: state.editors.length + 1,  // ID 자동 할당
          content: content ? content : "",
          image_url: image_url ? image_url : "",
          location: location ? location : null,
          exhibition: exhibition ? exhibition : null,
          art: null,
          rating: rating ? rating : 0,
        });
      }
    },
    updateEditorAtIndex: (state, action) => {
      // 특정 인덱스의 게시글 정보를 업데이트합니다.
      const { index, content, rich_content, hashtags, location, rating, exhibition } = action.payload;
      // 해당 인덱스의 게시글이 존재하는지 확인
      if (state.editors[index]) {
        const editor = state.editors[index];
    
        if (content !== undefined) {
          editor.content = content;
        }
        if (rich_content !== undefined) {
          editor.rich_content = rich_content;
        }
        if (hashtags !== undefined) {
          editor.hashtags = hashtags;
        }
        if (location !== undefined) {
          editor.location = { ...editor.location, ...location };
        }
        if (rating !== undefined) {
          editor.rating = rating;
        }
        if (exhibition !== undefined) {
          editor.exhibition = exhibition;
        }
      }
    },
    updateFeedById: (state, action) => {
      const { id, content, rich_content, hashtags, location, rating, is_liked } = action.payload;

      // 해당 ID를 가진 게시글의 인덱스를 찾습니다.
      const index = state.feeds.findIndex(feed => feed.id === id);

      // 인덱스 검사: 배열 범위 내에 있는지 확인합니다.
      if (index !== -1) {
          const feed = state.feeds[index];

          if (content !== undefined) {
              feed.content = content;
          }
          if (rich_content !== undefined) {
              feed.rich_content = rich_content;
          }
          if (hashtags !== undefined) {
              feed.hashtags = hashtags;
          }
          if (location !== undefined) {
              feed.location = { ...feed.location, ...location };
          }
          if (rating !== undefined) {
              feed.rating = rating;
          }
          if (is_liked !== undefined) {
              // 좋아요 상태가 변경되면 좋아요 수도 조정합니다.
              feed.is_liked = is_liked;
              if (is_liked) {
                  feed.likes_count++;
              } else if (feed.likes_count > 0) {
                  feed.likes_count--;
              }
          }
      } else {
          console.error("No feed found with id: ", id);
      }
    },
    clearLocationFromEditor: (state, action) => {
      const { index } = action.payload;  // 인덱스를 action.payload에서 추출
      const editor = state.editors[index];  // 해당 인덱스의 editor를 가져옴
    
      if (editor) {  // editor가 존재하면 위치 정보를 초기화
        editor.location = null;
      }
    },
    clearExhibitionFromEditor: (state, action) => {
      const { index } = action.payload;  // 인덱스를 action.payload에서 추출
      const editor = state.editors[index];  // 해당 인덱스의 editor를 가져옴
    
      if (editor) {  // editor가 존재하면 위치 정보를 초기화
        editor.exhibition = null;
      }
    },
    clearRatingFromEditor: (state, action) => {
      const { index } = action.payload;  // 인덱스를 action.payload에서 추출
      const editor = state.editors[index];  // 해당 인덱스의 editor를 가져옴
    
      if (editor) {  // editor가 존재하면 위치 정보를 초기화
        editor.rating = 0;
      }
    },
    updateEditor: (state, action) => {
      // postId를 사용하여 특정 게시글 업데이트
      const index = state.editors.findIndex(editor => editor.id === action.payload.id);
      if (index !== -1) {
        state.editors[index] = action.payload;
      }
    },
    removeEditor: (state, action) => {
      // postId를 사용하여 특정 게시글 삭제
      state.editors = state.editors.filter(editor => editor.id !== action.payload.id);
    },
    clearEditors: (state) => {
      // 모든 게시글 삭제
      state.editors = [{
        id:1,
        content: "",
        rich_content: [],
        hashtags: [],
        image_url: "",
        location: null,
        art:null,
        rating: 0
      }];
    },
    addCountCommentToFeed: (state, action) => {
      const index = state.feeds.findIndex(feed => feed.id === action.payload);
      if (index !== -1) {
        state.feeds[index].comments_count++;
      }
    },
    addCountCommentToPopular: (state, action) => {
      const index = state.popular.findIndex(post => post.id === action.payload);
      if (index !== -1) {
        state.popular[index].comments_count++;
      }
    },
    addCountCommentToDetail: (state, action) => {
      if (state.detail.id === action.payload) {
        state.detail.comments_count++;
      }
    },
    setPostDetail: (state, action) => {
      state.detail = action.payload.length > 0 ? action.payload[0] : state.detail;
    },
    deleteFeedById: (state, action) => {
      state.feeds = state.feeds.filter(feed => feed.id !== action.payload);
    }
  },
});

// Action creators are generated for each case reducer function
export const { 
  setLoading, setFeedList, moreFeedList, 
  setEditorType, addEditor, 
  updateEditor, removeEditorImage, updateEditorImageAtIndex, removeEditor, clearEditors, 
  updateEditorAtIndex, clearLocationFromEditor, clearExhibitionFromEditor, clearRatingFromEditor, updateFeedById, 
  addCountCommentToFeed, addCountCommentToPopular, addCountCommentToDetail, updateEditorArtAtIndex, setPostDetail,
  setPopular, morePopular, deleteFeedById
} = postsSlice.actions;

export default postsSlice.reducer;
// src/app/store.js
import { configureStore } from '@reduxjs/toolkit';
import userReducer from './repository/user/userSlice';
import commonReducer from './repository/common/commonSlice';
import postsReducer from './repository/posts/postsSlice';
import bookmarksReducer from './repository/bookmarks/bookmarksSlice';
import profilesReducer from './repository/profiles/profilesSlice';
import commentsReducer from './repository/comments/commentsSlice';
import artworkCommentsReducer from './repository/comments/artworkCommentsSlice';
import searchReducer from './repository/search/searchSlice';
import alertReducer from './repository/alert/alertSlice';
import locationReducer from './repository/location/locationSlice';
import docentReducer from './repository/docent/docentSlice';
import exhibitionReducer from './repository/exhibition/exhibitionSlice';
import adminReducer from './repository/admin/adminSlice';

export const store = configureStore({
  reducer: {
    user: userReducer,
    docent: docentReducer,
    common: commonReducer,
    posts: postsReducer,
    bookmarks: bookmarksReducer,
    profiles: profilesReducer,
    comments: commentsReducer,
    artworkComments: artworkCommentsReducer,
    search: searchReducer,
    alert: alertReducer,
    location: locationReducer,  
    exhibition: exhibitionReducer,
    admin: adminReducer,
    devTools: process.env.NODE_ENV !== 'production' // 개발 환경에서만 DevTools 활성화
  },
});

import { 
    getExhibition, getPlaces, 
    getPlacesAutoComplete, postExhibition,
    getArts, postArts, putArts, deleteArts,
    getExhibitionArtWorks,
    putExhibitionArtWorks, deleteExhibition,
    putExhibitionArtWorksSort
} from '../../api/SwissApi';
import { setExhibition, setPlaces, 
    addExhibition, setArtWorks, setExhibitionArtWorks,
    setAvailableExhibitionArtWorks, addArt, updateArt, removeArt, removeExhibition,
    updateExhibition
} from './adminSlice';

export const fetchExhibitionList = () => async (dispatch) => {
    try {
        const {results} = await getExhibition("all");
        dispatch(setExhibition(results));
    } catch (error) {
        console.error('전시회 목록을 불러오는데 실패했습니다:', error);
    }
}

export const fetchPlaces = () => async (dispatch) => {
    try {
        const {results} = await getPlaces();    
        dispatch(setPlaces(results));
    } catch (error) {
        console.error('장소 목록을 불러오는데 실패했습니다:', error);
    }
}

export const fetchPlacesAutoComplete = (keyword) => async (dispatch) => {
    try {
        const places = await getPlacesAutoComplete(keyword);
        dispatch(setPlaces(places));
    } catch (error) {
        console.error('장소 자동완성 실패:', error);
    }
}

export const fetchExhibitionCreate = (params) => async (dispatch) => {
    try {
        const {exhibition} = await postExhibition(params);
        dispatch(addExhibition(exhibition));    
    } catch (error) {
        console.error('전시회 생성 실패:', error);
    }
}

export const fetchUpdateExhibition = (exhibitionId, params) => async (dispatch) => {
    try {
        const {exhibition} = await putExhibitionArtWorks(exhibitionId, params);
        console.log(exhibition);
        dispatch(updateExhibition(exhibition));
    } catch (error) {
        console.error('전시회 수정 실패:', error);
    }
}
export const fetchDeleteExhibition = (exhibitionId) => async (dispatch) => {
    try {
        await deleteExhibition(exhibitionId);
        dispatch(removeExhibition(exhibitionId));
    } catch (error) {
        console.error('전시회 삭제 실패:', error);
    }
}

export const fetchArtCreate = (params) => async (dispatch) => {
    try {
        const {unique_art} = await postArts(params);
        dispatch(addArt(unique_art));
    } catch (error) {
        console.error('작품 생성 실패:', error);
        alert(error.response.data.message);
    }
}

export const fetchUpdateArt = (artId, params) => async (dispatch) => {
    try {
        const result = await putArts(artId, params);
        dispatch(updateArt(result));
    } catch (error) {
        console.error('작품 수정 실패:', error);
    }
}

export const fetchDeleteArt = (artId) => async (dispatch) => {
    try {
        await deleteArts(artId);
        dispatch(removeArt(artId));
    } catch (error) {
        console.error('작품 삭제 실패:', error);
        alert(error.response.data.error);

    }
}
export const fetchUpdateArtWorksSort = (exhibitionId, params) => async (dispatch) => {
    try {
        // const filteredParams = params.map(item => ({
        //     id: item.id,
        //     sort_index: item.sort_index
        // }));
        dispatch(setExhibitionArtWorks(params));
        await putExhibitionArtWorksSort(exhibitionId, params);
        
    } catch (error) {
        console.error('작품 정렬 실패:', error);
        alert(error.response.data);
    }
}
export const fetchArtWorks = () => async (dispatch) => {
    try {
        const {results} = await getArts();
        dispatch(setArtWorks(results));
    } catch (error) {
        console.error('작품 목록을 불러오는데 실패했습니다:', error);
    }
}

export const fetchExhibitionArtWorks = (exhibitionId) => async (dispatch) => {
    try {
        const {results} = await getExhibitionArtWorks(exhibitionId);
        dispatch(setExhibitionArtWorks(results));
        const {results:artworksList} = await getArts();

        const uniqueArtworks = artworksList.filter(artwork => 
            !results.some(result => result.id === artwork.id)
        );
        dispatch(setAvailableExhibitionArtWorks(uniqueArtworks));
    } catch (error) {
        console.error('전시회 작품 목록을 불러오는데 실패했습니다:', error);
    }
}

export const fetchExhibitionArtWorksMapping = (exhibitionId, params) => async () => {
    try {
        const result = await putExhibitionArtWorks(exhibitionId, params);
        console.log(result);
    } catch (error) {
        console.error('전시회 작품 매핑 실패:', error);
        alert(error.response.data);
    }
}
import React, {useState, useEffect} from 'react';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const YoutubeEmbed = ({ url, onClose, height = "250px" }) => {
  const [videoId, setVideoId] = useState(null);

  useEffect(() => {
    window.history.pushState(null, null, window.location.pathname);
    const handlePopState = (event) => {
      event.preventDefault();
      onClose();
    };

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);

  useEffect(() => {
    try {
      const id = new URL(url).searchParams.get('v');
      setVideoId(id);
    } catch (error) {
      console.error("Invalid URL:", error);
      setVideoId(null);
    }
   
  }, [url]);

    return (
        <div style={{ 
          position: "fixed", 
          bottom: '55px', 
          left: "50%", 
          transform: "translateX(-50%)",
          maxWidth: '600px',
          width: "100%", 
          height: height, 
          // paddingBottom: "56.25%", // 16:9 aspect ratio
          // backgroundColor: "black",
          boxSizing: 'border-box',
          padding: '0',
          '@media (min-width: 1200px)': {
              padding: '0 20px',
          },
          display: 'flex',
          flexDirection: 'column',
          // justifyContent: 'center',
          // alignItems: 'center',
          zIndex: 1000,
        }}>
          <div style={{ display: 'flex', backgroundColor: '#f5f5f5', justifyContent: 'flex-end' }}>
            <IconButton 
              sx={{ 
                zIndex: 1100,
                color: 'black'
            }} 
            onClick={onClose}
          >
              <CloseIcon />
          </IconButton>
          </div>
          <iframe
            src={`https://www.youtube.com/embed/${videoId}`}
            title="YouTube video"
            allowFullScreen
            style={{
              width: "100%",
              height: "100%",
              border: "none",
            }}
          />
        </div>
    );
};

export default YoutubeEmbed;
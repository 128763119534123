import { createSlice } from '@reduxjs/toolkit';

export const artworkCommentsSlice = createSlice({
  name: 'artworkComments',
  initialState: {
    is_reply_to: 0,
    comments: [],
  },
  reducers: {
    setComments: (state, action) => {
      // 각 comment의 replies 배열을 시간순으로 정렬
      const sortedComments = action.payload.map(comment => ({
        ...comment,
        replies: comment.replies?.sort((a, b) => 
          new Date(a.created_at) - new Date(b.created_at)
        ) || []
      }));
      
      // comments 배열 자체를 시간순으로 정렬
      state.comments = sortedComments.sort((a, b) => 
        new Date(a.created_at) - new Date(b.created_at)
      );
    },
    addComments: (state, action) => {
      state.comments.push(action.payload);
    },
    addReplies: (state, action) => {
      state.comments.find(comment => comment.id === action.payload.is_reply_to)
      .replies.push(action.payload.comment);
    },
    setIsReply: (state, action) => {
      state.is_reply_to = action.payload;
    },
    addLikeComment: (state, action) => {
      state.comments.find(comment => comment.id === action.payload)
      .likes_count += 1;
    },
    deleteLikeComment: (state, action) => {
      state.comments.find(comment => comment.id === action.payload)
      .likes_count -= 1;
    },
    addLikeReply: (state, action) => {
      state.comments.find(comment => comment.id === action.payload.comment_id)
      .replies.find(reply => reply.id === action.payload.reply_id)
      .likes_count += 1;
    },
    deleteLikeReply: (state, action) => {
      state.comments.find(comment => comment.id === action.payload.comment_id)
      .replies.find(reply => reply.id === action.payload.reply_id)
      .likes_count -= 1;
    },
    deleteCommentById: (state, action) => {
      state.comments.find(comment => comment.id === action.payload)
      .is_deleted = true;
    },
    deleteReplyById: (state, action) => {
      state.comments.find(comment => comment.id === action.payload.comment_id)
      .replies.find(reply => reply.id === action.payload.reply_id)
      .is_deleted = true;
    },
    clearComments: (state, action) => {
      state.comments = [];
    }
  },
});

// Action creators are generated for each case reducer function
export const { 
    setComments, addComments, addReplies, clearComments, setIsReply, 
    addLikeComment, deleteLikeComment, addLikeReply, deleteLikeReply, 
    deleteCommentById, deleteReplyById 
} = artworkCommentsSlice.actions;

export default artworkCommentsSlice.reducer;
import { setComments, addComments, addReplies, addLikeComment, deleteLikeComment, deleteLikeReply, addLikeReply, deleteCommentById, deleteReplyById } from './artworkCommentsSlice';
import { getExhibitionArtWorkComments, postExhibitionArtWorkComments, 
    postExhibitionArtWorkCommentLikes, deleteExhibitionArtWorkCommentLikes,
    deleteExhibitionArtWorkComments, deleteComment } from '../../api/SwissApi';

export const fetchPostArtworkComments = (exhibitionId, artworkId, bodyParam) => async (dispatch) => {
    try {
        const comment = await postExhibitionArtWorkComments(exhibitionId, artworkId, bodyParam);
        if (bodyParam.is_reply_to) {
            dispatch(addReplies({comment: comment, is_reply_to: bodyParam.is_reply_to}));
        } else {
            dispatch(addComments(comment));
        }

    } catch (error) {
        console.error('Failed to fetch bookmark lists:', error);
    }
};

export const fetchGetArtworkComments = (exhibitionId, artworkId) => async (dispatch) => {
    try {
        const {results} = await getExhibitionArtWorkComments(exhibitionId, artworkId); 
        dispatch(setComments(results));
    } catch (error) {
        console.error('Failed to fetch comments:', error);
    }
}

export const fetchPostArtworkCommentLike = (exhibitionId, artworkId, commentId) => async (dispatch) => {
    try {
        const response = await postExhibitionArtWorkCommentLikes(exhibitionId, artworkId, commentId);
        dispatch(addLikeComment(commentId));
        console.log(response);
    } catch (error) {
        console.error('Failed to fetch comment like:', error);
    }
}

export const fetchDeleteArtworkCommentLike = (exhibitionId, artworkId, commentId) => async (dispatch) => {
    try {
        const response = await deleteExhibitionArtWorkComments(exhibitionId, artworkId, commentId);
        dispatch(deleteLikeComment(commentId));
        console.log(response);
    } catch (error) {
        console.error('Failed to fetch comment like:', error);
    }
}

export const fetchPostArtworkReplyLike = (exhibitionId, artworkId, commentId, replyId) => async (dispatch) => {
    try {
        const response = await postExhibitionArtWorkCommentLikes(exhibitionId, artworkId, replyId);
        dispatch(addLikeReply({comment_id: commentId, reply_id: replyId}));
        console.log(response);
    } catch (error) {
        console.error('Failed to fetch comment like:', error);
    }
}

export const fetchDeleteArtworkReplyLike = (exhibitionId, artworkId, commentId, replyId) => async (dispatch) => {
    try {
        const response = await deleteExhibitionArtWorkCommentLikes(exhibitionId, artworkId, replyId);
        dispatch(deleteLikeReply({comment_id: commentId, reply_id: replyId}));
        console.log(response);
    } catch (error) {
        console.error('Failed to fetch comment like:', error);
    }
}

export const fetchDeleteArtworkComment = (exhibitionId, artworkId, commentId) => async (dispatch) => {
    try {
        const response = await deleteExhibitionArtWorkComments(exhibitionId, artworkId, commentId);
        dispatch(deleteCommentById(commentId));
        console.log(response);
    } catch (error) {
        console.error('Failed to fetch comment delete:', error);
    }
}

export const fetchDeleteArtworkReply = (exhibitionId, artworkId, commentId, replyId) => async (dispatch) => {
    try {
        const response = await deleteExhibitionArtWorkComments(exhibitionId, artworkId, replyId);
        dispatch(deleteReplyById({comment_id: commentId, reply_id: replyId}));
        console.log(response);
    } catch (error) {
        console.error('Failed to fetch comment delete:', error);
    }
}
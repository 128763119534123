import axios from 'axios';
const artApi = axios.create({
    baseURL: `${process.env.REACT_APP_ART_API_URL}`,
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
    }
  });
  
export const getDocentExhibitionWorks = async (exhibitionWorkId) => {
    const response = await artApi.get(`/api/docents/exhibition-works/${exhibitionWorkId}`);
    return response.data;
};
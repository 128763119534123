import React, { useEffect, useRef, useState } from 'react';
import Quill from 'quill';
import 'quill/dist/quill.bubble.css'; // snow 대신 bubble 테마 사용
import { Mention, MentionBlot } from 'quill-mention';
import { Box, Typography } from '@mui/material';
import './QuillEditor.css'; // 스타일 커스터마이징을 위한 CSS
// Hashtag Blot 정의 수정
class HashtagBlot extends Quill.import('blots/inline') {
  static create() {
    const node = super.create();
    node.setAttribute('class', 'ql-hashtag');
    return node;
  }

  static formats() {
    return true;
  }
}
HashtagBlot.blotName = 'hashtag';
HashtagBlot.tagName = 'span';

// Quill에 등록
Quill.register('formats/hashtag', HashtagBlot);
Quill.register(MentionBlot);
Quill.register('modules/mention', Mention);

const QuillEditor = ({ onChange, initialValue = '', readOnly = false }) => {
  const editorRef = useRef(null);
  const quillRef = useRef(null);
  const [showPlaceholder, setShowPlaceholder] = useState(true);
 
  // 해시태그 추출 함수
  const extractHashtags = (text) => {
    const hashtagPattern = /#[ㄱ-ㅎㅏ-ㅣ가-힣a-zA-Z0-9]+/g;
    return (text.match(hashtagPattern) || []).map(tag => tag.slice(1));
  };

  // 멘션 추출 함수
  const extractMentions = (delta) => {
    const mentions = [];
    delta.ops.forEach(op => {
        if (op.insert && op.insert.mention) {
        mentions.push({
            id: op.insert.mention.id,
            value: op.insert.mention.value
        });
        }
    });
    return mentions;
  };

  useEffect(() => {
    if (quillRef.current) {
      return;
    }
    if (editorRef.current) {
      quillRef.current = new Quill(editorRef.current, {
        theme: 'bubble',
        readOnly: readOnly,
        placeholder: '',
        modules: {
          toolbar: false,
          // ToDo: 멘션 개발필요
          // mention: {
          //   allowedChars: /^[A-Za-z\d\-_]*$/,
          //   mentionDenotationChars: ['@'],
          //   source: function(searchTerm, renderList, mentionChar) {
          //     const values = [
          //       { id: 1, value: '임수빈' },
          //       { id: 2, value: '정유진' }
          //     ];
              
          //     if (mentionChar === '@') {
          //       const matches = values.filter(item => 
          //         item.value.toLowerCase().includes(searchTerm.toLowerCase())
          //       );
          //       renderList(matches, searchTerm);
          //     }
          //   },
          //   renderItem: function(item) {
          //     return `@${item.value}`;
          //   },
          //   blotName: 'mention',
          //   dataAttributes: ['id', 'value'],
          //   mentionSelect: function(item, insertItem) {
          //     insertItem(item);
          //   }
          // },
        },
      });

      // 초기값이 있는 경우 설정
      if (initialValue) {
        setShowPlaceholder(false);
        // Array 형식인 경우 (ops 배열)
        if (Array.isArray(initialValue)) {
          const delta = { ops: initialValue };
          quillRef.current.setContents(delta);
          if (!readOnly) {
            quillRef.current.focus();
            const length = quillRef.current.getLength();  // 전체 텍스트 길이 가져오기
            quillRef.current.setSelection(length - 1, length - 1);  // 커서를 끝으로 이동
          }
        }
        // HTML 형식인 경우
        else if (typeof initialValue === 'string') {
          quillRef.current.root.innerHTML = initialValue;
          if (!readOnly) {
            quillRef.current.focus();
            const length = quillRef.current.getLength();
            quillRef.current.setSelection(length - 1, length - 1);
          }
        }

        // 읽기 전용 모드에서 해시태그 스타일 적용
        if (readOnly) {
          const text = quillRef.current.getText();
          const hashtagPattern = /#[ㄱ-ㅎㅏ-ㅣ가-힣a-zA-Z0-9]+/g;
          let match;
          
          while ((match = hashtagPattern.exec(text)) !== null) {
            const hashtag = match[0];
            const startIndex = match.index;
            
            quillRef.current.formatText(startIndex, hashtag.length, {
              color: "#007BFF",
              bold: true,
            });
          }
        }
      }

      // 읽기 전용이 아닐 때만 text-change 이벤트 리스너 추가
      if (!readOnly) {
        // 한글 입력 완료 이벤트 감지
        quillRef.current.root.addEventListener('input', () => {
          setShowPlaceholder(false);
        });

        quillRef.current.on("text-change", (delta, oldDelta, source) => {
          if (source === "user") {
            const text = quillRef.current.getText();
            // 플레이스홀더 표시 여부를 결정할 때는 trim()된 텍스트 길이 사용
            setShowPlaceholder(text.trim().length === 0);

            // 먼저 모든 텍스트의 스타일을 초기화
            quillRef.current.formatText(0, text.length, {
              color: false,
              bold: false,
            });
            
            // 해시태그 정규식 패턴 수정 (자음, 모음 포함)
            const hashtagPattern = /#[ㄱ-ㅎㅏ-ㅣ가-힣a-zA-Z0-9]+/g;
            let match;
            
            // 모든 해시태그 찾기
            while ((match = hashtagPattern.exec(text)) !== null) {
              const hashtag = match[0];
              const startIndex = match.index;
              
              // 해시태그 스타일 적용
              quillRef.current.formatText(startIndex, hashtag.length, {
                color: "#007BFF",
                bold: true,
              });
            }
            if (onChange) {
              const content = {
                rich_content: quillRef.current.getContents().ops,
                // 최종 content에서도 trim() 적용
                content: quillRef.current.getText().trim(),
                hashtags: extractHashtags(text),
              };
              onChange(content);
            }
          }
        });
      }
    }

    // cleanup
    return () => {
      if (quillRef.current && !readOnly) {
        quillRef.current.root.removeEventListener('compositionend', () => {});
      }
    };
  }, [readOnly, initialValue]); // readOnly와 initialValue를 의존성 배열에 추가

  return (
    <Box sx={{ 
      position: 'relative', 
      padding: readOnly ? 0 : '12px',
      margin: 0,
      marginBottom: 2,
      borderRadius: '4px'
    }}>
      {showPlaceholder && !readOnly && (
        <Box
          sx={{
            position: 'absolute',
            top: '12px',
            left: '12px',
            pointerEvents: 'none',
            p:0,
            pb:1,
            zIndex: 1,
            textAlign: 'left !important',
          }}
        >
          <Typography sx={{
            fontSize: '15px',
            color: '#666',
            lineHeight: 1.5,
            fontWeight: 500,
            marginBottom: 0
          }}>
            작품에 대한 생각을 공유해 주세요!
          </Typography>
          <Typography sx={{
            fontSize: '15px',
            color: '#666',
            lineHeight: 1.5,
            fontWeight: 500,
            marginTop: 0,
            marginLeft: 0,
            padding: 0
          }}>
            <Typography component="span" sx={{ color: '#1173FF', p:0}}>#태그</Typography>를 추가할 수도 있어요.
          </Typography>
        </Box>
      )}
      <div ref={editorRef} />
    </Box>
  );
};

export default QuillEditor;
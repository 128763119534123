import { useEffect, useState, useRef } from 'react';
import { Box, Typography, IconButton, Grid, Fab, BottomNavigation, BottomNavigationAction, Slider } from '@mui/material';
import BackNavigationHeader from '../../common/components/header/BackNavigationHeader';
import ArtWorkCard from './ArtWorkCard';
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from 'react-redux';
import { fetchExhibitionArtWork, fetchExhibitionArtWorkRecommend, fetchExhibitionDetail } from '../../repository/exhibition/exhibitionRepository';
import { useParams, useNavigate } from 'react-router-dom';
import { useCustomNavigate } from '../../common/hooks/useCustomNavigate';
import { useSnakeBarAlert } from '../../common/hooks/useSnakeBarAlert';
import CustomSnackbar from '../../common/components/exception/CustomSnackbar';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import DocentList from './DocentList';

const StyledImage = styled('img')({
    width: '100%',
    height: '150px',
    objectFit: 'cover',
    borderRadius: '8px',
});


const ExhibitionArtWorkPage = () => {
    const dispatch = useDispatch();
    const { exhibition_id, artwork_id } = useParams();
    const artwork = useSelector((state) => state.exhibition.artwork);
    const exhibition = useSelector((state) => state.exhibition.exhibition);
    const artworkRecommend = useSelector((state) => state.exhibition.artworkRecommend);
    
    const { onMovePostDetailPage, onMoveExhibitionDetailPage } = useCustomNavigate();
    const { alertExhibitionArtWork, snackbarState, handleCloseSnackbar } = useSnakeBarAlert();
    const navigate = useNavigate();
    
    const [isPlaying, setIsPlaying] = useState(false);
    const [showAudioPlayer, setShowAudioPlayer] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
        // alertExhibitionArtWork();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                await fetchExhibitionArtWork(dispatch, exhibition_id, artwork_id);
                await fetchExhibitionArtWorkRecommend(dispatch, exhibition_id, artwork_id);
                if (Object.keys(exhibition).length === 0 || exhibition.id !== exhibition_id) {
                    dispatch(fetchExhibitionDetail(exhibition_id));
                }
            } catch (error) {
                console.error('데이터 로딩 중 에러 발생:', error);
            }
        };
        fetchData();
    }, [exhibition_id, artwork_id]);

    const currentIndex = exhibition?.exhibition_works?.findIndex(work => work.id == artwork_id);
    const isFirstArtwork = currentIndex === 0;
    const isLastArtwork = currentIndex === exhibition?.exhibition_works?.length - 1;

    const handleAudioPlay = () => {
        setShowAudioPlayer(true);
    };

    const handleAudioClose = () => {
        const audio = document.getElementById('audio-guide');
        if (audio) {
            audio.pause();
            audio.currentTime = 0;
        }
        setIsPlaying(false);
        setShowAudioPlayer(false); // Ensure this line is present to update the state
    };

    const handlePreviousArtwork = () => {
        if (!isFirstArtwork) {
            const previousIndex = currentIndex - 1;
            const previousArtworkId = exhibition.exhibition_works[previousIndex].id;
            navigate(`/exhibitions/${exhibition_id}/artworks/${previousArtworkId}`);
        }
    };

    const handleNextArtwork = () => {
        if (!isLastArtwork) {
            const nextIndex = currentIndex + 1;
            const nextArtworkId = exhibition.exhibition_works[nextIndex].id;
            navigate(`/exhibitions/${exhibition_id}/artworks/${nextArtworkId}`);
        }
    };

    const handleMoveBack = () => {
        onMoveExhibitionDetailPage(exhibition_id);
    }

    return (
        <Box
            sx={{
                width: '100%',
                padding: '0',
                boxSizing: 'border-box',
                '@media (min-width: 600px)': {
                    maxWidth: '600px',
                    padding: '0',
                    margin: '0 auto',
                },
            }}
        >
            <BackNavigationHeader onMove={handleMoveBack}/>
            <Box sx={showAudioPlayer && {mb: '400px'}}>
                <ArtWorkCard artwork={artwork} exhibitionId={exhibition_id} />
                <Box sx={{ pl: 2, pr: 2 }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 2, mb: 2 }}>
                        <Typography
                            sx={{
                                fontFamily: 'NanumGothicCodingBold',
                                fontSize: '16px',
                                color: '#000000',
                            }}
                        >
                            추천 게시물
                        </Typography>
                    </Box>
                    <Grid container item spacing={1}>
                        {artworkRecommend?.map((artwork) => (
                            <Grid item xs={4} key={`recommend-artwork-${artwork?.id}`}>
                                <StyledImage
                                    src={artwork?.image_url}
                                    alt={artwork?.title}
                                    title={`${artwork?.title}`}
                                    sx={{ cursor: 'pointer' }}
                                    onClick={() => onMovePostDetailPage(artwork?.id)}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            </Box>
            {showAudioPlayer &&
                <DocentList exhibitionWorkId={artwork_id} artwork={artwork} open={showAudioPlayer} handleClose={handleAudioClose} />
                // <YoutubeEmbed videoId="_htO1KUuuwU" onClose={handleYoutubeClose} />
            }
                <BottomNavigation
                sx={{
                    position: 'fixed',
                    bottom: 0,
                    left: 0,
                    width: '100%',
                    backgroundColor: '#f5f5f5',
                    boxShadow: '0px -2px 10px rgba(0,0,0,0.1)',
                    zIndex: 1000,
                }}
            >
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                        <BottomNavigationAction
                            label="이전 작품"
                            icon={<ArrowBackIcon />}
                            onClick={handlePreviousArtwork}
                            showLabel={true}
                            sx={{
                                ml: -2,
                                color: isFirstArtwork ? '#d3d3d3' : 'inherit', // Change color if disabled
                            }}
                            disabled={isFirstArtwork}
                        />
                        {!showAudioPlayer &&
                        <>
                            <Fab
                                // color="secondary"
                                aria-label="play"
                                onClick={handleAudioPlay}
                                sx={{
                                    position: 'absolute',
                                    top: -30,
                                    left: '50%',
                                    transform: 'translateX(-50%)',
                                    zIndex: 1100,
                                    width: 60,
                                    height: 60,
                                    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.3)',
                                    backgroundColor: '#ff5e5e',
                                    color: '#fff',
                                    '&:hover': {
                                        backgroundColor: '#e04e4e',
                                    },
                                }}
                            >
                                {isPlaying ? <PauseIcon sx={{ fontSize: 40 }} /> : <PlayArrowIcon sx={{ fontSize: 30 }} />}
                            </Fab>
                            <Typography
                                sx={{
                                    position: 'absolute',
                                    top: 35,
                                    left: '50%',
                                    transform: 'translateX(-50%)',
                                    fontSize: '12px',
                                    color: 'black',
                                }}
                            >
                                {isPlaying ? 'Pause' : '도슨트 듣기'}
                                </Typography>
                            </>
                        }
                        <BottomNavigationAction
                            label="다음 작품"
                            icon={<ArrowForwardIcon />}
                            onClick={handleNextArtwork}
                            showLabel={true}
                            sx={{
                                mr: -2,
                                color: isLastArtwork ? '#d3d3d3' : 'inherit', // Change color if disabled
                            }}
                            disabled={isLastArtwork}
                        />
                    </Box>
            </BottomNavigation>

            <CustomSnackbar
                open={snackbarState.open}
                autoHideDuration={5000}
                onClose={handleCloseSnackbar}
                message={snackbarState.message}
                actionIcon={snackbarState.actionIcon}
                actionRoute={snackbarState.actionRoute}
            />
        </Box>
    );
};

export default ExhibitionArtWorkPage;

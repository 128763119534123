import { useNavigate } from 'react-router-dom';
import { useSnakeBarAlert } from './useSnakeBarAlert';

export const useCustomNavigate = () => {
    const navigate = useNavigate();

    const onMoveBack = () => {
        if (window.history.state && window.history.state.idx > 0) {
            navigate(-1);
        } else {
            navigate('/main');
        }
    }

    const onMoveMain = () => {
      navigate('/main');
    }

    const onMoveProfiles = (user) => {
        if(user.is_secession) {
          return;
        }
        navigate(`/profiles/${user.id}`);
      };

    const onMoveEditProfile = (userId) => {
      navigate(`/profiles/${userId}/edit`);
    };

    const onMoveSettingPage = (userId) => {
      navigate(`/profiles/${userId}/setting`);
    };

    const onMoveSearchPlacesMap = (location) => {
        const locationData = encodeURIComponent(JSON.stringify(location));
        navigate(`/search/places/map?data=${locationData}`);
    }

    const onMoveExhibitionPage = () => {
        navigate('/exhibitions');
    }

    const onMoveExhibitionDetailPage = (exhibitionId) => {
        navigate(`/exhibitions/${exhibitionId}`);
    }

    const onMoveExhibitionArtWorkPage = (exhibitionId, artworkId) => {
        navigate(`/exhibitions/${exhibitionId}/artworks/${artworkId}`);
    }
    
    const onMovePostDetailPage = (postId) => {
        navigate(`/posts/${postId}`);
    }

    const onMoveProfileCollectionPosts = (profileId, collectionId) => {
        navigate(`/profiles/${profileId}/collections/${collectionId}/posts`);
    }

    return {
        onMoveProfiles,
        onMoveSearchPlacesMap,
        onMoveEditProfile,
        onMoveSettingPage,
        onMoveMain,
        onMoveExhibitionPage,
        onMoveExhibitionDetailPage,
        onMoveBack,
        onMoveExhibitionArtWorkPage,
        onMovePostDetailPage,
        onMoveProfileCollectionPosts,
    }
}
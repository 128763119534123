import React, { useState, useEffect } from 'react';
import { Drawer, Avatar, TextField, Divider, Typography, Box, IconButton, List, ListItem, ListItemAvatar, ListItemText, Button, ToggleButtonGroup, ToggleButton, CircularProgress } from '@mui/material';
import Puller from '../../common/components/Puller';
import { useSelector, useDispatch } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import StarIcon from '@mui/icons-material/Star';
import LanguageIcon from '@mui/icons-material/Language';
import PaletteIcon from '@mui/icons-material/Palette';
import YoutubeEmbed from './YoutubeEmbed';
import AudioPlayer from './AudioPlayer';
import YouTubeIcon from '@mui/icons-material/YouTube';
import RadioIcon from '@mui/icons-material/Radio';
import { fetchDocentExhibitionWorks } from '../../repository/docent/docentRepository';
import { clearDocentList } from '../../repository/docent/docentSlice';
const DocentList = ({ exhibitionWorkId, artwork, open, handleClose }) => {
  const dispatch = useDispatch();
  const [filter, setFilter] = useState('free');
  const [selectedDocent, setSelectedDocent] = useState(null);
  const docentList = useSelector(state => state.docent.docentList);
  const [loading, setLoading] = useState(false);
//   const {snackbarState, handleLoginAlert, handleCloseSnackbar} = useSnakeBarAlert();

  const handleFilterChange = (event, newFilter) => {
    if (newFilter !== null) {
      setFilter(newFilter);
    }
  };

  useEffect(() => {
    setSelectedDocent(null);
    dispatch(clearDocentList());
    const fetchData = async () => {
      setLoading(true);
      try {
        await dispatch(fetchDocentExhibitionWorks(exhibitionWorkId));
      } catch (error) {
        console.error('Error fetching docent exhibition works:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [exhibitionWorkId]);

  // 뒤로가기 버튼 눌렀을 때 도슨트 목록 초기화&닫기
  useEffect(() => {
    window.history.pushState(null, null, window.location.pathname);
    const handlePopState = (event) => {
      event.preventDefault();
      handleClearDocentList();
    };

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);

  // const docents = [
  //   { id: 1, title: 'The Met Highlights', type: 'Youtube', icon: <StarIcon />, isFree: true, url: "https://www.youtube.com/watch?v=_htO1KUuuwU&list=PLx_Gfh8MUyMwW8XNJ3RE7n0HqluwIzQFP" },
  //   { id: 3, title: 'Modern & Contemporary', type: 'Audio', icon: <PaletteIcon />, isFree: true, url: "https://storage.googleapis.com/art-friends/audio/test.mp3"   },
  //   { id: 4, title: 'Renaissance Art', type: 'Youtube', icon: <StarIcon />, isFree: true, url: "https://www.youtube.com/watch?v=1-_htO1KUuuwU&list=PLx_Gfh8MUyMwW8XNJ3RE7n0HqluwIzQFP" },
  //   { id: 5, title: 'Impressionism', type: 'Audio', icon: <LanguageIcon />, isFree: true, url: "https://www.youtube.com/watch?v=1-_htO1KUuuwU&list=PLx_Gfh8MUyMwW8XNJ3RE7n0HqluwIzQFP" },
  //   { id: 6, title: 'Abstract Expressionism', type: 'Audio', icon: <PaletteIcon />, isFree: true, url: "https://www.youtube.com/watch?v=1-_htO1KUuuwU&list=PLx_Gfh8MUyMwW8XNJ3RE7n0HqluwIzQFP" },
  // ];

  // const filteredDocents = docents.filter(docent => 
  //   filter === 'all' || (filter === 'free' && docent.isFree) || (filter === 'paid' && !docent.isFree)
  // );

  const handleStart = (docent) => {
    setSelectedDocent(docent);
  };
  const handleCloseDocent = () => {
    setSelectedDocent(null);
  };

  const handleClearDocentList = () => {
    dispatch(clearDocentList());
    handleClose();
  };

  return (
    <>
    {selectedDocent === null ? (
    <Box sx={{ 
      position: 'fixed',
      bottom: '55px',
      display: 'flex', 
      flexDirection: 'column', 
      alignItems: 'center', 
      justifyContent: 'center',
      overflow: 'hidden',
      width: '100%',
      bgcolor: '#fff',
      boxShadow: '0px -2px 10px rgba(0,0,0,0.2)',
      borderTopLeftRadius: '24px',
      borderTopRightRadius: '24px',
      // p: 2,
      zIndex: 1300,
      '@media (min-width: 600px)': {
        maxWidth: '600px',
        padding: '0',
        margin: '0 auto',
    },
    }}>        
      <Puller />
      <Box sx={{ height: 16 }} />
      <IconButton sx={{ position: 'absolute', right: 10, top: 10 }} onClick={()=>handleClearDocentList()}>
          <CloseIcon />
      </IconButton>
      <Box sx={{ width: '100%', position: 'relative', display: 'flex', alignItems: 'center', mt: 3 }}>
        <Typography sx={{ position: 'absolute', left: '50%', transform: 'translateX(-50%)', fontSize: '14px', fontWeight: 600, color: 'rgba(0,0,0,0.9)' }}>도슨트</Typography>
        <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'flex-end' }}>
          <ToggleButtonGroup
            value={filter}
            exclusive
            onChange={handleFilterChange}
            sx={{ transform: 'scale(0.5)' }}
          >
            {/* <ToggleButton value="all" sx={{ border: 'none' }}>All</ToggleButton> */}
            <ToggleButton value="free" sx={{ border: 'none' }}>Free</ToggleButton>
            <ToggleButton value="paid" sx={{ border: 'none' }} disabled>Paid</ToggleButton>
          </ToggleButtonGroup>
        </Box>
      </Box>
      <List sx={{ width: '100%', maxHeight: '170px', overflowY: 'auto' }}>
        {loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', my: 2 }}>
            <CircularProgress />
          </Box>
        ) : docentList.length === 0 ? (
          <Box sx={{mb:2}}>
            <Typography sx={{ textAlign: 'center', mt: 2, color: 'rgba(0,0,0,0.6)' }}>
            해당 작품은 현재 지원 가능한 도슨트가 없습니다.
            </Typography>
            <Typography sx={{ textAlign: 'center', mt:1, color: 'rgba(0,0,0,0.6)' }}>
            직접 도슨트로 참여해보시겠어요?
            </Typography>
          </Box>
        ) : (
          docentList.map((docent) => (
            <ListItem key={docent.id} sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <ListItemAvatar>
                <Avatar>
                  {docent?.docent?.image_url &&
                  <img src={docent?.docent?.image_url} alt="docent" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                  }
                </Avatar>
              </ListItemAvatar>
              <ListItemText 
                primary={`${docent.docent.name} 님`} 
                secondary={
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {`${docent.type}`}
                    {docent.type === 'youtube' && (
                      <YouTubeIcon sx={{ ml: 1, color: 'red' }} />
                    )}
                    {docent.type === 'audio' && (
                      <RadioIcon sx={{ ml: 1, color: 'blue', height:'18px' }} />
                    )}
                  </Box>
                }
              />
              <Button variant="outlined" onClick={() => handleStart(docent)}>Start</Button>
            </ListItem>
          ))
        )}
      </List>
    </Box>
    ) : (
      <Box sx={{ width: '100%', mt: 2 }}>
          {selectedDocent.type === 'youtube' ? (
            <YoutubeEmbed url={selectedDocent.url} onClose={handleCloseDocent} />
          ) : (
            <AudioPlayer artwork={artwork} audioSrc={selectedDocent.url} onClose={handleCloseDocent} />
          )}
      </Box>
    )}
    </>
  );
};

    export default DocentList;
import React, { useState, useEffect, useCallback } from 'react';
import { Box, Typography, Grid } from '@mui/material';
import { useNavigate, useLocation, useParams } from 'react-router-dom'; 
import PostCard from '../../common/components/PostCard';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProfileCollectionPosts } from '../../repository/profiles/profilesRepository'
import PostCardSkeleton from '../../common/components/loading/PostCardSkeleton';
import BackNavigationHeader from '../../common/components/header/BackNavigationHeader';

function ProfileCollectionPostsPage() {
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const location = useLocation();

  const collection_posts = useSelector((state) => state.profiles.collection_posts);

  const [isLoading, setIsLoading] = useState(false);
  const { user_id, collection_id } = useParams();

  const currentTab = new URLSearchParams(location.search).get('tab') || 'home';

//   const fetchMorePosts = useCallback(async () => {
//     if (isLoading || next === null) return; // Stop if loading or no more pages

//     try {
//       fetchMorePostPopular(dispatch, next);
//     } catch (error) {
//       console.error('Failed to load more posts:', error);
//     } finally {
//       setIsLoading(false);
//     }
//   }, [isLoading, next, dispatch]);
  
//   useEffect(() => {
//     const handleScroll = () => {
//       if (window.innerHeight + document.documentElement.scrollTop + 5 >= document.documentElement.offsetHeight) {
//         fetchMorePosts();
//       }
//     };

//     window.addEventListener('scroll', handleScroll);
//     return () => window.removeEventListener('scroll', handleScroll);
//   }, [fetchMorePosts]);
//   useEffect(() => {
//     const handleScroll = () => {
//       if (window.innerHeight + document.documentElement.scrollTop+5 >= document.documentElement.offsetHeight || isLoading) return;
//       //fetchMorePosts();
//     };

//     window.addEventListener('scroll', handleScroll);
//     return () => window.removeEventListener('scroll', handleScroll);
//   }, [fetchMorePosts, isLoading]);

  useEffect(() => {
    window.scrollTo(0, 0); // 스크롤을 맨 위로 초기화
    try {
      dispatch(fetchProfileCollectionPosts(user_id, collection_id));
    } catch (error) {
      console.error('Failed to load more posts:', error);
    }
  }, [user_id, collection_id]);

  return (
    <Box
        sx={{
        width: '100%',
        padding: '0', // 기본 padding
        boxSizing: 'border-box',
        '@media (min-width: 600px)': {
            maxWidth: '600px',  // 최대 너비
            padding: '0',  // 너비가 1200px 이상일 때 padding을 넓힘
            margin: '0 auto',   // 가운데 정렬
        },
        }}
    >
        <BackNavigationHeader/>
        <Grid container>
            {collection_posts.length === 0 ? (
                <Grid item xs={12}>
                    <PostCardSkeleton />
                </Grid>
            ) : (
                collection_posts.map(post => (
                    <Grid item xs={12} sm={12} md={12} lg={12} key={post.id}>
                        <PostCard post={post}/>
                    </Grid>
                ))
            )}
        </Grid>
    </Box>
  );
}

export default ProfileCollectionPostsPage;


export function formatTime(isoString) {
    const now = new Date();
    const date = new Date(isoString);
    const diff = now - date; // 차이를 밀리초로 계산
  
    const minutes = Math.floor(diff / 60000);
    const hours = Math.floor(diff / 3600000);
    const days = Math.floor(diff / 86400000);
  
    if (minutes < 60) {
      return `${minutes}분 전`;
    } else if (hours < 24) {
      return `${hours}시간 전`;
    } else if (days < 7) {
      return `${days}일 전`;
    } else {
      const year = date.getFullYear();
      const month = date.getMonth() + 1; // 월은 0부터 시작하므로 1을 더함
      const day = date.getDate();
  
      if (year === now.getFullYear()) {
        return `${month}월${day}일`;
      } else {
        return `${year}.${month}.${day}`;
      }
    }
  }
  
export function formatDisplayDate(display_start_at, display_end_at) {
    if (!display_start_at || !display_end_at) {
        return "상시 전시";
    }
    const startDate = new Date(display_start_at);
    const endDate = new Date(display_end_at);
    return `${startDate.getFullYear() % 100}.${startDate.getMonth() + 1}.${startDate.getDate()} ~ ${endDate.getFullYear() % 100}.${endDate.getMonth() + 1}.${endDate.getDate()}`;
}
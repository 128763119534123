import React,{ useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Avatar, Typography, IconButton, Divider, Dialog, DialogTitle, DialogActions, Button, Menu, MenuItem } from '@mui/material';
import { formatTime } from '../../util/utils';
import { icons } from '../../constants/icons';
import { fetchPostArtworkReplyLike, fetchDeleteArtworkReplyLike, fetchDeleteArtworkReply } from '../../repository/comments/artworkCommentsRepository';
import CommentContent from './CommentContent';
import { useNavigate } from 'react-router-dom';

const ArtworkReplyCard = ({ index, commentId, reply, exhibitionId, artworkId, mentionUsers, userId, handleReplyTo, handleLoginAlert }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isLiked, setIsLiked] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const [openDialog, setOpenDialog] = useState(false);

    useEffect(() => {
        setIsLiked(reply.is_liked);
    }, [reply.is_liked]);

    const handleLike = (replyInfo) => {
        if (!userId) {
            handleLoginAlert();
            return;
        }
        setIsLiked(!isLiked);
        console.log(replyInfo);
        isLiked ? dispatch(fetchDeleteArtworkReplyLike(exhibitionId, artworkId, commentId, replyInfo.id)) : dispatch(fetchPostArtworkReplyLike(exhibitionId, artworkId, commentId, replyInfo.id));
    };

    const handleMenuClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleDelete = () => {
        setOpenDialog(true);
        handleMenuClose();
    };

    const handleConfirmDelete = async () => {
        // TODO: 삭제 로직 구현
        await dispatch(fetchDeleteArtworkReply(exhibitionId, artworkId, commentId, reply.id));
        setOpenDialog(false);
    };

    const handleCancelDelete = () => {
        setOpenDialog(false);
    };

    const handleUserClick = () => {
        navigate(`/profiles/${reply.user.id}`);
    };

    return <Box key={`reply-card-${reply.id}`} sx={{ width: '100%', pb: '0px' }}>
    <Box sx={{
      display: 'flex',
      justifyContent: 'space-between', // This ensures that the items are spread across the container
      p: '16px 20px 16px 45px',
    }}>
      <Box sx={{ display: 'flex' }}>
        <Avatar 
          src={reply.user.profile_image_url} 
          sx={{ 
            width: 28, 
            height: 28, 
            marginRight: '8px',
            cursor: 'pointer'
          }} 
          onClick={handleUserClick}
        />
        <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography 
              sx={{ 
                fontSize: '14px', 
                fontWeight: 600, 
                color: 'rgba(0,0,0,0.9)', 
                alignSelf: 'flex-start', 
                pr: '6px',
                cursor: 'pointer'
              }}
              onClick={handleUserClick}
            >
              {reply.user.username}
            </Typography>
            {reply.is_deleted ? null :
            <Typography sx={{ fontSize: '12px', fontWeight: 400, color: 'rgba(0,0,0,0.65)', alignSelf: 'center' }}>
              {formatTime(reply.created_at)}
            </Typography>
            }
          </Box>
          <Box sx={{ pt: '4px' }}>
            {reply.is_deleted ? <Typography sx={{ fontSize: '14px', fontWeight: 400, color: 'rgba(0,0,0,0.55)' }}>작성자가 삭제한 댓글입니다.</Typography> :
            <Typography sx={{ fontSize: '14px', fontWeight: 400, color: 'rgba(0,0,0,0.9)' }}>
              <CommentContent content={reply.content} mentionUsers={mentionUsers}/>
            </Typography>
            }
          </Box>
          <Box sx={{ pt: '8px', m: 0 }}>
            {reply.is_deleted ? <Typography sx={{ fontSize: '12px', fontWeight: 400, color: 'rgba(0,0,0,0.65)' }}> {formatTime(reply.created_at)}</Typography> : <>
              <IconButton sx={{ p: 0, mr: '14px' }} onClick={() => handleLike(reply)}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <img src={isLiked ? icons.commentLike.active : icons.commentLike.inactive} alt="Like" style={{ width: 20, height: 20 }} />
                      <Typography sx={{fontSize: '14px', fontWeight: 500, color: 'rgba(0, 0, 0, 0.65)', marginLeft: 1}}>
                      {reply.likes_count || ''} {/* 좋아요 수 */}
                      </Typography>
                  </Box>            
              </IconButton>
              <IconButton sx={{ p: 0 }} onClick={() => handleReplyTo(reply, "reply")}>
                <img src={'/assets/icons/comments/comment.png'} alt="comment" style={{ width: 20, height: 20 }} />
              </IconButton>
            </>
            }
          </Box>
        </Box>
      </Box>
      <Box>
        {reply.user.id === userId && !reply.is_deleted && (
              <IconButton 
                sx={{ p: 0, m: 0 }}
                onClick={handleMenuClick}
            >
              <img src={'/assets/icons/comments/more.png'} alt="more" style={{ width: 20, height: 20 }} />
            </IconButton>
            )}
            <Menu
              anchorEl={anchorEl}
              open={open}
              onClose={handleMenuClose}
            >
              <MenuItem onClick={handleDelete}>
                <Typography sx={{ fontSize: '12px', fontWeight: 500, color: 'red' }}>
                  삭제하기
                </Typography>
              </MenuItem>
            </Menu> 
        </Box>
    </Box>
    <Divider variant="middle" />
    <Dialog
                open={openDialog}
                onClose={handleCancelDelete}
            >
                <DialogTitle sx={{ fontSize: '14px', py: 2 }}>
                    정말 삭제하시겠습니까?
                </DialogTitle>
                <DialogActions sx={{ pb: 2, px: 2 }}>
                    <Button onClick={handleCancelDelete} sx={{ color: 'rgba(0, 0, 0, 0.65)' }}>
                        취소
                    </Button>
                    <Button onClick={handleConfirmDelete} sx={{ color: 'red' }}>
                        삭제
                    </Button>
                </DialogActions>
            </Dialog>
  </Box>
};

export default ArtworkReplyCard;
import { createSlice } from '@reduxjs/toolkit';

export const bookmarksSlice = createSlice({
  name: 'bookmarks',
  initialState: {
    location: [],
    post_group: [],
    exhibition: [],
    exhibition_work: [],
  },
  reducers: {
    setLocation: (state, action) => {
      state.location = action.payload;
    },
    setPostGroup: (state, action) => {
      state.post_group = action.payload;
    },
    removeLocation: (state, action) => {
      state.location = state.location.filter(location => location.result.id !== action.payload);
    },
    removePostGroup: (state, action) => {
      state.post_group = state.post_group.filter(post => post.result.id !== action.payload);
    },
    setExhibition: (state, action) => {
      state.exhibition = action.payload;
    },
    removeExhibition: (state, action) => {
      state.exhibition = state.exhibition.filter(exhibition => exhibition.result.id !== action.payload);
    },
    setExhibitionWork: (state, action) => {
      state.exhibition_work = action.payload;
    },
    removeExhibitionWork: (state, action) => {
      state.exhibition_work = state.exhibition_work.filter(exhibitionWork => exhibitionWork.result.id !== action.payload);
    },
  },
});

// Action creators are generated for each case reducer function
export const { setLocation, setPostGroup, setExhibition, setExhibitionWork,
  removeLocation, removePostGroup, removeExhibition, removeExhibitionWork
 } = bookmarksSlice.actions;

export default bookmarksSlice.reducer;
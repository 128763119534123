import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, Drawer, Typography, Button, Avatar, Box, Grid, IconButton, List, ListItem, ListItemAvatar, ListItemText, ListItemButton, Tabs, Tab } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { fetchProfiles, fetchFollowers, fetchFollowing, fetchAddFollower, fetchDeleteFollower, fetchUpdateAddFollowerList, fetchUpdateDeleteFollowerList, fetchUpdateAddFollowingList, fetchUpdateDeleteFollowingList, fetchProfileCollections } from '../../repository/profiles/profilesRepository';
import PostCard from '../../common/components/PostCard';
import BottomNav from '../../common/components/BottomNav';
import Puller from '../../common/components/Puller';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ViewListIcon from '@mui/icons-material/ViewList';
import GridViewIcon from '@mui/icons-material/GridView';
import GridPostCard from '../../common/components/GridPostCard';
import { useCustomNavigate } from '../../common/hooks/useCustomNavigate';
import { useSnakeBarAlert } from '../../common/hooks/useSnakeBarAlert';
import CustomSnackbar from '../../common/components/exception/CustomSnackbar';

import EmptyPostsMessage from '../../common/components/exception/EmptyPostsMessage';
import EmptyExhibitionMessage from '../../common/components/exception/EmptyExhibitionMessage';
import { formatDisplayDate } from '../../util/utils';
import FollowButton from './FollowButton';

const ProfilePage = () => {
  return <Box>
    <ProfileContent/>
    <Toolbar/>
    <BottomNav/>
  </Box>
}

const getBoxHeight = (index) => {
  switch (index % 6) {
    case 0: return '90px';  // 첫번째: 80x90
    case 1: return '70px';  // 두번째: 80x70
    case 2: return '100px'; // 세번째: 80x100
    case 3: return '70px';  // 네번째: 80x70
    case 4: return '100px'; // 다섯번째: 80x100
    case 5: return '90px';  // 여섯번째: 80x90
    default: return '90px';
  }
};

function ProfileContent() {
    const dispatch = useDispatch();
    const { onMoveEditProfile, onMoveSettingPage, onMoveMain, onMoveProfileCollectionPosts } = useCustomNavigate();
    const { handleLoginAlert, handleCloseSnackbar, snackbarState } = useSnakeBarAlert();
    const { user_id } = useParams();

    const user = useSelector((state) => state.profiles.user);
    const posts = useSelector((state) => state.profiles.posts);
    const follower_count = useSelector((state) => state.profiles.follower_count);
    const following_count = useSelector((state) => state.profiles.following_count);
    const collection_count = useSelector((state) => state.profiles.collection_count);
    const collections = useSelector((state) => state.profiles.collections);
    const followers = useSelector((state) => state.profiles.followers);
    const loginUserId = useSelector((state) => state.user.userId);
    const is_follow = useSelector((state) => state.profiles.is_follow);

    const [drawerOpen, setDrawerOpen] = useState(false);
    const [followingDrawerOpen, setFollowingDrawerOpen] = useState(false);
    const [viewMode, setViewMode] = useState('grid');
    const [activeTab, setActiveTab] = useState(0);
    const [selectedExhibition, setSelectedExhibition] = useState(null);
    const [exhibitionDrawerOpen, setExhibitionDrawerOpen] = useState(false);

    // const [exhibitions] = useState([
    //     {
    //         id: 1,
    //         title: "피카소 특별전",
    //         image_url: "https://storage.googleapis.com/art-friends/test/exhibit2.gif",
    //         date: "2024.01.01 - 2024.03.31",
    //         location: "국립현대미술관"
    //     },
    //     {
    //         id: 2,
    //         title: "반 고흐 전시",
    //         image_url: "https://storage.googleapis.com/art-friends/test/exhibit2.gif",
    //         date: "2024.02.15 - 2024.04.15",
    //         location: "예술의전당"
    //     },
    //     {
    //         id: 3,
    //         title: "모네: 빛을 그리다",
    //         image_url: "https://storage.googleapis.com/art-friends/test/exhibit2.gif",
    //         date: "2024.03.01 - 2024.05.31",
    //         location: "디뮤지엄"
    //     }
    // ]);

    useEffect(() => {
      window.scrollTo(0, 0); // 스크롤을 맨 위로 초기화
      if (user_id) {
        dispatch(fetchProfiles(user_id));
        dispatch(fetchFollowers(user_id));
        dispatch(fetchProfileCollections(user_id));
      }
    }, [user_id, dispatch]);
    
    const handleFollower = (is_follow, userId) => {
      if(!loginUserId) {
        handleLoginAlert();
        return;
      }      
      is_follow ? fetchDeleteFollower(dispatch, userId) : fetchAddFollower(dispatch, userId);
    }

    const handleUpdateFollowerList = (is_follow, userId) => {
      if(!loginUserId) {
        handleLoginAlert();
        return;
      }      
      is_follow ? fetchUpdateDeleteFollowerList(dispatch, userId) : fetchUpdateAddFollowerList(dispatch, userId);
    }

    const handleUpdateFollowingList = (is_follow, userId) => {
      if(!loginUserId) {
        handleLoginAlert();
        return;
      }      
      is_follow ? fetchUpdateDeleteFollowingList(dispatch, userId) : fetchUpdateAddFollowingList(dispatch, userId);
    }

    const handleFollowerClick = () => {
      dispatch(fetchFollowers(user_id));
      setDrawerOpen(true);
    };
  
    const handleCloseFollowerModal = () => {
      setDrawerOpen(false);
    };
  
    const handleFollowingClick = () => {
      fetchFollowing(dispatch, user_id);
      setFollowingDrawerOpen(true);
    };

    const handleCloseFollowingModal = () => {
      setFollowingDrawerOpen(false);
    };

    const handleClose = () => {
      onMoveMain();
    };

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
    };

    const handleExhibitionClick = (exhibition) => {
      setSelectedExhibition(exhibition);
      setExhibitionDrawerOpen(true);
    };

    const handleCloseExhibitionDrawer = () => {
      setExhibitionDrawerOpen(false);
      setSelectedExhibition(null);
    };

    return (
      <Box sx={{
        '@media (min-width: 600px)': {
          maxWidth: '600px',
          padding: '0',
          margin: '0 auto',
        }
      }}>
        <Box sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '164px',
          backgroundImage: 'url(/assets/images/bg_test.jpeg)',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          zIndex: -1
        }} />
        <Box sx={{ p: '0 16px', top: 0 }}>
        <AppBar position="relative" color="transparent" sx={{
            elevation: 0,
            p: 1,
            margin: 0,
            boxShadow: 'none',
            border: 0,
            zIndex: 10,
            // background: 'linear-gradient(180deg, rgba(0,0,0,0.3) 0%, rgba(0,0,0,0) 100%)'
          }}>
            <Toolbar sx={{ justifyContent: 'space-between', p: 0, margin: 0 }}>
              <IconButton 
                edge="start" 
                color="inherit" 
                aria-label="back" 
                onClick={handleClose}
                sx={{ color: '#FFFFFF' }}
              >
                <img 
                  src="/assets/icons/back_button.png" 
                  alt="back_button" 
                  style={{ width: '10px', height: '17px' }} 
                />
              </IconButton>
              {user.id === loginUserId &&
                <IconButton sx={{ p: 0, m: 0 }} onClick={()=>{onMoveSettingPage(loginUserId);}}>
                  <img src={'/assets/icons/more_icon.png'} alt="more" style={{ width: 20, height: 20 }} />
                </IconButton>
              }
            </Toolbar>
          </AppBar>
          <Box sx={{ height: '17px' }} />
          <Avatar sx={{ width: 64, height: 64, marginBottom: '12px', border: 1.5, borderColor: 'rgba(255,255,255,1)' }} src={user.profile_image_url} />
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
            <Box>
              <Typography sx={{ fontSize: '16px', fontWeight: 600, color: 'rgba(0,0,0,1)' }}>{user.username}</Typography>
              <Box sx={{ display: 'flex', color: 'rgba(115,115,115,1)',pt: '4px'}}>
                <Typography onClick={handleFollowerClick} sx={{cursor: 'pointer', fontSize: '14px', fontWeight: 500 }}>
                  팔로워 {follower_count}
                </Typography>
                {loginUserId == user_id &&
                  <>
                    <Typography sx={{ margin: '0 4px' }}>|</Typography>
                    <Typography sx={{ cursor: 'pointer', fontSize: '14px', fontWeight: 500 }} onClick={handleFollowingClick}>
                      팔로잉 {following_count}
                    </Typography>
                  </>
                }
              </Box>

              <Box sx={{ height: '10px' }} />
              <Typography sx={{ 
                fontSize: '12px', 
                fontWeight: 500,
                whiteSpace: 'pre-line'
              }}>
                {user.introduction}
              </Typography>
            </Box>
            {user.id === loginUserId ? (
              <Button 
                variant="outlined" 
                onClick={()=>{onMoveEditProfile(loginUserId)}}
                sx={{
                  color: 'rgba(0,0,0,0.85)',
                  borderColor: 'rgba(0,0,0,0.2)',
                  borderRadius: '20px',
                  width: '100px',
                  height: '32px',
                  fontSize: '14px',
                  fontWeight: '500',
                  whiteSpace: 'nowrap'
                }}
              >
                프로필 수정
              </Button>
            ) : (
              <FollowButton onClick={()=> handleFollower(is_follow, user_id)} is_follow={is_follow}/>
            )}
          </Box>
  
          {/* 팔로워 모달 */}
          <DrawerFollowList followers={followers} loginUserId={loginUserId} drawerOpen={drawerOpen} closeDrawer={handleCloseFollowerModal} handleFollower={handleUpdateFollowerList}/>
          <DrawerFollowingList drawerOpen={followingDrawerOpen} closeDrawer={handleCloseFollowingModal} handleFollowing={handleUpdateFollowingList}/>
          <Box>
            <Typography sx={{ fontSize: '12px', fontWeight: 500, color: 'rgba(0,0,0,0.75)' }}>
              {/* 소개글 */}
            </Typography>
          </Box>
        </Box>
        <Box sx={{ 
          borderBottom: 1, 
          borderColor: 'divider', 
          backgroundColor: 'rgba(255, 255, 255, 1)',
        }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs 
              value={activeTab} 
              onChange={handleTabChange}
              variant="fullWidth"
              sx={{
                '& .MuiTab-root': {
                  fontSize: '16px',
                  fontWeight: 500,
                  color: 'rgba(0, 0, 0, 0.6)',
                  '&.Mui-selected': {
                    color: 'rgba(0, 0, 0, 0.87)',
                  }
                }
              }}
            >
              <Tab label={
                <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                  <span>전시수집</span>
                  {(collection_count > 0) && 
                    <span style={{ color: 'rgba(0, 0, 0, 0.6)' }}>{collection_count}</span>
                  }
                </Box>
              } />
              <Tab label={
                <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                  <span>게시물</span>
                  {(posts?.length > 0) && 
                    <span style={{ color: 'rgba(0, 0, 0, 0.6)' }}>{posts.length}</span>
                  }
                </Box>
              } />
            </Tabs>
          </Box>
        </Box>

        <Box>
          {activeTab === 1 ? (
            <>
            <Box sx={{ 
              display: 'flex',
              justifyContent: 'flex-end',
              padding: '0 16px',
              marginTop: '8px'
            }}>
              <Box sx={{ display: 'flex', gap: 1 }}>
                <IconButton 
                  onClick={() => setViewMode('list')}
                  sx={{ 
                    color: viewMode === 'list' ? 'primary.main' : 'text.secondary',
                    p: '4px'
                  }}
                >
                  <ViewListIcon />
                </IconButton>
                <IconButton 
                  onClick={() => setViewMode('grid')}
                  sx={{ 
                    color: viewMode === 'grid' ? 'primary.main' : 'text.secondary',
                    p: '4px'
                  }}
                >
                  <GridViewIcon />
                </IconButton>
              </Box>
            </Box>
            <Grid container spacing={viewMode === 'grid' ? 1 : 0} >
              {posts && posts.length > 0 ? (
                posts.map((post) => (
                  <Grid item xs={viewMode === 'grid' ? 4 : 12} key={post.id}>
                    {viewMode === 'grid' ? (
                      <GridPostCard post={post} />
                    ) : (
                      <PostCard post={post}/>
                    )}
                  </Grid>
                ))
              ) : (
                <Grid item xs={12}>
                  <EmptyPostsMessage content={"여기에 당신의 이야기를 채워주세요"}/>
                </Grid>
              )}
            </Grid>
            </>
          ) : (
            <Grid 
              container 
              spacing={1} 
              sx={{ 
                padding: '16px 24px',
                minHeight: 'calc(100vh - 400px)',
                alignItems: 'center',
                backgroundColor: '#F5F7F9'  // 배경색 추가
              }}
            >
              {collections.length > 0 ? (
                <>
                  {[...Array(12)].map((_, index) => {
                    const collection = collections[index];
                    return (
                      <Grid item xs={4} sx={{ paddingBottom: '8px' }} key={index}>
                        {collection ? (
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              height: '100%',
                              maxWidth: '88px',
                              margin: '0 auto'
                            }}
                          >
                            <Box
                              onClick={() => handleExhibitionClick(collection)}
                              sx={{
                                position: 'relative',
                                paddingTop: '100%',
                                cursor: 'pointer',
                                overflow: 'hidden',
                                border: '4px solid #FFFFFF',
                                boxShadow: '0 6px 16px rgba(177,184,193,0.35)',  // hover 시 쉐도우 더 진하게
                                transition: 'all 0.3s ease',
                                '&:hover': {
                                  transform: 'translateY(-4px)',
                                  boxShadow: '0 6px 16px rgba(177,184,193,0.35)'  // hover 시 쉐도우 더 진하게
                                }
                              }}
                            >
                              <Box
                                component="img"
                                src={collection.image_url}
                                alt={collection.title}
                                sx={{
                                  position: 'absolute',
                                  top: 0,
                                  left: 0,
                                  width: '100%',
                                  height: '100%',
                                  objectFit: 'cover'
                                }}
                              />
                            </Box>
                          </Box>
                        ) : (
                          <Box
                          sx={{
                            position: 'relative',
                            maxWidth: '80px',
                            height: getBoxHeight(index),
                            margin: '0 auto',
                            // backgroundColor: 'w',
                            border: '1px dashed #B6D0EF',  // 실선을 점선으로 변경하고 색상 변경
                            boxShadow: '0 2px 8px rgba(177,184,193,0.1)'
                          }}
                        >
                          <Typography
                            sx={{
                              position: 'absolute',
                              top: '50%',
                              left: '50%',
                              transform: 'translate(-50%, -50%)',
                              fontSize: '24px',
                              fontWeight: 500,
                              color: '#B6D0EF'  // 숫자 색상 변경
                            }}
                          >
                            {index + 1}
                          </Typography>
                        </Box>
                        )}
                      </Grid>
                    );
                  })}
                </>
              ) : (
                <Grid item xs={12} sx={{ textAlign: 'center' }}>
                  <EmptyExhibitionMessage content={"아직 수집된 전시가 없습니다"} isMyProfile={user.id === loginUserId}/>
                </Grid>
              )}
            </Grid>
          )}
        </Box>
        <CustomSnackbar
            open={snackbarState.open}
            autoHideDuration={3000}
            onClose={handleCloseSnackbar}
            message={snackbarState.message}
            actionIcon={snackbarState.actionIcon}
            actionRoute={snackbarState.actionRoute}
          />
        <Toolbar sx={{backgroundColor: activeTab === 0 ? '#F5F7F9' : 'transparent'}}/>
        {/* Add Exhibition Drawer */}
        <Drawer
          anchor="bottom"
          open={exhibitionDrawerOpen}
          onClose={handleCloseExhibitionDrawer}
          sx={{
            '& .MuiDrawer-paper': {
              borderTopLeftRadius: 32,
              borderTopRightRadius: 32,
              borderColor: '#FFFFFF',
              backgroundColor: '#FFFFFF',
              height: 'auto',
              maxHeight: '80vh',
              overflow: 'visible'
            }
          }}
        >
          {selectedExhibition && (
            <>
              <Puller />
              <Box sx={{ p: 3 }}>
                <Box sx={{ width: '100%', textAlign: 'center', mt:1, mb: 2 }}>
                  <Typography sx={{ fontSize: '14px', fontWeight: 600, color: 'rgba(0,0,0,0.9)' }}>
                    전시 정보
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
                  <Box
                    component="img"
                    src={selectedExhibition?.image_url}
                    alt={selectedExhibition?.title}
                    sx={{
                      width: 120,
                      height: 160,
                      borderRadius: 2,
                      objectFit: 'cover'
                    }}
                  />
                  <Box sx={{ flex: 1 }}>
                    <Typography 
                      variant="h6" 
                      sx={{ 
                        fontSize: '18px',
                        fontWeight: 600,
                        mb: 1
                      }}
                    >
                      {selectedExhibition?.title}
                    </Typography>
                    <Typography 
                      sx={{ 
                        fontSize: '14px',
                        color: 'rgba(0,0,0,0.6)',
                        mb: 1
                      }}
                    >
                      {selectedExhibition?.location?.name}
                    </Typography>
                    <Typography 
                      sx={{ 
                        fontSize: '14px',
                        color: 'rgba(0,0,0,0.6)'
                      }}
                    >
                      {formatDisplayDate(selectedExhibition?.display_start_at, selectedExhibition?.display_end_at)}
                    </Typography>
                  </Box>
                </Box>
                <Box sx={{ mt: 2 }}>
                  <Button
                    variant="outlined"
                    fullWidth
                    onClick={()=>{onMoveProfileCollectionPosts(user.id, selectedExhibition.id)}}
                    sx={{
                      borderRadius: '8px',
                      py: 1,
                      backgroundColor: '#FFFFFF',
                      borderColor: 'rgba(0,0,0,0.2)',
                      color: 'rgba(0,0,0,0.85)',
                      '&:hover': {
                        backgroundColor: '#FFFFFF',
                        borderColor: 'rgba(0,0,0,0.3)'
                      }
                    }}
                  >
                    <Box component="span" sx={{ fontWeight: 600 }} >{user.username}</Box>님의 리뷰 확인하기
                  </Button>
                </Box>
              </Box>
            </>
          )}
        </Drawer>
      </Box>
    );
  }
  


const DrawerFollowList = ({followers, loginUserId, drawerOpen, closeDrawer, handleFollower}) => {
  const {onMoveProfiles} =useCustomNavigate();

  useEffect(() => {
    if (drawerOpen) {
      // 히스토리 엔트리 추가

      window.history.pushState(null, '', window.location.pathname);

      // popstate 이벤트 핸들러
      const handlePopState = () => {
        closeDrawer();
      };

      // 이벤트 리스너 등록
      window.addEventListener('popstate', handlePopState);

      // 클린업 함수
      return () => {
        window.removeEventListener('popstate', handlePopState);
      };
    }
  }, [drawerOpen]);

  return <>
  <Drawer
        anchor="bottom"
        open={drawerOpen}
        onClose={closeDrawer}
        sx={{
          '& .MuiDrawer-paper': {
            borderTopLeftRadius: 32, // Top left border radius
            borderTopRightRadius: 32, // Top right border radius
            borderColor: '#FFFFFF',
            backgroundColor: '#FFFFFF', // Background color of the drawer
            height: 'auto', // Adjust this value as needed to cover the bottom nav
            overflow: 'visible'
          }
        }}
      >
         <Puller/>
         <Box sx={{ height: 16 }} />
         <Box sx={{ width: '100%', textAlign: 'center', mt: 2 }}>
          <Typography sx={{ fontSize: '14px', fontWeight: 600, color: 'rgba(0,0,0,0.9)' }}>팔로워</Typography>
        </Box>
         <List sx={{marginBottom:'19px'}}>
            {followers.map((follower) => (
                  <ListItemButton 
                    key={follower.id} 
                    disableRipple={false}
                    sx={{ 
                      display: 'flex', 
                      justifyContent: 'space-between', 
                      cursor: 'pointer',
                      padding: '8px 16px',
                      '&:hover': {
                        backgroundColor: 'rgba(0, 0, 0, 0.04) !important'
                      },
                      transition: 'background-color 0.3s'
                     }}
                    onClick={()=> {
                      closeDrawer();
                      onMoveProfiles(follower.user);
                    }}
                  >
                    <Box sx={{ display: 'flex', alignItems: 'center', flex: 1 }}>
                      <ListItemAvatar>
                        <Avatar src={follower.user.profile_image_url} />
                      </ListItemAvatar>
                      <ListItemText primary={follower.user.username} />
                    </Box>
                    {loginUserId === follower.user.id ? 
                      null
                      : <Box onClick={(e) => e.stopPropagation()}>
                        <FollowButton 
                          onClick={() => handleFollower(follower.is_follow, follower.user.id)} 
                          is_follow={follower.is_follow}
                        />
                      </Box>
                    }
                </ListItemButton>
              ))}
         </List>
       </Drawer>
  </>;
}

const DrawerFollowingList = ({drawerOpen, closeDrawer, handleFollowing}) => {
  const {onMoveProfiles} =useCustomNavigate();
  const followings = useSelector((state) => state.profiles.followings);

  useEffect(() => {
    if (drawerOpen) {
      // 히스토리 엔트리 추가

      window.history.pushState(null, '', window.location.pathname);

      // popstate 이벤트 핸들러
      const handlePopState = () => {
        closeDrawer();
      };

      // 이벤트 리스너 등록
      window.addEventListener('popstate', handlePopState);

      // 클린업 함수
      return () => {
        window.removeEventListener('popstate', handlePopState);
      };
    }
  }, [drawerOpen]);

  return <>
  <Drawer
        anchor="bottom"
        open={drawerOpen}
        onClose={closeDrawer}
        sx={{
          '& .MuiDrawer-paper': {
            borderTopLeftRadius: 32, // Top left border radius
            borderTopRightRadius: 32, // Top right border radius
            borderColor: '#FFFFFF',
            backgroundColor: '#FFFFFF', // Background color of the drawer
            height: 'auto', // Adjust this value as needed to cover the bottom nav
            overflow: 'visible'
          }
        }}
      >
         <Puller/>
         <Box sx={{ height: 16 }} />
         <Box sx={{ width: '100%', textAlign: 'center', mt: 2 }}>
          <Typography sx={{ fontSize: '14px', fontWeight: 600, color: 'rgba(0,0,0,0.9)' }}>팔로잉</Typography>
        </Box>
         <List sx={{marginBottom:'19px'}}>
            {followings.map((following) => (
                  <ListItemButton 
                    key={following.id} 
                    disableRipple={false}
                    sx={{ 
                      display: 'flex', 
                      justifyContent: 'space-between', 
                      cursor: 'pointer',
                      padding: '8px 16px',
                      '&:hover': {
                        backgroundColor: 'rgba(0, 0, 0, 0.04) !important'
                      },
                      transition: 'background-color 0.3s'
                     }}
                    onClick={()=> {
                      closeDrawer();
                      onMoveProfiles(following.user);
                    }}
                  >
                    <Box sx={{ display: 'flex', alignItems: 'center', flex: 1 }}>
                      <ListItemAvatar>
                        <Avatar src={following.user.profile_image_url} />
                      </ListItemAvatar>
                      <ListItemText primary={following.user.username} />
                    </Box>
                    <Box onClick={(e) => e.stopPropagation()}>
                      <FollowButton 
                        onClick={() => handleFollowing(following.is_follow, following.user.id)} 
                        is_follow={following.is_follow}
                    />
                    </Box>
                  </ListItemButton>
                ))}
         </List>
       </Drawer>
  </>;
}

export default ProfilePage;

import { useState, useEffect } from 'react';
import { 
  Box, 
  Button, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Grid,
  Typography,
  IconButton
} from '@mui/material';
import { Add, Edit, Delete } from '@mui/icons-material';
import { uploadImage } from '../../api/SwissApi';
import { useDispatch, useSelector } from 'react-redux';
import { fetchArtWorks, fetchArtCreate, fetchUpdateArt, fetchDeleteArt } from '../../repository/admin/adminRepository';

const ArtWorkManagePage = () => {
    const dispatch = useDispatch();
    const artworks = useSelector(state => state.admin.artworks);
    const [open, setOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [artworkData, setArtworkData] = useState({
    title: '',
    titleEn: '',
    artist: '',
    artistEn: '',
    creation_year: '',
    description: '',
    image_url: ''
  });
  const [editingArtwork, setEditingArtwork] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [artworkToDelete, setArtworkToDelete] = useState(null);

  useEffect(() => {
    dispatch(fetchArtWorks());
  }, []);

  const handleOpen = (artwork = null) => {
    setEditingArtwork(artwork);
    if (artwork) {
      setArtworkData({
        title: artwork.title_kr,
        titleEn: artwork.title_en,
        artist: artwork.artist_kr,
        artistEn: artwork.artist_en,
        creation_year: artwork.creation_year,
        description: artwork.description,
        image_url: artwork.image_url
      });
    }
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setArtworkData({
      title: '',
      titleEn: '',
      artist: '',
      artistEn: '',
      creation_year: '',
      description: '',
      image_url: ''
    });
  };
  const handleChangeImage = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    try {
      const imageUrl = await uploadImage(file, 'artwork');
      setArtworkData({...artworkData, image_url: imageUrl});
    } catch (error) {
      console.error('이미지 업로드 실패:', error);
    }
  };

  const handleDeleteImage = () => {
    setArtworkData({...artworkData, image_url: ""});
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const transformedData = {
      title_kr: artworkData.title,
      title_en: artworkData.titleEn,
      artist_kr: artworkData.artist,
      artist_en: artworkData.artistEn,
      creation_year: artworkData.creation_year,
      description: artworkData.description,
      image_url: artworkData.image_url
    };

    if (editingArtwork) {
      dispatch(fetchUpdateArt(editingArtwork.id, transformedData));
    } else {
      dispatch(fetchArtCreate(transformedData));
    }

    console.log(transformedData);
    handleClose();
  };

  const handleDeleteDialogOpen = (artwork) => {
    setArtworkToDelete(artwork);
    setDeleteDialogOpen(true);
  };

  const handleDeleteDialogClose = () => {
    setDeleteDialogOpen(false);
    setArtworkToDelete(null);
  };

  const handleConfirmDelete = () => {
    if (artworkToDelete) {
      dispatch(fetchDeleteArt(artworkToDelete.id));
    }
    handleDeleteDialogClose();
  };

  const filteredArtworks = artworks.filter(artwork => 
    artwork.title_kr.toLowerCase().includes(searchTerm.toLowerCase()) ||
    artwork.title_en.toLowerCase().includes(searchTerm.toLowerCase()) ||
    artwork.artist_kr.toLowerCase().includes(searchTerm.toLowerCase()) ||
    artwork.artist_en.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2, alignItems: 'center'  }}>
        <h2>작품 관리</h2>
        <Button 
          variant="contained" 
          startIcon={<Add />}
          onClick={()=>handleOpen()}
        >
          작품 등록
        </Button>
      </Box>

      <Box sx={{ mb: 2 }}>
        <TextField
          fullWidth
          variant="outlined"
          placeholder="작품명 또는 작가명으로 검색"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          size="small"
        />
      </Box>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>이미지</TableCell>
              <TableCell>제목</TableCell>
              <TableCell>작가</TableCell>
              <TableCell>제작연도</TableCell>
              <TableCell>관리</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredArtworks.map((artwork) => (
              <TableRow key={artwork.id}>
                <TableCell>{artwork.id}</TableCell>
                <TableCell>
                  <img 
                    src={artwork.image_url} 
                    alt={artwork.title} 
                    style={{ width: '50px', height: '50px', objectFit: 'cover' }}
                  />
                </TableCell>
                <TableCell>{artwork.title_kr} / {artwork.title_en}</TableCell>
                <TableCell>{artwork.artist_kr} / {artwork.artist_en}</TableCell>
                <TableCell>{artwork.creation_year}</TableCell>
                <TableCell>
                  <Button startIcon={<Edit />} onClick={() => handleOpen(artwork)}>수정</Button>
                  <Button 
                    startIcon={<Delete />} 
                    color="error" 
                    onClick={() => handleDeleteDialogOpen(artwork)}
                  >
                    삭제
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle>작품 등록</DialogTitle>
        <form onSubmit={handleSubmit}>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  autoFocus
                  margin="dense"
                  label="작품명 (한글)"
                  fullWidth
                  value={artworkData.title}
                  onChange={(e) => setArtworkData({...artworkData, title: e.target.value})}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  margin="dense"
                  label="작품명 (영문)"
                  fullWidth
                  value={artworkData.titleEn}
                  onChange={(e) => setArtworkData({...artworkData, titleEn: e.target.value})}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  margin="dense"
                  label="작가명 (한글)"
                  fullWidth
                  value={artworkData.artist}
                  onChange={(e) => setArtworkData({...artworkData, artist: e.target.value})}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  margin="dense"
                  label="작가명 (영문)"
                  fullWidth
                  value={artworkData.artistEn}
                  onChange={(e) => setArtworkData({...artworkData, artistEn: e.target.value})}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  margin="dense"
                  label="제작연도"
                  fullWidth
                  value={artworkData.creation_year}
                  onChange={(e) => setArtworkData({...artworkData, creation_year: e.target.value})}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  margin="dense"
                  label="작품 설명"
                  fullWidth
                  multiline
                  rows={4}
                  value={artworkData.description}
                  onChange={(e) => setArtworkData({...artworkData, description: e.target.value})}
                />
              </Grid>
              <Grid item xs={12}>
              <Typography variant="subtitle2" sx={{ mb: 1 }}>작품 이미지</Typography>
              <Box 
                sx={{ 
                  position: 'relative',
                  width: 200,
                  height: 200,
                  border: '1px dashed rgba(0, 0, 0, 0.2)',
                  borderRadius: 1,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  overflow: 'hidden',
                  '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.04)'
                  }
                }}
              >
                <input
                  type="file"
                  hidden
                  id="artwork-image-input"
                  accept="image/*"
                  onChange={handleChangeImage}
                />
                {artworkData.image_url ? (
                  <>
                    <img 
                      src={artworkData.image_url} 
                      alt="작품 이미지"
                      style={{ 
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover'
                      }}
                    />
                    <Box
                      sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: 'rgba(0, 0, 0, 0.3)',
                        display: 'flex',
                        gap: 1,
                        alignItems: 'center',
                        justifyContent: 'center',
                        opacity: 0,
                        transition: 'opacity 0.2s',
                        '&:hover': {
                          opacity: 1
                        }
                      }}
                    >
                      <IconButton
                        component="label"
                        htmlFor="artwork-image-input"
                        sx={{ color: 'white' }}
                      >
                        <Edit />
                      </IconButton>
                      <IconButton
                        onClick={handleDeleteImage}
                        sx={{ color: 'white' }}
                      >
                        <Delete />
                      </IconButton>
                    </Box>
                  </>
                ) : (
                  <label htmlFor="artwork-image-input" style={{ cursor: 'pointer' }}>
                    <Box sx={{ textAlign: 'center' }}>
                      <Add sx={{ fontSize: 40, color: 'text.secondary' }} />
                      <Typography variant="body2" color="text.secondary">
                        이미지를 업로드해주세요
                      </Typography>
                    </Box>
                  </label>
                )}
              </Box>
            </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>취소</Button>
            <Button type="submit" variant="contained">등록</Button>
          </DialogActions>
        </form>
      </Dialog>

      <Dialog open={deleteDialogOpen} onClose={handleDeleteDialogClose}>
        <DialogTitle>작품 삭제</DialogTitle>
        <DialogContent>
          <Typography>정말 작품을 삭제하시겠습니까?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteDialogClose}>취소</Button>
          <Button onClick={handleConfirmDelete} color="error">삭제</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ArtWorkManagePage;
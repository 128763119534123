import { Box, Typography, Card, CardContent, CardMedia, IconButton } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { formatDisplayDate } from '../../util/utils';
import { icons } from '../../constants/icons';

const ExhibitionCard = ({ exhibition, onMoveExhibitionDetailPage, toggleBookmark, bookmarkCard }) => {
  return (
    <Card 
      key={exhibition.id} 
      sx={{ 
        display: 'flex', 
        height: '120px',
        bgcolor: '#F8F9FA',
        boxShadow: 'none',
        position: 'relative'
      }}
      
    >
      <Box sx={{ position: 'relative', width: 90 }}>
        <CardMedia
          component="img"
          sx={{ 
            width: 90, 
            height: 120,
            borderRadius: 1,
          }}
          image={exhibition.image_url}
          alt={exhibition.title}
        />
        {/* <IconButton 
                onClick={(e) => {
                  e.stopPropagation();
                  toggleFavorite(exhibition.id);
                }}
                sx={{ 
                  position: 'absolute', 
                  bottom: 4, 
                  left: 4,
                  padding: '4px',
                  '&:hover': {
                    bgcolor: 'transparent',
                  },
                }}
              >
                {favorites.has(exhibition.id) ? (
                  <FavoriteIcon sx={{ fontSize: 22, color: '#FF3B30' }} />
                ) : (
                  <FavoriteBorderIcon sx={{ fontSize: 22, color: 'white' }} />
                )}
              </IconButton> */}
      </Box>
      
      <CardContent 
        sx={{ 
          flex: 1, 
          p: 1.5, 
          cursor: 'pointer', 
          position: 'relative',
          overflow: 'hidden'
        }} 
        onClick={() => onMoveExhibitionDetailPage(exhibition.id)}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 0.5 }}>
          {exhibition.tags.map((tag, index) => (
            <Typography 
              key={index}
              sx={{ 
                bgcolor: tag === "추천" ? '#FFF1F0' : '#FFFFFF',
                color: tag === "추천" ? '#FF3B30' : '#666666',
                fontSize: '10px',
                fontWeight: 'bold',
                px: 1,
                py: 0.5,
                borderRadius: 1,
                mr: 1
              }}
            >
              {tag}
            </Typography>
          ))}
        </Box>
        
        <Typography 
          sx={{ 
            fontWeight: 'bold', 
            mb: 1, 
            fontSize: '14px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap'
          }} 
        >
          {exhibition.title}
        </Typography>
        
        <Box>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
            <LocationOnIcon sx={{ fontSize: 12, color: '#666666' }} />
            <Typography color="#666666" sx={{ fontSize: 12 }}>
              {exhibition.location?.name || '위치 정보 없음'}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5, mt: 0.5 }}>
            <CalendarTodayIcon sx={{ fontSize: 12, color: '#666666' }} />
            <Typography color="#666666" sx={{ fontSize: 12 }}>
              {formatDisplayDate(exhibition.display_start_at, exhibition.display_end_at)}
            </Typography>
          </Box>
        </Box>
       { !bookmarkCard && (
        <IconButton 
          onClick={(e) => {
            e.stopPropagation();
            toggleBookmark(exhibition.id, exhibition.is_bookmarked);
          }}
          sx={{ 
            position: 'absolute', 
            bottom: 4, 
            right: 4,
            padding: '4px',
            '&:hover': {
              bgcolor: 'transparent',
            },
            zIndex: 1
          }}
        >
          {exhibition.is_bookmarked ? (
            <img src={icons.smallBookmarks.active} alt="Bookmark" style={{ width: 17, height: 17 }} />
          ) : (
            <img src={icons.smallBookmarks.inactive} alt="Bookmark" style={{ width: 17, height: 17 }} />
            )}
          </IconButton>
        )}
      </CardContent>
      
    </Card>
  );
};

export default ExhibitionCard;
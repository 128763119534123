import { Box, Typography, Slider, IconButton } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import Replay10Icon from '@mui/icons-material/Replay10';
import Forward10Icon from '@mui/icons-material/Forward10';
import CloseIcon from '@mui/icons-material/Close';

const AudioPlayer = ({ artwork, audioSrc, onClose }) => {
    const [isPlaying, setIsPlaying] = useState(false);
    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(0);
    const audioRef = useRef(null);

    useEffect(() => {
        window.history.pushState(null, null, window.location.pathname);

        const handlePopState = (event) => {
            event.preventDefault();
            onClose();
        };

        window.addEventListener('popstate', handlePopState);

        const audio = audioRef.current;
        if (audio) {
            audio.addEventListener('timeupdate', () => setCurrentTime(audio.currentTime));
            audio.addEventListener('loadedmetadata', () => setDuration(audio.duration));
        }
    }, []);

    const handlePlayPause = () => {
        if (isPlaying) {
            audioRef.current.pause();
        } else {
            audioRef.current.play();
        }
        setIsPlaying(!isPlaying);
    };

    const handleSeekChange = (event, newValue) => {
        audioRef.current.currentTime = newValue;
        setCurrentTime(newValue);
    };

    const handleSkip = (seconds) => {
        audioRef.current.currentTime += seconds;
        setCurrentTime(audioRef.current.currentTime);
    };

    return (
        <Box
            sx={{
                position: 'fixed',
                bottom: '55px',
                left: 0,
                right: 0,
                width: '100%',
                maxWidth: '100%',
                overflow: 'hidden',
                bgcolor: '#fff',
                boxShadow: '0px -2px 10px rgba(0,0,0,0.2)',
                borderTopLeftRadius: '16px',
                borderTopRightRadius: '16px',
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                boxSizing: 'border-box',
            }}
        >
            {/* 닫기 버튼 */}
            <IconButton sx={{ position: 'absolute', right: 10, top: 10 }} onClick={onClose}>
                <CloseIcon />
            </IconButton>

            {/* 오디오 제목 */}
            <Typography sx={{ fontWeight: 'bold', mb: 2 }}>{artwork?.title_kr}</Typography>

            {/* Seek Bar */}
            <Slider
                value={currentTime}
                min={0}
                max={duration}
                step={1}
                onChange={handleSeekChange}
                sx={{ width: '90%' }}
            />

            {/* 시간 표시 */}
            <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '90%', fontSize: '12px', color: '#666' }}>
                <Typography>{formatTime(currentTime)}</Typography>
                <Typography>{formatTime(duration)}</Typography>
            </Box>

            {/* 컨트롤 버튼 */}
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mt: 1 }}>
                <IconButton onClick={() => handleSkip(-10)}>
                    <Replay10Icon />
                </IconButton>
                <IconButton onClick={handlePlayPause} sx={{ fontSize: 40 }}>
                    {isPlaying ? <PauseIcon fontSize="large" /> : <PlayArrowIcon fontSize="large" />}
                </IconButton>
                <IconButton onClick={() => handleSkip(10)}>
                    <Forward10Icon />
                </IconButton>
            </Box>

            {/* 오디오 태그 */}
            <audio ref={audioRef} src={audioSrc} />
        </Box>
    );
};

// 시간 포맷 함수
const formatTime = (seconds) => {
    const mins = Math.floor(seconds / 60);
    const secs = Math.floor(seconds % 60);
    return `${mins}:${secs < 10 ? '0' : ''}${secs}`;
};

export default AudioPlayer;
import { useState, useEffect } from 'react';
import {
  Box,
  Paper,
  Typography,
  Grid,
  Card,
  CardContent,
  CardMedia,
  CardActionArea,
  Chip,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Button,
  Autocomplete,
  TextField,
  Pagination,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
} from '@mui/material';
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import { Delete, Add, ImageOutlined } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { fetchExhibitionList, fetchExhibitionArtWorks, fetchExhibitionArtWorksMapping, fetchUpdateArtWorksSort } from '../../repository/admin/adminRepository';
import { setExhibitionArtWorks, setAvailableExhibitionArtWorks } from '../../repository/admin/adminSlice';
import { useSearchParams, useNavigate } from 'react-router-dom';



const ExhibitionArtworkMappingPage = () => {
    
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const exhibitionId = searchParams.get('exhibitionId') ? Number(searchParams.get('exhibitionId')) : null;

  const [selectedExhibition, setSelectedExhibition] = useState(exhibitionId || '');
  const [selectedExhibitionObj, setSelectedExhibitionObj] = useState(null);
  const exhibitions = useSelector(state => state.admin.exhibitions);

  const availableArtworks = useSelector(state => state.admin.availableExhibitionArtWorks);
  const mappedArtworks = useSelector(state => state.admin.exhibitionArtWorks);

  const [artworkSearch, setArtworkSearch] = useState('');
  const [page, setPage] = useState(1);
  const itemsPerPage = 10;
  const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    dispatch(fetchExhibitionList()).then(() => {
      if (exhibitionId) {
        setSelectedExhibition(exhibitionId);
        dispatch(fetchExhibitionArtWorks(exhibitionId));
      }
    });
  }, [exhibitionId]);

  useEffect(() => {
    if (exhibitions.length > 0 && selectedExhibition) {
      const exhibition = exhibitions.find(ex => ex.id === Number(selectedExhibition));
      setSelectedExhibitionObj(exhibition || null);
    }
  }, [exhibitions, selectedExhibition]);

  const handleExhibitionChange = (event, newValue) => {
    if (newValue) {
      const newId = newValue.id;
      setSelectedExhibition(newId);
      setSelectedExhibitionObj(newValue);
      navigate(`/admin/manage/exhibitions/artworks?exhibitionId=${newId}`);
    } else {
      setSelectedExhibition('');
      setSelectedExhibitionObj(null);
      navigate('/admin/manage/exhibitions/artworks');
    }
  };

  const handleAddArtwork = async (artwork) => {
    try {
      await dispatch(fetchExhibitionArtWorksMapping(selectedExhibition, {
        add_exhibition_works: [artwork.id],
      }));
      dispatch(setExhibitionArtWorks([...mappedArtworks, artwork]));
      dispatch(setAvailableExhibitionArtWorks(availableArtworks.filter(a => a.id !== artwork.id)));
    } catch (error) {
      console.error('Failed to add artwork:', error);
      // Handle error
    }
  };

  const handleRemoveArtwork = async (artwork) => {
    try {
      await dispatch(fetchExhibitionArtWorksMapping(selectedExhibition, {
        remove_exhibition_works: [artwork.id]
      }));
      dispatch(setExhibitionArtWorks(mappedArtworks.filter(a => a.id !== artwork.id)));
      dispatch(setAvailableExhibitionArtWorks([...availableArtworks, artwork]));
    } catch (error) {
      console.error('Failed to remove artwork:', error);
      // Handle error
    }
  };

  // 작품 검색 필터링
  const filteredAvailableArtworks = availableArtworks.filter(artwork =>
    artwork?.title_kr?.toLowerCase().includes(artworkSearch.toLowerCase()) ||
    artwork?.artist_kr?.toLowerCase().includes(artworkSearch.toLowerCase()) ||
    artwork?.title_en?.toLowerCase().includes(artworkSearch.toLowerCase()) ||
    artwork?.artist_en?.toLowerCase().includes(artworkSearch.toLowerCase())
  );

  const paginatedArtworks = filteredAvailableArtworks.slice(
    (page - 1) * itemsPerPage,
    page * itemsPerPage
  );

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleRegister = () => {
    setOpenDialog(true);
  };

  const handleConfirmRegister = async () => {
    try {
      const add_exhibition_works = mappedArtworks.map(artwork => artwork.id);
      const remove_exhibition_works = availableArtworks.map(artwork => artwork.id);

      await dispatch(fetchExhibitionArtWorksMapping(selectedExhibition, {
        add_exhibition_works: add_exhibition_works,
        remove_exhibition_works: remove_exhibition_works
      })).then(() => {
        setOpenDialog(false);
        alert('전시 작품이 성공적으로 등록되었습니다.');
      });
    } catch (error) {
      console.error('Exhibition artwork mapping failed:', error);
      // 에러 처리 로직 추가 필요
    }
  };

  const handleDragEnd = (result) => {
    if (!result.destination) return;
  
    const reorderedArtworks = [...mappedArtworks];
    const [movedItem] = reorderedArtworks.splice(result.source.index, 1);
    reorderedArtworks.splice(result.destination.index, 0, movedItem);

    // Create a new array with updated sort_index
    const updatedArtworks = reorderedArtworks.map((artwork, index) => ({
      ...artwork,
      sort_index: index+1,
    }));

    console.log("reorder: ", updatedArtworks);
    dispatch(fetchUpdateArtWorksSort(selectedExhibition, updatedArtworks));
  };
  return (
    <Box>
      <Box sx={{ mb: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <h2>전시 작품 관리</h2>
      </Box>
      <Autocomplete
        fullWidth
        sx={{ mb: 4 }}
        options={exhibitions}
        getOptionLabel={(option) => option.title}
        onChange={handleExhibitionChange}
        value={selectedExhibitionObj}
        renderInput={(params) => (
          <TextField
            {...params}
            label="전시회 선택"
          />
        )}
      />

      {selectedExhibition && (
        <Grid container spacing={3}>
          {/* 매핑된 작품 목록 */}
          <Grid item xs={12} md={6}>
            <Paper sx={{ p: 2 }}>
              <Typography variant="h6" sx={{ mb: 2 }}>
                전시 작품 목록
                <Chip 
                  label={`${mappedArtworks.length}점`} 
                  size="small" 
                  sx={{ ml: 1 }}
                />
              </Typography>
              <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId="mappedArtworks">
                  {(provided) => (
                    <List {...provided.droppableProps} ref={provided.innerRef}>
                      {mappedArtworks.map((artwork, index) => (
                        <Draggable key={artwork.id} draggableId={String(artwork.id)} index={index}>
                          {(provided) => (
                          <ListItem 
                            key={artwork.id}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            disableGutters
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              border: "1px solid #ddd",
                              borderRadius: 2,
                              mb: 1,
                              mx: 1,
                              p:0,
                              pr:2
                            }}
                          >
                            <Box
                              sx={{ 
                                width: 80,
                                height: 70,
                                flexShrink: 0,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                bgcolor: 'grey.100',
                                borderRadius: 1,
                                overflow: 'hidden',
                              }}
                            >
                              {artwork.image_url ? (
                                <CardMedia
                                  component="img"
                                  sx={{ width: '100%', height: '100%', objectFit: 'cover' }}
                                  image={artwork.image_url}
                                  alt={artwork.title_kr}
                                />
                              ) : (
                                <ImageOutlined sx={{ fontSize: 30, color: 'grey.500' }} />
                              )}
                            </Box>
                            <ListItemText
                              primary={
                                <Typography variant="body2" color="textPrimary" noWrap>
                                  {`${artwork.title_kr} / ${artwork.title_en}`}
                                </Typography>
                              }
                              secondary={
                                <Typography variant="caption" color="textSecondary" noWrap>
                                  {`${artwork.artist_kr} / ${artwork.artist_en}`}
                                </Typography>
                              }
                              sx={{ ml: 2 }}
                            />
                              <IconButton 
                                edge="end" 
                                onClick={() => handleRemoveArtwork(artwork)}
                                color="error"
                              >
                                <Delete />
                              </IconButton>
                          </ListItem>
                          )}
                        </Draggable>
                    ))}
                    {provided.placeholder}
                    </List>
                  )}
                </Droppable>
              </DragDropContext>
            </Paper>
          </Grid>

          {/* 추가 가능한 작품 목록 */}
          <Grid item xs={12} md={6}>
            <Paper sx={{ p: 2 }}>
              <Typography variant="h6" sx={{ mb: 2 }}>
                추가 가능한 작품
                <Chip 
                  label={`${availableArtworks.length}점`} 
                  size="small" 
                  sx={{ ml: 1 }}
                />
              </Typography>
              <TextField
                fullWidth
                size="small"
                label="작품/작가 검색"
                value={artworkSearch}
                onChange={(e) => {
                  setArtworkSearch(e.target.value);
                  setPage(1); // 검색 시 첫 페이지로 이동
                }}
                sx={{ mb: 2 }}
              />
              <Grid container spacing={1}>
                {paginatedArtworks.map((artwork) => (
                  <Grid item xs={12} key={artwork.id}>
                    <Card sx={{ maxHeight: 100 }}>
                      <CardActionArea 
                        onClick={() => handleAddArtwork(artwork)} 
                        sx={{ 
                          display: 'flex', 
                          justifyContent: 'flex-start',
                          height: '100%'
                        }}
                      >
                        {artwork.image_url ? (
                          <CardMedia
                            component="img"
                            sx={{ width: 80, height: 70 }}
                            image={artwork.image_url}
                            alt={artwork.title_kr}
                          />
                        ) : (
                          <Box
                            sx={{ 
                              width: 80, 
                              height: 70,
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              bgcolor: 'grey.100'
                            }}
                          >
                            <ImageOutlined sx={{ fontSize: 40, color: 'grey.500' }} />
                          </Box>
                        )}
                        <CardContent sx={{ py: 1 }}>
                          <Typography variant="body2" color="textPrimary" noWrap>
                            {artwork.title_kr} / {artwork.title_en}
                          </Typography>
                          <Typography variant="caption" color="textSecondary" noWrap>
                            {artwork.artist_kr} / {artwork.artist_en} ({artwork.creation_year})
                          </Typography>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </Grid>
                ))}
              </Grid>
              {filteredAvailableArtworks.length > itemsPerPage && (
                <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}>
                  <Pagination
                    count={Math.ceil(filteredAvailableArtworks.length / itemsPerPage)}
                    page={page}
                    onChange={handlePageChange}
                    color="primary"
                    size="small"
                  />
                </Box>
              )}
            </Paper>
          </Grid>
        </Grid>
      )}

      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
      >
        <DialogTitle>전시 작품 매핑 확인</DialogTitle>
        <DialogContent>
          <DialogContentText>
            선택한 작품들을 전시에 매핑하시겠습니까?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)} color="inherit">
            취소
          </Button>
          <Button onClick={handleConfirmRegister} color="primary" variant="contained">
            확인
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ExhibitionArtworkMappingPage;